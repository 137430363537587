import { useState, useEffect, useRef } from 'react';
import { getGenderText } from '../utils/formatUtils';
import { findMatchingDivisions, generateDivisions } from '../utils/divisionUtils';

/**
 * Custom hook to manage tournament registration form state and logic
 */
export const useTournamentForm = (tournament, onSubmit) => {
  const [formData, setFormData] = useState({
    skill: '',
    age: '',
    gender: [], // This will now be called "Match Format"
    rating: '',
    division: [], // Multiple divisions
    // New structure for partner info - each type of doubles has its own partner info
    partners: {
      mixed: { email: '', phone: '' },
      mens: { email: '', phone: '' },
      womens: { email: '', phone: '' }
    }
  });
  
  const [availableDivisions, setAvailableDivisions] = useState([]);
  const [suggestedDivision, setSuggestedDivision] = useState(null);
  const [ageOptions, setAgeOptions] = useState([]);
  const [generatedDivisions, setGeneratedDivisions] = useState([]);
  const skipDivisionUpdateRef = useRef(false);
  const didSuggestRef = useRef(false);

  // Get options from tournament data
  const skillOptions = tournament.divisions?.skillCategory || [];
  const genderOptions = tournament.divisions?.divisionGenderCategory || [];
  const ratingOptions = tournament.divisions?.ratingsRange || [];

  // Update age options when skill changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (formData.skill && tournament.divisions?.ageRanges) {
      setAgeOptions(tournament.divisions.ageRanges);
    } else {
      setAgeOptions([]);
    }
  }, [formData.skill, tournament]);

  // Generate divisions based on selected criteria
  useEffect(() => {
    const { skill, gender, age, rating } = formData;
    
    if (skill && gender.length > 0) {
      const generated = generateDivisions(skill, gender, age, rating, tournament.divisions);
      setGeneratedDivisions(generated);
      
      if (!tournament.divisions?.generatedDivisions && generated.length > 0) {
        setSuggestedDivision(generated[0]);
      }
    } else {
      setGeneratedDivisions([]);
    }
  }, [formData.skill, formData.gender, formData.age, formData.rating, tournament.divisions, formData]);

  // Process available divisions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (tournament.divisionSettings?.selectedDivisions) {
      const { skill, gender, age, rating } = formData;
      
      // Start with all divisions
      let filtered = tournament.divisionSettings.selectedDivisions;
      
      // Filter divisions based on selected criteria...
      
      // Find a suggested match from generatedDivisions
      let suggestedMatch = null;
      
      if (tournament.divisions?.generatedDivisions && 
          skill && gender.length > 0 && (age || rating)) {
        // Find matching division logic...
      }
      
      console.log('Suggested match found:', suggestedMatch);
      setAvailableDivisions(filtered);
    }
  }, [formData.skill, formData.age, formData.rating, tournament, generatedDivisions, formData]);

  // Update divisions when match formats change
  useEffect(() => {
    if (formData.gender.length === 0 || availableDivisions.length === 0) {
      return;
    }
    
    if (skipDivisionUpdateRef.current) {
      skipDivisionUpdateRef.current = false;
      return;
    }
    
    const matchingDivs = findMatchingDivisions(formData.gender, availableDivisions);
    
    const currentDivs = new Set(formData.division);
    if (matchingDivs.length !== formData.division.length || 
        matchingDivs.some(div => !currentDivs.has(div))) {
      skipDivisionUpdateRef.current = true;
      setFormData(prev => ({
        ...prev,
        division: matchingDivs
      }));
    }
  }, [formData.gender, availableDivisions, formData.division]);

  // Reset suggestion tracking when form selections change
  useEffect(() => {
    didSuggestRef.current = false;
  }, [formData.skill, formData.gender, formData.age, formData.rating]);

  // Handle changes for regular inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name.startsWith('partner_')) {
      // Handle partner info fields
      const [, partnerType, field] = name.split('_');
      
      setFormData(prev => ({
        ...prev,
        partners: {
          ...prev.partners,
          [partnerType]: {
            ...prev.partners[partnerType],
            [field]: value
          }
        }
      }));
    } else {
      // Handle other fields
      setFormData(prev => {
        if (name !== 'division' && !name.startsWith('partner_')) {
          return { ...prev, [name]: value, division: [] };
        }
        return { ...prev, [name]: value };
      });
    }
  };

  // Handle match format selection
  const handleMatchFormatChange = (gender, isSelected) => {
    if (isSelected) {
      setFormData(prev => ({
        ...prev,
        gender: prev.gender.filter(g => g !== gender)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        gender: [...prev.gender, gender]
      }));
    }
  };

  // Handle division selection
  const handleDivisionChange = (division, isSelected) => {
    if (isSelected) {
      setFormData(prev => ({
        ...prev,
        division: prev.division.filter(d => d !== division)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        division: [...prev.division, division]
      }));
    }
  };

  // Update the handleSubmit function to include all validation and submission logic
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate required fields
    if (!formData.skill || !formData.rating || !formData.gender.length === 0 || !formData.division || formData.division.length === 0) {
      alert('Please fill in all required fields.');
      return;
    }
    
    // Check if partners are required but missing
    const hasMixed = formData.gender.some(g => getGenderText(g).includes('mixed'));
    const hasMensDoubles = formData.gender.some(g => {
      const text = getGenderText(g);
      return text.includes('men') && text.includes('doubles') && !text.includes('mixed');
    });
    const hasWomensDoubles = formData.gender.some(g => {
      const text = getGenderText(g);
      return text.includes('women') && text.includes('doubles');
    });
    
    let missingPartnerInfo = false;
    let missingPartnerType = '';
    
    if (hasMixed && (!formData.partners.mixed.email || !formData.partners.mixed.phone)) {
      missingPartnerInfo = true;
      missingPartnerType = 'Mixed Doubles';
    } else if (hasMensDoubles && (!formData.partners.mens.email || !formData.partners.mens.phone)) {
      missingPartnerInfo = true;
      missingPartnerType = 'Men\'s Doubles';
    } else if (hasWomensDoubles && (!formData.partners.womens.email || !formData.partners.womens.phone)) {
      missingPartnerInfo = true;
      missingPartnerType = 'Women\'s Doubles';
    }
    
    if (missingPartnerInfo) {
      alert(`Please enter your partner's email and phone number for ${missingPartnerType}.`);
      return;
    }
    
    // Prepare the registration payload with tournament ID and formatted data
    const registrationData = {
      tournamentId: tournament.TID,
      playerInfo: {
        skill: formData.skill,
        rating: formData.rating,
        age: formData.age || null
      },
      formats: formData.gender, // Selected match formats
      divisions: formData.division, // Selected divisions
      partners: {
        // Add partner information in a structured way
        mixed: hasMixed ? formData.partners.mixed : null,
        mens: hasMensDoubles ? formData.partners.mens : null,
        womens: hasWomensDoubles ? formData.partners.womens : null
      },
      // Add backward compatibility fields
      partnerEmail: formData.partners.mixed.email || formData.partners.mens.email || formData.partners.womens.email,
      partnerPhone: formData.partners.mixed.phone || formData.partners.mens.phone || formData.partners.womens.phone
    };
    
    console.log('Submitting registration data:', registrationData);
    
    // Call the onSubmit handler with the complete registration data
    onSubmit(registrationData);
  };

  return {
    formData,
    setFormData,
    skillOptions,
    genderOptions,
    ratingOptions,
    ageOptions,
    availableDivisions,
    suggestedDivision,
    generatedDivisions,
    handleChange,
    handleMatchFormatChange,
    handleDivisionChange,
    handleSubmit
  };
};