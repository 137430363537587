import { toApiAddressFormat, toUiAddressFormat } from '../../../utils/addressUtils';

/**
 * Helper to get authentication headers, with option for multipart requests
 * @param {boolean} isMultipart - Whether this is a multipart/form-data request
 * @returns {Object} Headers object with JWT if available
 */
const getHeaders = (isMultipart = false) => {
  const jwt = localStorage.getItem('jwt');
  const headers = jwt ? { 'X-JWT': jwt } : {};
  
  // Only add Content-Type for non-multipart requests
  if (!isMultipart) {
    headers['Content-Type'] = 'application/json';
  }
  
  return headers;
};

/**
 * Transform API data to form format
 * @param {Object} apiTournament - Tournament data from API
 * @returns {Object} Data transformed to form format
 */
export const transformApiDataToFormData = (apiTournament) => {
    console.log('Raw API tournament data:', apiTournament);
    
    if (!apiTournament) {
        console.warn('No tournament data received from API');
        return createEmptyFormData();
    }
    
    // Check for operator details in different possible locations
    const operatorDetailsFromAPI = apiTournament.operatorDetails || {};
    
    // Check for divisions in different possible locations
    const divisionsFromAPI = apiTournament.divisions || {};
    
    // Extract skill categories with debug logging
    const skillCategory = divisionsFromAPI.skillCategory || apiTournament.skillCategory || [];
    console.log('Extracted skillCategory:', skillCategory);
    
    // Extract division gender categories
    const divisionGenderCategory = divisionsFromAPI.divisionGenderCategory || apiTournament.divisionGenderCategory || [];
    console.log('Extracted divisionGenderCategory:', divisionGenderCategory);
    
    // Extract ratings range with multiple fallbacks
    const ratingsRange = divisionsFromAPI.ratingsRange || 
                        divisionsFromAPI.ratingsRanges || 
                        apiTournament.ratingsRange || 
                        apiTournament.ratingsRanges || [];
    console.log('Extracted ratingsRange:', ratingsRange);
    
    // Extract age ranges with multiple fallbacks and formats
    let ageRanges = divisionsFromAPI.ageRanges || 
                   divisionsFromAPI.ageRange || 
                   apiTournament.ageRanges || 
                   apiTournament.ageRange || {};
                   
    // Make sure ageRanges is an object, not an array
    if (Array.isArray(ageRanges)) {
        // Convert array to object format expected by the form
        const ageRangesObj = {};
        skillCategory.forEach(category => {
            ageRangesObj[category] = ageRanges.filter(age => 
                age.startsWith(category + ':') || 
                age.includes(category)
            ).map(age => age.split(':')[1] || age);
        });
        ageRanges = ageRangesObj;
    }
    console.log('Extracted and processed ageRanges:', ageRanges);
    
    // Extract generated divisions
    const generatedDivisions = divisionsFromAPI.generatedDivisions || apiTournament.generatedDivisions || [];
    
    // Extract communication settings with all possible paths
    let communicationSettings = apiTournament.communication;
    
    // If communication is a string or invalid format, try to parse it
    if (typeof communicationSettings === 'string') {
        try {
            communicationSettings = JSON.parse(communicationSettings);
        } catch (e) {
            console.warn('Failed to parse communication settings string:', e);
            communicationSettings = null;
        }
    }
    
    // If still not an object, set to default
    if (!communicationSettings || typeof communicationSettings !== 'object') {
        communicationSettings = {
            isTextingEnabled: false,
            isEmailEnabled: false,
            primaryContact: '',
            additionalContacts: [],
            isPrivate: false,
            timezone: '',
            sponsors: []
        };
    }
    
    // Extract logos with proper validation
    let logos = apiTournament.logos;
    
    // Handle case where logos might be a string (URL) or invalid format
    if (typeof logos === 'string') {
        logos = [{ url: logos }];
    } else if (!Array.isArray(logos)) {
        logos = [];
    }
    
    console.log('Extracted logos:', logos);
    
    // Extract player limits with type conversion to ensure numbers
    const minPlayers = apiTournament.minimumPlayersPerDivision;
    const maxPlayers = apiTournament.maximumPlayersPerDivision;
    
    const minPlayersValue = minPlayers !== undefined && minPlayers !== null ? 
        (typeof minPlayers === 'number' ? minPlayers.toString() : minPlayers) : '';
        
    const maxPlayersValue = maxPlayers !== undefined && maxPlayers !== null ? 
        (typeof maxPlayers === 'number' ? maxPlayers.toString() : maxPlayers) : '';
    
    console.log('Player limits:', { min: minPlayersValue, max: maxPlayersValue });
    
    // Initialize the form data with tournament fields
    const formData = {
        id: apiTournament.id || apiTournament.TID || "",
        name: apiTournament.name || "",
        description: apiTournament.description || "",
        
        importantDates: {
            startDate: apiTournament.importantDates?.startDate || apiTournament.startDate || "",
            endDate: apiTournament.importantDates?.endDate || apiTournament.endDate || "",
            openRegistrationDate: apiTournament.importantDates?.openRegistrationDate || apiTournament.openRegistrationDate || "",
            closeRegistrationDate: apiTournament.importantDates?.closeRegistrationDate || apiTournament.closeRegistrationDate || "",
            registrationPublicDate: apiTournament.importantDates?.registrationPublicDate || apiTournament.registrationPublicDate || ""
        },
        
        status: apiTournament.status || "inactive",

        // Use values from nested structure first, then try root level, then use defaults
        operatorDetails: {
            runningOrganization: operatorDetailsFromAPI.runningOrganization || apiTournament.runningOrganization || "",
            tournamentType: operatorDetailsFromAPI.tournamentType || apiTournament.tournamentType || "",
            isUSAPGoldenTicket: operatorDetailsFromAPI.isUSAPGoldenTicket || apiTournament.isUSAPGoldenTicket || false
        },
        
        // Properly populate divisions with extracted data
        divisions: {
            skillCategory: skillCategory,
            divisionGenderCategory: divisionGenderCategory,
            ratingsRange: ratingsRange,
            ageRanges: ageRanges,
            generatedDivisions: generatedDivisions
        },
        
        // Transform venues to ensure address is in the correct format
        venues: Array.isArray(apiTournament.venues) 
            ? apiTournament.venues.map(venue => {
                return {
                    name: venue.name || "",
                    numberOfCourts: venue.numberOfCourts || "",
                    address: venue.address ? toUiAddressFormat(venue.address) : {}
                };
            })
            : [],
        
        // Player limits with proper type handling
        minimumPlayersPerDivision: minPlayersValue,
        maximumPlayersPerDivision: maxPlayersValue,
        numberOfMatchesGuaranteed: apiTournament.numberOfMatchesGuaranteed || "",
        
        // Communication settings with proper extraction
        communication: communicationSettings,
        
        // Division settings with fallbacks
        divisionConfigs: apiTournament.divisionSettings?.divisionConfigs || 
                        apiTournament.divisionConfigs || {},
                        
        selectedDivisions: apiTournament.divisionSettings?.selectedDivisions || 
                          apiTournament.selectedDivisions || [],
                          
        mergedDivisions: apiTournament.divisionSettings?.mergedDivisions || 
                        apiTournament.mergedDivisions || [],
        
        // Logos with validation
        logos: logos
    };
    
    console.log('Transformed form data:', formData);
    console.log('Specific fields to verify:', {
        operatorDetails: formData.operatorDetails,
        divisions: formData.divisions,
        minPlayers: formData.minimumPlayersPerDivision,
        maxPlayers: formData.maximumPlayersPerDivision, 
        communication: formData.communication,
        logos: formData.logos
    });
    
    return formData;
};

/**
 * Creates an empty form data object with all required fields initialized
 * @returns {Object} Empty form data structure with default values
 */
export const createEmptyFormData = () => {
    return {
        name: '',
        description: '', // Initialize with empty string for rich text
        importantDates: {
            startDate: '',
            endDate: '',
            openRegistrationDate: '',
            closeRegistrationDate: '',
            registrationPublicDate: ''
        },
        status: 'inactive',
        
        // Initialize operator details with empty values
        operatorDetails: {
            runningOrganization: '',
            tournamentType: '',
            isUSAPGoldenTicket: false
        },
        
        venues: [],
        
        // Division data in the correct structure with all fields
        divisions: {
            skillCategory: [],
            divisionGenderCategory: [],
            ratingsRange: [],
            ageRanges: [],  // Age ranges
            generatedDivisions: []  // Initialize generated divisions
        },
        
        minimumPlayersPerDivision: '',
        maximumPlayersPerDivision: '',  // Add this line
        numberOfMatchesGuaranteed: '',
        communication: {
            isTextingEnabled: false,
            isEmailEnabled: false,
            primaryContact: '',
            additionalContacts: [],
            isPrivate: false,
            timezone: '',
            sponsors: []
        },
        
        // Initialize division settings correctly
        divisionConfigs: {}, 
        selectedDivisions: [],
        mergedDivisions: []
    };
};

// Update the transformFormDataToApiData function to properly handle operatorDetails

export const transformFormDataToApiData = (formData) => {
    console.log('Raw form data in transform:', formData);
    
    // Transform venues to match API expectations with more logging
    const transformedVenues = Array.isArray(formData.venues) 
        ? formData.venues.map(venue => {
            // Existing venue transformation code...
            return {
                name: venue.name || "",
                numberOfCourts: venue.numberOfCourts || "",
                address: toApiAddressFormat(venue.address)
            };
        })
        : [];

    // Ensure description HTML content is properly preserved
    const descriptionContent = formData.description || "";
    
    // Create API data structure
    const apiData = {
        TID: formData.id || "",
        name: formData.name || "",
        description: descriptionContent, // Preserve HTML content
        importantDates: formData.importantDates || {},
        status: formData.status || "inactive",
        
        // Add operator details at the top level - this is the key fix
        runningOrganization: formData.operatorDetails?.runningOrganization || "",
        tournamentType: formData.operatorDetails?.tournamentType || "",
        isUSAPGoldenTicket: formData.operatorDetails?.isUSAPGoldenTicket || false,
        
        // Use transformed venues array
        venues: transformedVenues,
        
        // Put division properties inside a 'divisions' object
        divisions: {
            skillCategory: formData.divisions?.skillCategory || [],
            divisionGenderCategory: formData.divisions?.divisionGenderCategory || [],
            ratingsRange: formData.divisions?.ratingsRange || [],
            ageRanges: formData.divisions?.ageRanges || [],
            generatedDivisions: formData.divisions?.generatedDivisions || []
        },
        
        // For backward compatibility, include these at the root level too
        skillCategory: formData.divisions?.skillCategory || [],
        divisionGenderCategory: formData.divisions?.divisionGenderCategory || [],
        ratingsRange: formData.divisions?.ratingsRange || [],
        ageRanges: formData.divisions?.ageRanges || [],
        generatedDivisions: formData.divisions?.generatedDivisions || [],
        
        minimumPlayersPerDivision: formData.minimumPlayersPerDivision || "",
        maximumPlayersPerDivision: formData.maximumPlayersPerDivision || "",
        numberOfMatchesGuaranteed: formData.numberOfMatchesGuaranteed || "",
        communication: formData.communication || {},
        logos: formData.logos || [],
        
        // Include division settings
        divisionSettings: {
            divisionConfigs: formData.divisionConfigs || {},
            selectedDivisions: formData.selectedDivisions || [],
            mergedDivisions: formData.mergedDivisions || []
        }
    };
    
    console.log('Transformed API data with operator details at root level:', apiData);
    return apiData;
};

/**
 * Fetch a tournament by ID
 * @param {string} tournamentId - ID of tournament to fetch
 * @returns {Promise<Object>} Tournament data
 */
export const fetchTournament = async (tournamentId) => {
    try {
        // Validate tournament ID
        if (!tournamentId) {
            console.warn('Tournament ID is empty or undefined');
            return createEmptyFormData();
        }

        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
        console.log(`Fetching tournament with ID: ${tournamentId} from ${backendUrl}`);

        // Create AbortController for timeout handling
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 15000); // 15 second timeout

        const headers = getHeaders();
        console.log('Request headers:', headers);

        try {
            // Test connectivity to server first
            console.log('Testing connectivity to server...');
            await fetch(`${backendUrl}/health`, { 
                method: 'GET',
                signal: AbortSignal.timeout(3000) // 3 second timeout just for the health check
            });
            console.log('Connectivity test passed');
        } catch (connErr) {
            console.warn('Connectivity test failed, but continuing with main request', connErr);
            // Continue with main request even if health check fails
        }

        const response = await fetch(`${backendUrl}/api/tournaments/${tournamentId}`, {
            method: 'GET',
            headers: headers,
            credentials: 'include',
            signal: controller.signal,
            // Add mode for CORS if needed
            mode: 'cors'
        });
        
        // Clear the timeout
        clearTimeout(timeoutId);

        if (!response.ok) {
            const errorText = await response.text().catch(() => 'No response text available');
            console.error(`Server returned ${response.status}: ${errorText}`);
            throw new Error(`Failed to fetch tournament: ${response.status} - ${errorText}`);
        }

        // Parse the response as JSON
        const tournamentData = await response.json();
        
        // Specifically log the operator details from API
        console.log('Tournament data fetched successfully:', tournamentData);
        console.log('Operator details from API:', {
            runningOrganization: tournamentData.runningOrganization,
            tournamentType: tournamentData.tournamentType,
            isUSAPGoldenTicket: tournamentData.isUSAPGoldenTicket
        });
        
        // Transform API data to form format
        const formData = transformApiDataToFormData(tournamentData);
        
        // Specifically log the operatorDetails in form data
        console.log('operatorDetails in form data:', formData.operatorDetails);
        
        // Explicitly preserve the isPublic field (add this line)
        formData.isPublic = tournamentData.isPublic;
        
        return formData;
    } catch (error) {
        console.error('Error in fetchTournament:', error);
        
        // Provide more specific error messages
        if (error.name === 'AbortError') {
            throw new Error('Request timed out while fetching tournament. Please check your network connection and server status.');
        } else if (!navigator.onLine) {
            throw new Error('You appear to be offline. Please check your internet connection and try again.');
        } else if (error.message.includes('Failed to fetch')) {
            // Get the backend URL for the error message
            const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
            throw new Error(`Unable to connect to the server at ${backendUrl}. Please verify the server is running and reachable.`);
        }
        
        throw error;
    }
};

/**
 * Create a new tournament
 * @param {FormData} formData - Tournament data as FormData object
 * @returns {Promise<Object>} Created tournament data
 */
export const createTournament = async (formData) => {
  try {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
    
    // Check if formData is already a FormData object
    const isMultipart = formData instanceof FormData;
    
    // Use headers with JWT but no Content-Type for FormData
    const headers = getHeaders(isMultipart);
    
    console.log('Sending request to create tournament with headers:', headers);
    
    const response = await fetch(`${backendUrl}/api/tournaments`, {
      method: 'POST',
      body: formData,
      headers: headers,
      credentials: 'include'
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => {
        return { message: `Server returned status: ${response.status}` };
      });
      throw new Error(errorData.message || 'Failed to create tournament');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating tournament:', error);
    throw error;
  }
};

/**
 * Update an existing tournament
 * @param {string} tournamentId - ID of the tournament to update
 * @param {FormData} formData - Tournament data as FormData object
 * @returns {Promise<Object>} Updated tournament data
 */
export const updateTournament = async (tournamentId, formData) => {
  try {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
    
    // Check if formData is already a FormData object
    const isMultipart = formData instanceof FormData;
    
    // Use headers with JWT but no Content-Type for FormData
    const headers = getHeaders(isMultipart);
    
    console.log('Sending request to update tournament with headers:', headers);
    
    const response = await fetch(`${backendUrl}/api/tournaments/${tournamentId}`, {
      method: 'PUT',
      body: formData,
      headers: headers,
      credentials: 'include'
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => {
        return { message: `Server returned status: ${response.status}` };
      });
      throw new Error(errorData.message || 'Failed to update tournament');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error updating tournament:', error);
    throw error;
  }
};

/**
 * Fetch a list of all tournaments
 * @returns {Promise<Array>} List of tournaments
 */
export const fetchTournaments = async () => {
    try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
        const response = await fetch(`${backendUrl}/api/tournaments`, {
            method: 'GET',
            headers: getHeaders(),
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch tournaments: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching tournaments:', error);
        throw error;
    }
};

/**
 * Delete a tournament
 * @param {string} tournamentId - ID of tournament to delete
 * @returns {Promise<void>}
 */
export const deleteTournament = async (tournamentId) => {
    try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
        const response = await fetch(`${backendUrl}/api/tournaments/${tournamentId}`, {
            method: 'DELETE',
            headers: getHeaders(),
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error(`Failed to delete tournament: ${response.status}`);
        }

        return;
    } catch (error) {
        console.error('Error deleting tournament:', error);
        throw error;
    }
};

// Publish a tournament
export const publishTournament = async (tournamentId) => {
    try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
        const response = await fetch(`${backendUrl}/api/tournaments/publish/${tournamentId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                ...getHeaders()
            }
        });

        if (!response.ok) {
            // Check if the response is JSON
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const errorData = await response.json();
                throw new Error(errorData.message || `Failed to publish tournament (${response.status})`);
            } else {
                // Handle non-JSON error response
                const errorText = await response.text();
                console.error('Server returned non-JSON response:', errorText);
                throw new Error(`Server error (${response.status}): Please check the API endpoint`);
            }
        }
        
        // Check if response has content before parsing
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return await response.json();
        } else {
            // For empty or non-JSON successful responses
            return { success: true };
        }
    } catch (error) {
        console.error('Error publishing tournament:', error);
        throw error;
    }
};

// Unpublish a tournament
export const unpublishTournament = async (tournamentId) => {
    try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
        const response = await fetch(`${backendUrl}/api/tournaments/unpublish/${tournamentId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                ...getHeaders()
            }
        });

        if (!response.ok) {
            // Check if the response is JSON
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const errorData = await response.json();
                throw new Error(errorData.message || `Failed to unpublish tournament (${response.status})`);
            } else {
                // Handle non-JSON error response
                const errorText = await response.text();
                console.error('Server returned non-JSON response:', errorText);
                throw new Error(`Server error (${response.status}): Please check the API endpoint`);
            }
        }
        
        // Check if response has content before parsing
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return await response.json();
        } else {
            // For empty or non-JSON successful responses
            return { success: true };
        }
    } catch (error) {
        console.error('Error unpublishing tournament:', error);
        throw error;
    }
};