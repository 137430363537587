import "./Header.css";
import SignInMenu from "./SignInMenu";
import { useNavigate } from "react-router-dom";

export default function Header() {
    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate("/");
    };

  return (
    <header>
      {/* Top Banner - Background color is already #262f3e in CSS */}
      <div className="top-banner">
          PickleballTMS <strong>Powers</strong> Tournaments
      </div>

      {/* Main Header */}
      <div className="main-header">
          <div className="logo-container">
              {/* Logo image with adjusted position */}
              <img 
                src="/images/logo/tms-logo.png" 
                alt="PickleballTMS Logo"
                className="header-logo"
                onClick={navigateToHome}
                style={{ cursor: "pointer", height: "40px" }}
              />
          </div>
          <div className="logo-text-container">
             <h1 className="logo" onClick={navigateToHome} style={{ cursor: "pointer", minWidth: "100px" }}>
               <span className="visually-hidden">PickleballTMS</span>
               &nbsp;
             </h1>
          </div>

        {/* Search Bar */}
        <div className="search-bar">
          <input type="text" placeholder="Search for tournaments" />
        </div>

        {/* Icons */}
        <div className="header-icons">
          {/* Sign In */}
          <div className="icon-item">
            <SignInMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

