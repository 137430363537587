import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select';
import './DivisionSelector.css';

const DivisionSelector = ({ value, onChange, field }) => {
    // Use a ref to track initialization state
    const isInitialized = useRef(false);
    const skipNextValueUpdate = useRef(false);
    const popoverRef = useRef(null);
    
    // State for component
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [selectedRatings, setSelectedRatings] = useState([]);
    const [skillAgeMap, setSkillAgeMap] = useState({});
    const [divisions, setDivisions] = useState([]);
    const [showDivisions, setShowDivisions] = useState(false);
    
    // Error states
    const [errors, setErrors] = useState({
        skills: null,
        genders: null,
        ratings: null,
        ages: {}
    });
    
    // Map config options to react-select format
    const skillOptions = field.fields.skillCategory.options.map(opt => ({ label: opt, value: opt }));
    const genderOptions = field.fields.divisionGenderCategory.options.map(opt => ({ label: opt, value: opt }));
    const ratingOptions = field.fields.ratingsRange.options.map(opt => ({ label: opt, value: opt }));
    
    // Add Select All functions
    const selectAllSkills = () => {
        setSelectedSkills(skillOptions);
        
        // Update skillAgeMap to include all skills
        const updatedSkillAgeMap = { ...skillAgeMap };
        skillOptions.forEach(({ value }) => {
            if (!updatedSkillAgeMap[value]) {
                updatedSkillAgeMap[value] = [];
            }
        });
        setSkillAgeMap(updatedSkillAgeMap);
        
        // Clear any skill-related errors
        setErrors(prev => ({ ...prev, skills: null }));
    };
    
    const selectAllGenders = () => {
        setSelectedGenders(genderOptions);
        
        // Clear any gender-related errors
        setErrors(prev => ({ ...prev, genders: null }));
    };
    
    const selectAllRatings = () => {
        setSelectedRatings(ratingOptions);
        
        // Clear any rating-related errors
        setErrors(prev => ({ ...prev, ratings: null }));
    };
    
    const selectAllAges = (skill) => {
        const ageOptions = getAgeOptions(skill);
        setSkillAgeMap(prev => ({
            ...prev,
            [skill]: ageOptions
        }));
        
        // Clear any age-related errors for this skill
        setErrors(prev => ({
            ...prev,
            ages: { ...prev.ages, [skill]: null }
        }));
    };
    
    // Close popover when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                setShowDivisions(false);
            }
        }
        
        // Add event listener when popover is shown
        if (showDivisions) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        
        // Clean up event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDivisions]);
    
    // Initialize from value prop only once or when value.id changes
    useEffect(() => {
        // Skip if we just triggered an onChange that updated the value prop
        if (skipNextValueUpdate.current) {
            skipNextValueUpdate.current = false;
            return;
        }
        
        // Only set state from props during initialization or when receiving a new tournament
        const valueId = value?.id || 'new';
        const currentId = isInitialized.current;
        
        if (!isInitialized.current || valueId !== currentId) {
            if (value) {
                // Extract selected skills from value
                if (value.skillCategory) {
                    setSelectedSkills(value.skillCategory.map(skill => ({ label: skill, value: skill })));
                } else {
                    setSelectedSkills([]);
                }
                
                // Extract gender categories
                if (value.divisionGenderCategory) {
                    setSelectedGenders(value.divisionGenderCategory.map(gender => ({ label: gender, value: gender })));
                } else {
                    setSelectedGenders([]);
                }
                
                // Extract rating ranges
                if (value.ratingsRange) {
                    setSelectedRatings(value.ratingsRange.map(rating => ({ label: rating, value: rating })));
                } else {
                    setSelectedRatings([]);
                }
                
                // Extract age ranges
                if (value.ageRanges) {
                    // Convert any string arrays to react-select format
                    const formattedAgeMap = {};
                    Object.keys(value.ageRanges).forEach(skill => {
                        const ages = value.ageRanges[skill];
                        if (Array.isArray(ages)) {
                            formattedAgeMap[skill] = ages.map(age => 
                                typeof age === 'string' ? { label: age, value: age } : age
                            );
                        } else {
                            formattedAgeMap[skill] = ages;
                        }
                    });
                    setSkillAgeMap(formattedAgeMap);
                } else {
                    setSkillAgeMap({});
                }
            }
            
            isInitialized.current = valueId;
        }
    }, [value]);
    
    // Helper functions for generating structured division objects
    const getDivisionTypeFromSkill = (skill) => {
        const skillLower = skill.toLowerCase();
        if (skillLower.includes('singles')) return 'singles';
        if (skillLower.includes('doubles')) return 'doubles';
        if (skillLower.includes('mixed')) return 'mixed';
        return 'singles'; // Default
    };

    const parseRatingRange = (rating) => {
        const parts = rating.split('-');
        return {
            min: parts[0]?.trim() || '',
            max: parts.length > 1 ? parts[1].trim() : parts[0]?.trim() || ''
        };
    };

    const generateDivisionId = (gender, skill, rating, age = '') => {
        const baseId = `${gender}-${skill}-${rating}`.toLowerCase().replace(/\s+/g, '-');
        return age ? `${baseId}-${age}`.replace(/[()]/g, '') : baseId;
    };

    // Memoized function to generate divisions as structured objects
    const generateDivisionsData = useCallback(() => {
        const newDivisions = [];
        
        selectedSkills.forEach(({ value: skill }) => {
            const ages = skillAgeMap[skill] || [];
            const divisionType = getDivisionTypeFromSkill(skill);
            
            if (ages.length === 0) {
                // If no ages selected for this skill category, create divisions with default age range
                selectedGenders.forEach(({ value: gender }) => {
                    selectedRatings.forEach(({ value: rating }) => {
                        const divisionName = `${gender} ${skill} ${rating}`;
                        const ratingRange = parseRatingRange(rating);
                        
                        newDivisions.push({
                            name: divisionName,
                            id: generateDivisionId(gender, skill, rating),
                            skillCategory: skill,
                            divisionType: divisionType.toLowerCase(), 
                            genderCategory: gender.toLowerCase(), 
                            ratingRange: ratingRange,
                            // Add default age range
                            ageRange: {
                                label: "All Ages",
                                value: "0-100"
                            }
                        });
                    });
                });
            } else {
                // Create divisions with specified age ranges
                ages.forEach(({ value: age, label: ageLabel }) => {
                    selectedGenders.forEach(({ value: gender }) => {
                        selectedRatings.forEach(({ value: rating }) => {
                            const divisionName = `${gender} ${skill} ${rating} (${age})`;
                            const ratingRange = parseRatingRange(rating);
                            
                            newDivisions.push({
                                name: divisionName,
                                id: generateDivisionId(gender, skill, rating, age),
                                skillCategory: skill,
                                divisionType: divisionType.toLowerCase(),
                                genderCategory: gender.toLowerCase(),
                                ratingRange: ratingRange,
                                ageRange: {
                                    label: ageLabel || age,
                                    value: age
                                }
                            });
                        });
                    });
                });
            }
        });
        
        return newDivisions;
    }, [selectedSkills, selectedGenders, selectedRatings, skillAgeMap]);
    
    // Update divisions when selections change
    useEffect(() => {
        setDivisions(generateDivisionsData());
    }, [generateDivisionsData]);

    // Handle notifying parent component of changes
    const notifyChanges = useCallback(() => {
        // Skip if not initialized yet
        if (!isInitialized.current) return;
        
        // Set flag to avoid circular updates
        skipNextValueUpdate.current = true;
        
        // Create a value object to pass to onChange
        const newValue = {
            skillCategory: selectedSkills.map(item => item.value),
            divisionGenderCategory: selectedGenders.map(item => item.value),
            ratingsRange: selectedRatings.map(item => item.value),
            ageRanges: Object.keys(skillAgeMap).reduce((acc, skill) => {
                acc[skill] = (skillAgeMap[skill] || []).map(item => item.value);
                return acc;
            }, {}),
            generatedDivisions: generateDivisionsData()
        };
        
        onChange(newValue);
    }, [selectedSkills, selectedGenders, selectedRatings, skillAgeMap, onChange, generateDivisionsData]);
    
    // Call notify after state changes have settled
    useEffect(() => {
        // Skip the first render
        if (!isInitialized.current) return;
        
        // Use timeout to batch updates and reduce flickering
        const timer = setTimeout(() => {
            notifyChanges();
        }, 0);
        
        return () => clearTimeout(timer);
    }, [notifyChanges]);

    // Validate for duplicate selection
    const checkForDuplicates = (items) => {
        const values = items.map(item => item.value);
        return values.length !== new Set(values).size;
    };

    // Handle skill category changes
    const handleSkillChange = (skills) => {
        // Check for duplicates
        if (skills && checkForDuplicates(skills)) {
            setErrors(prev => ({ ...prev, skills: "Duplicate skill categories are not allowed" }));
            return;
        }
        
        setErrors(prev => ({ ...prev, skills: null }));
        setSelectedSkills(skills || []);
        
        // Update skillAgeMap to keep only selected skills
        const updatedSkillAgeMap = {};
        (skills || []).forEach(({ value }) => {
            if (skillAgeMap[value]) {
                updatedSkillAgeMap[value] = skillAgeMap[value];
            } else {
                updatedSkillAgeMap[value] = [];
            }
        });
        setSkillAgeMap(updatedSkillAgeMap);
    };

    // Handle gender category changes
    const handleGenderChange = (genders) => {
        // Check for duplicates
        if (genders && checkForDuplicates(genders)) {
            setErrors(prev => ({ ...prev, genders: "Duplicate gender categories are not allowed" }));
            return;
        }
        
        setErrors(prev => ({ ...prev, genders: null }));
        setSelectedGenders(genders || []);
    };

    // Handle ratings range changes
    const handleRatingChange = (ratings) => {
        // Check for duplicates
        if (ratings && checkForDuplicates(ratings)) {
            setErrors(prev => ({ ...prev, ratings: "Duplicate rating ranges are not allowed" }));
            return;
        }
        
        setErrors(prev => ({ ...prev, ratings: null }));
        setSelectedRatings(ratings || []);
    };

    // Handle age range changes for a specific skill
    const handleAgeChange = (skill, ages) => {
        // Check for duplicates
        if (ages && checkForDuplicates(ages)) {
            setErrors(prev => ({
                ...prev,
                ages: { ...prev.ages, [skill]: "Duplicate age ranges are not allowed" }
            }));
            return;
        }
        
        setErrors(prev => ({
            ...prev,
            ages: { ...prev.ages, [skill]: null }
        }));
        
        setSkillAgeMap(prev => ({
            ...prev,
            [skill]: ages || []
        }));
    };

    // Get age range options for a specific skill category
    const getAgeOptions = (skill) => {
        const options = field.fields.ageRanges.childOptions[skill] || [];
        return options.map(age => ({ label: age, value: age }));
    };
    
    // Toggle divisions popover
    const toggleDivisions = (e) => {
        e.preventDefault();
        setShowDivisions(!showDivisions);
    };

    return (
        <div className="division-selector-vertical">
            <div className="form-section-vertical">
                <h3 className="section-title">Skill Categories</h3>
                <div className="select-actions">
                    <button 
                        type="button" 
                        onClick={selectAllSkills}
                        className="select-all-button"
                    >
                        Select All
                    </button>
                </div>
                <div className="form-control-wrapper">
                    <Select
                        isMulti
                        options={skillOptions}
                        value={selectedSkills}
                        onChange={handleSkillChange}
                        placeholder="Select skill categories"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        isSearchable={true}
                    />
                    {errors.skills && <div className="error-message">{errors.skills}</div>}
                </div>
            </div>

            {selectedSkills.map(({ value: skill }) => (
                <div className="form-section-vertical" key={skill}>
                    <h3 className="section-title">Age Ranges for {skill}</h3>
                    <div className="select-actions">
                        <button 
                            type="button" 
                            onClick={() => selectAllAges(skill)}
                            className="select-all-button"
                        >
                            Select All
                        </button>
                    </div>
                    <div className="form-control-wrapper">
                        <Select
                            isMulti
                            options={getAgeOptions(skill)}
                            value={skillAgeMap[skill] || []}
                            onChange={(ages) => handleAgeChange(skill, ages)}
                            placeholder={`Select age ranges for ${skill}`}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            blurInputOnSelect={false}
                            isSearchable={true}
                        />
                        {errors.ages[skill] && <div className="error-message">{errors.ages[skill]}</div>}
                    </div>
                </div>
            ))}

            <div className="form-section-vertical">
                <h3 className="section-title">Gender Categories</h3>
                <div className="select-actions">
                    <button 
                        type="button" 
                        onClick={selectAllGenders}
                        className="select-all-button"
                    >
                        Select All
                    </button>
                </div>
                <div className="form-control-wrapper">
                    <Select
                        isMulti
                        options={genderOptions}
                        value={selectedGenders}
                        onChange={handleGenderChange}
                        placeholder="Select gender categories"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        isSearchable={true}
                    />
                    {errors.genders && <div className="error-message">{errors.genders}</div>}
                </div>
            </div>

            <div className="form-section-vertical">
                <h3 className="section-title">Rating Ranges</h3>
                <div className="select-actions">
                    <button 
                        type="button" 
                        onClick={selectAllRatings}
                        className="select-all-button"
                    >
                        Select All
                    </button>
                </div>
                <div className="form-control-wrapper">
                    <Select
                        isMulti
                        options={ratingOptions}
                        value={selectedRatings}
                        onChange={handleRatingChange}
                        placeholder="Select rating ranges"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        isSearchable={true}
                    />
                    {errors.ratings && <div className="error-message">{errors.ratings}</div>}
                </div>
            </div>

            {divisions.length > 0 && (
                <div className="form-section-vertical division-preview-section">
                    <div className="division-summary">
                        <button 
                            type="button"
                            onClick={toggleDivisions} 
                            className="view-divisions-link"
                        >
                            Preview Generated Divisions ({divisions.length})
                        </button>
                        
                        {showDivisions && (
                            <div className="divisions-popover" ref={popoverRef}>
                                <div className="popover-header">
                                    <h4>Generated Divisions ({divisions.length})</h4>
                                    <button 
                                        type="button"
                                        className="close-button" 
                                        onClick={() => setShowDivisions(false)}
                                        aria-label="Close"
                                    >
                                        ×
                                    </button>
                                </div>
                                <div className="divisions-list">
                                    {divisions.length === 0 ? (
                                        <div className="no-divisions">No divisions generated yet.</div>
                                    ) : (
                                        divisions.map((division, index) => (
                                            <div key={division.id || index} className="division-item">
                                                {division.name}
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DivisionSelector;
