import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import SignOut from './components/SignOut';
import CreateClub from './components/createclub/CreateClub';
import ClubList from './components/viewclub/ClubList';
import CreateTournament from './components/createtournament/CreateTournament';
import TournamentList from './components/viewtournament/TournamentList';
import RegisterTournament from './components/register/RegisterTournament';
import Home from './components/Home';
import Header from './components/Header';
import ScheduleTournament from './components/schedule/ScheduleTournament';

const AppRoutes = () => (
  <BrowserRouter>
    <div className="App">
      <Header />
      <main>
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signout" element={<SignOut />} />
          <Route path="/createclub" element={<CreateClub />} />
          <Route path="/editclub/:clubId" element={<CreateClub />} />
          <Route path="/createtournament" element={<CreateTournament />} />
          <Route path="/edittournament/:tid" element={<CreateTournament />} />
          <Route path="/register-tournament" element={<RegisterTournament />} />
          <Route path="/register-tournament/:tournamentId" element={<RegisterTournament />} />
          <Route path="/clubs" element={<ClubList />} />
          <Route path="/tournaments" element={<TournamentList />} />
          <Route path="/schedule-tournament" element={<ScheduleTournament />} />
          <Route path="/schedule-tournament/:tournamentId" element={<ScheduleTournament />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
    </div>
  </BrowserRouter>
);

export default AppRoutes;
