import React, { useEffect, useMemo } from 'react';
import TextField from '../fields/TextField';
import NumberField from '../fields/NumberField';
import TextareaField from '../fields/TextareaField';
import SelectField from '../fields/SelectField';
import MultiSelectField from '../fields/MultiSelectField';
import DateField from '../fields/DateField';
import CheckboxField from '../fields/CheckboxField';

const GroupFieldEditor = ({
    fieldName,
    field,
    formData,
    setFormData,
    errors,
    setErrors,
    inputRefs,
    handleKeyDown
}) => {
    // Memoize groupValue to prevent unnecessary re-renders
    const groupValue = useMemo(() => {
        return formData[fieldName] || {};
    }, [formData, fieldName]);

    // Handle changes to a field within the group
    const handleGroupFieldChange = (subFieldName, value) => {
        setFormData({
            ...formData,
            [fieldName]: {
                ...groupValue,
                [subFieldName]: value
            }
        });

        // Clear any errors for this field
        if (errors[`${fieldName}.${subFieldName}`]) {
            setErrors({
                ...errors,
                [`${fieldName}.${subFieldName}`]: ''
            });
        }
    };

    // Inside the component, add this check before rendering the address fields
    // This ensures the address structure is consistent before rendering
    useEffect(() => {
        if (fieldName === 'address' && groupValue) {
            // Ensure address uses the addressLine1/addressLine2 format consistently
            const standardizedAddress = {
                addressLine1: groupValue.addressLine1 || groupValue.street || "",
                addressLine2: groupValue.addressLine2 || groupValue.street2 || "",
                city: groupValue.city || "",
                state: groupValue.state || "", 
                zipCode: groupValue.zipCode || "",
                country: groupValue.country || ""
            };
            
            // Only update if actual changes are needed
            if (JSON.stringify(standardizedAddress) !== JSON.stringify(groupValue)) {
                // Use setFormData instead of undefined setGroupValue
                setFormData({
                    ...formData,
                    [fieldName]: standardizedAddress
                });
            }
        }
    }, [fieldName, groupValue, formData, setFormData]);

    // Render the appropriate field component based on field type
    const renderField = (subFieldName, subField) => {
        const value = groupValue[subFieldName];
        const error = errors[`${fieldName}.${subFieldName}`] || '';

        switch (subField.type) {
            case 'text':
                return (
                    <TextField
                        fieldName={`${fieldName}.${subFieldName}`}
                        label={subField.label}
                        value={value || ''}
                        onChange={(value) => handleGroupFieldChange(subFieldName, value)} // FIX: accept value directly
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}.${subFieldName}`] = el}
                        onKeyDown={handleKeyDown}
                        helpText={subField.helpText}
                    />
                );
            case 'number':
                return (
                    <NumberField
                        fieldName={`${fieldName}.${subFieldName}`}
                        label={subField.label}
                        value={value || ''}
                        onChange={(value) => handleGroupFieldChange(subFieldName, value)} // FIX: accept value directly
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}.${subFieldName}`] = el}
                        onKeyDown={handleKeyDown}
                        helpText={subField.helpText}
                    />
                );
            case 'textarea':
                return (
                    <TextareaField
                        fieldName={`${fieldName}.${subFieldName}`}
                        label={subField.label}
                        value={value || ''}
                        onChange={(value) => handleGroupFieldChange(subFieldName, value)} // FIX: accept value directly
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}.${subFieldName}`] = el}
                        onKeyDown={handleKeyDown}
                        helpText={subField.helpText}
                    />
                );
            case 'select':
                return (
                    <SelectField
                        fieldName={`${fieldName}.${subFieldName}`}
                        label={subField.label}
                        value={value || ''}
                        options={subField.options || []}
                        onChange={(value) => handleGroupFieldChange(subFieldName, value)} // FIX: accept value directly
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}.${subFieldName}`] = el}
                        onKeyDown={handleKeyDown}
                        helpText={subField.helpText}
                    />
                );
            case 'multiselect':
                return (
                    <MultiSelectField
                        fieldName={`${fieldName}.${subFieldName}`}
                        label={subField.label}
                        value={Array.isArray(value) ? value : []}
                        options={subField.options || []}
                        onChange={(selected) => handleGroupFieldChange(subFieldName, selected)}
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}.${subFieldName}`] = el}
                        helpText={subField.helpText}
                    />
                );
            case 'date':
                return (
                    <DateField
                        fieldName={`${fieldName}.${subFieldName}`}
                        label={subField.label}
                        value={value || ''}
                        onChange={(date) => handleGroupFieldChange(subFieldName, date)}
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}.${subFieldName}`] = el}
                        onKeyDown={handleKeyDown}
                        helpText={subField.helpText}
                    />
                );
            case 'checkbox':
                return (
                    <CheckboxField
                        fieldName={`${fieldName}.${subFieldName}`}
                        label={subField.label}
                        checked={!!value}
                        onChange={(value) => handleGroupFieldChange(subFieldName, value)} // FIX: accept value directly
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}.${subFieldName}`] = el}
                        helpText={subField.helpText}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="group-field-editor-container" data-fieldname={fieldName} style={{width: '100%', maxWidth: '700px'}}>
            <h3>{field.label}</h3>
            {field.helpText && <p className="help-text">{field.helpText}</p>}

            <div className="group-fields">
                {Object.keys(field.fields).map(subFieldName => (
                    <div key={subFieldName} className="group-field">
                        {renderField(subFieldName, field.fields[subFieldName])}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GroupFieldEditor;