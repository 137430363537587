import React from 'react';
import { getGenderText } from '../utils/formatUtils';

const PartnerInfoSection = ({ genderFormats, partners, onChange }) => {
  // Only show if any doubles format is selected
  if (!genderFormats.some(gender => getGenderText(gender).includes('doubles'))) {
    return null;
  }

  // Check which types of doubles are selected
  const hasMixed = genderFormats.some(g => getGenderText(g).includes('mixed'));
  const hasMensDoubles = genderFormats.some(g => {
    const text = getGenderText(g);
    return text.includes('men') && text.includes('doubles') && !text.includes('mixed');
  });
  const hasWomensDoubles = genderFormats.some(g => {
    const text = getGenderText(g);
    return text.includes('women') && text.includes('doubles');
  });

  return (
    <div className="partner-info-container">
      <h4>Partner Information</h4>
      
      {/* Mixed Doubles Partner */}
      {hasMixed && (
        <div className="partner-info-section">
          <h5>Mixed Doubles Partner</h5>
          <div className="form-group">
            <label htmlFor="partner_mixed_email">Partner Email</label>
            <input 
              type="email" 
              id="partner_mixed_email" 
              name="partner_mixed_email" 
              value={partners.mixed.email} 
              onChange={onChange}
              placeholder="Enter your partner's email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="partner_mixed_phone">Partner Phone</label>
            <input 
              type="tel" 
              id="partner_mixed_phone" 
              name="partner_mixed_phone" 
              value={partners.mixed.phone} 
              onChange={onChange}
              placeholder="Enter your partner's phone number"
              required
            />
          </div>
        </div>
      )}
      
      {/* Men's Doubles Partner */}
      {hasMensDoubles && (
        <div className="partner-info-section">
          <h5>Men's Doubles Partner</h5>
          <div className="form-group">
            <label htmlFor="partner_mens_email">Partner Email</label>
            <input 
              type="email" 
              id="partner_mens_email" 
              name="partner_mens_email" 
              value={partners.mens.email} 
              onChange={onChange}
              placeholder="Enter your partner's email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="partner_mens_phone">Partner Phone</label>
            <input 
              type="tel" 
              id="partner_mens_phone" 
              name="partner_mens_phone" 
              value={partners.mens.phone} 
              onChange={onChange}
              placeholder="Enter your partner's phone number"
              required
            />
          </div>
        </div>
      )}
      
      {/* Women's Doubles Partner */}
      {hasWomensDoubles && (
        <div className="partner-info-section">
          <h5>Women's Doubles Partner</h5>
          <div className="form-group">
            <label htmlFor="partner_womens_email">Partner Email</label>
            <input 
              type="email" 
              id="partner_womens_email" 
              name="partner_womens_email" 
              value={partners.womens.email} 
              onChange={onChange}
              placeholder="Enter your partner's email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="partner_womens_phone">Partner Phone</label>
            <input 
              type="tel" 
              id="partner_womens_phone" 
              name="partner_womens_phone" 
              value={partners.womens.phone} 
              onChange={onChange}
              placeholder="Enter your partner's phone number"
              required
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerInfoSection;