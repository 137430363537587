import { useState, useEffect, useRef, useCallback } from 'react';
import { getGenderText } from '../utils/formatUtils';

/**
 * Custom hook to manage division matching based on selected match formats
 * @param {Array} selectedFormats - Selected match formats
 * @param {Array} availableDivisions - Available divisions to match against
 * @param {Object} formData - Form data containing skill, rating, and age
 * @returns {Object} - Division matching state and functions
 */
export const useDivisionMatching = (selectedFormats, availableDivisions, formData) => {
  const [matchingDivisions, setMatchingDivisions] = useState([]);
  const skipUpdateRef = useRef(false);

  /**
   * Find divisions that match the selected match formats
   */
  const findMatchingDivisions = useCallback((formats, divisions) => {
    if (!formats || formats.length === 0 || !divisions || divisions.length === 0) {
      return [];
    }

    console.log('---MATCHING DIVISIONS FOR FORMATS---');
    console.log('Selected formats:', formats);
    console.log('Available divisions:', divisions.map(d => d.label || d.value));
    console.log('Form data for filtering:', formData);
    
    const matchingDivs = divisions.filter(division => {
      const divText = (division.label || division.value || '').toLowerCase();
      
      // Filter by skill category if selected
      if (formData.skill && !divText.toLowerCase().includes(formData.skill.toLowerCase())) {
        return false;
      }
      
      // Filter by rating if selected
      if (formData.rating) {
        const ratingMatch = divText.includes(formData.rating);
        if (!ratingMatch) {
          return false;
        }
      }
      
      // Filter by age if selected
      if (formData.age) {
        // Check common age formats in division names: (18-34), (35-49), (50-59), (60+)
        const ageRegex = /\((\d+)-(\d+|\+)\)/;
        const ageMatch = divText.match(ageRegex);
        
        if (ageMatch) {
          const minAge = parseInt(ageMatch[1], 10);
          const maxAgeStr = ageMatch[2];
          const maxAge = maxAgeStr === '+' ? 999 : parseInt(maxAgeStr, 10);
          const userAge = parseInt(formData.age, 10);
          
          if (isNaN(userAge) || userAge < minAge || userAge > maxAge) {
            return false; // Age doesn't match this division
          }
        } else if (!divText.includes(formData.age)) {
          // If we can't parse the age range, do a simple text match
          return false;
        }
      }
      
      // Check if this division matches ANY of the selected formats
      for (const format of formats) {
        const formatText = getGenderText(format);
        console.log(`Checking if division "${divText}" matches format "${formatText}"`);
        
        // Men's singles - check with word boundaries
        if (formatText === "men's singles") {
          // Make sure it's men's singles, not women's singles
          if (divText.includes("men's single") && !divText.includes("women's")) {
            console.log(`✓ Match found: Men's singles for "${divText}"`);
            return true;
          }
        }
        
        // Women's singles
        else if (formatText === "women's singles") {
          if (divText.includes("women's single")) {
            console.log(`✓ Match found: Women's singles for "${divText}"`);
            return true;
          }
        }
        
        // Men's doubles
        else if (formatText === "men's doubles") {
          // Make sure it's men's doubles, not women's doubles
          if (divText.includes("men's double") && !divText.includes("women's") && !divText.includes("mixed")) {
            console.log(`✓ Match found: Men's doubles for "${divText}"`);
            return true;
          }
        }
        
        // Women's doubles
        else if (formatText === "women's doubles") {
          if (divText.includes("women's double") && !divText.includes("mixed")) {
            console.log(`✓ Match found: Women's doubles for "${divText}"`);
            return true;
          }
        }
        
        // Mixed doubles
        else if (formatText === "mixed doubles") {
          if (divText.includes("mixed double")) {
            console.log(`✓ Match found: Mixed doubles for "${divText}"`);
            return true;
          }
        }
      }
      
      return false;
    }).map(div => div.value);
    
    console.log('Matching division values:', matchingDivs);
    return matchingDivs;
  }, [formData]);

  /**
   * Update matching divisions when formats or available divisions change
   */
  useEffect(() => {
    // Skip if no formats are selected or no divisions are available
    if (selectedFormats.length === 0 || availableDivisions.length === 0) {
      console.log("Skipping division update - no formats or no available divisions");
      console.log("Formats:", selectedFormats);
      console.log("Available divisions:", availableDivisions.length);
      return;
    }
    
    // Use ref to prevent infinite updates
    if (skipUpdateRef.current) {
      skipUpdateRef.current = false;
      return;
    }
    
    console.log('Match formats changed - updating divisions');
    
    // Find all matching divisions for current formats
    const newMatchingDivs = findMatchingDivisions(selectedFormats, availableDivisions);
    
    // Only update if the matches are different
    const currentDivs = new Set(matchingDivisions);
    if (newMatchingDivs.length !== matchingDivisions.length || 
        newMatchingDivs.some(div => !currentDivs.has(div))) {
      console.log('Updating matching divisions to:', newMatchingDivs);
      skipUpdateRef.current = true;
      setMatchingDivisions(newMatchingDivs);
    }
  }, [selectedFormats, availableDivisions, findMatchingDivisions, matchingDivisions]);

  /**
   * Manually force an update of matching divisions
   */
  const updateMatches = useCallback(() => {
    const matches = findMatchingDivisions(selectedFormats, availableDivisions);
    setMatchingDivisions(matches);
    return matches;
  }, [findMatchingDivisions, selectedFormats, availableDivisions]);

  /**
   * Check if a specific division matches the selected formats
   */
  const isDivisionMatching = useCallback((divisionValue) => {
    const division = availableDivisions.find(d => d.value === divisionValue);
    if (!division) return false;
    
    // Use the same matching logic but just for one division
    const divText = (division.label || division.value || '').toLowerCase();
    
    for (const format of selectedFormats) {
      const formatText = getGenderText(format);
      
      // Direct text match
      if (divText.includes(formatText)) {
        return true;
      }
      
      // Singles match
      if (formatText.includes('singles') && 
          ((formatText.includes('men') && divText.includes('men')) || 
           (formatText.includes('women') && divText.includes('women'))) && 
          (divText.includes('single') || divText.includes('sgl'))) {
        return true;
      }
      
      // Doubles match
      if (formatText.includes('doubles') && 
          ((formatText.includes('men') && !formatText.includes('women') && divText.includes('men')) || 
           (formatText.includes('women') && divText.includes('women')) ||
           (formatText.includes('mixed') && divText.includes('mixed'))) && 
          (divText.includes('double') || divText.includes('dbl'))) {
        return true;
      }
    }
    
    return false;
  }, [selectedFormats, availableDivisions]);

  return {
    matchingDivisions,
    findMatchingDivisions,
    updateMatches,
    isDivisionMatching
  };
};