import React from 'react';
import { getGenderText } from '../utils/formatUtils';

const MatchFormatSelect = ({ selected, options, onChange }) => {
  return (
    <div className="form-group">
      <label htmlFor="gender">Match Format</label>
      <div className="custom-multi-select">
        <div className="selected-items">
          {selected.length === 0 && (
            <div className="placeholder">Select Match Format</div>
          )}
          {selected.map(gender => (
            <div key={gender} className="selected-tag">
              <span>{gender}</span>
              <button 
                type="button" 
                className="remove-tag" 
                onClick={() => onChange(gender, true)}
              >
                ×
              </button>
            </div>
          ))}
        </div>
        <div className="dropdown">
          {options.map(gender => {
            const genderText = getGenderText(gender);
            const isSelected = selected.includes(gender);
            const isMens = genderText.includes('men') && !genderText.includes('women');
            const isWomens = genderText.includes('women');
            const isDisabled = 
              (isWomens && selected.some(g => 
                getGenderText(g).includes('men') && !getGenderText(g).includes('women'))) ||
              (isMens && selected.some(g => getGenderText(g).includes('women')));
            
            return (
              <div 
                key={typeof gender === 'string' ? gender : gender.value || gender.name}
                className={`option ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
                onClick={() => {
                  if (!isDisabled) {
                    onChange(gender, isSelected);
                  }
                }}
              >
                <input 
                  type="checkbox" 
                  checked={isSelected} 
                  onChange={() => {}} // Handled by onClick
                  disabled={isDisabled}
                />
                <span>{typeof gender === 'string' ? gender : (gender.name || gender.value || '')}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MatchFormatSelect;