import React, { useState, useEffect } from 'react';
import './TournamentDraws.css';
import { initialDrawPlayers } from '../data/initialData';

const TournamentDraws = ({ players, tournamentId, divisionId }) => {
  // Use initialDrawPlayers as fallback if no players are provided
  const [drawPlayers, setDrawPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    // Check if players are provided directly, otherwise use initialDrawPlayers
    if (Array.isArray(players) && players.length > 0) {
      setDrawPlayers(players);
    } else {
      setDrawPlayers(initialDrawPlayers || []);
    }
  }, [players]);
  
  useEffect(() => {
    // Fetch players for a division if tournament and division IDs are provided
    if (tournamentId && divisionId) {
      const fetchDrawPlayers = async () => {
        setLoading(true);
        setError(null);
        
        try {
          const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
          const response = await fetch(
            `${backendUrl}/api/tournaments/${tournamentId}/divisions/${divisionId}/bracket`,
            {
              headers: {
                'Content-Type': 'application/json',
                'X-JWT': localStorage.getItem('jwt') || ''
              }
            }
          );
          
          if (!response.ok) {
            throw new Error(`Failed to fetch bracket data: ${response.status}`);
          }
          
          const data = await response.json();
          if (Array.isArray(data) && data.length > 0) {
            // Transform API data if needed
            setDrawPlayers(data);
          } else {
            // If no data or empty array, fall back to initialDrawPlayers
            console.log('No bracket data returned from API, using default data');
            setDrawPlayers(initialDrawPlayers || []);
          }
        } catch (error) {
          console.error('Error fetching bracket data:', error);
          setError(error.message);
          // Still use initialDrawPlayers as fallback on error
          setDrawPlayers(initialDrawPlayers || []);
        } finally {
          setLoading(false);
        }
      };
      
      fetchDrawPlayers();
    } else {
      // If no tournamentId or divisionId, use initialDrawPlayers
      setDrawPlayers(initialDrawPlayers || []);
    }
  }, [tournamentId, divisionId]);
  
  // Get the maximum round number
  const maxRound = Math.max(...drawPlayers.map(player => player.round), 0);
  
  // Group players by round
  const playersByRound = {};
  for (let i = 1; i <= maxRound; i++) {
    playersByRound[i] = drawPlayers.filter(player => player.round === i);
  }
  
  // Calculate the number of matches per round
  const matchesPerRound = {};
  for (let i = 1; i <= maxRound; i++) {
    matchesPerRound[i] = Math.max(...playersByRound[i].map(p => p.matchIndex), 0) + 1;
  }
  
  if (loading) {
    return <div className="tournament-draws-loading">Loading bracket data...</div>;
  }
  
  if (error) {
    return (
      <div className="tournament-draws-error">
        Error loading bracket data: {error}
        <div className="using-default-data">Using default bracket visualization.</div>
      </div>
    );
  }

  return (
    <div className="tournament-draws">
      <h3>Tournament Bracket</h3>
      
      {drawPlayers.length === 0 ? (
        <div className="no-draws-message">
          No bracket data available for this division.
        </div>
      ) : (
        <div className="bracket-container">
          {Array.from({ length: maxRound }, (_, i) => i + 1).map((round) => (
            <div key={`round-${round}`} className="bracket-round">
              <div className="round-header">
                {round === maxRound ? 'Finals' : round === maxRound - 1 ? 'Semifinals' : `Round ${round}`}
              </div>
              <div className="round-matches">
                {Array.from({ length: matchesPerRound[round] || 0 }, (_, matchIndex) => {
                  const topPlayer = drawPlayers.find(
                    p => p.round === round && p.matchIndex === matchIndex && p.position === 'top'
                  );
                  const bottomPlayer = drawPlayers.find(
                    p => p.round === round && p.matchIndex === matchIndex && p.position === 'bottom'
                  );
                  
                  return (
                    <div key={`match-${round}-${matchIndex}`} className="bracket-match">
                      <div className={`player top ${topPlayer?.isWinner ? 'winner' : ''}`}>
                        {topPlayer?.name || 'TBD'}
                      </div>
                      <div className={`player bottom ${bottomPlayer?.isWinner ? 'winner' : ''}`}>
                        {bottomPlayer?.name || 'TBD'}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TournamentDraws;