import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  // Check if user is logged in on initial load
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  // Check user logged in status
  const checkUserLoggedIn = () => {
    setLoading(true);

    // Get user data from session storage
    const userId = sessionStorage.getItem('userId');
    const email = sessionStorage.getItem('userEmail');
    const fullName = sessionStorage.getItem('userFullName');

    if (userId) {
      setUser({
        id: userId,
        name: fullName || email || 'User',
        email: email || ''
      });
      setIsLoggedIn(true);
    } else {
      setUser(null);
      setIsLoggedIn(false);
    }
    setLoading(false);
  };

  // Login function - updated to handle email-password login case
  const login = async (userDataOrEmail, passwordOrJwt = null, rememberMe = false) => {
    console.log('Login initiated with:', userDataOrEmail);
    
    // Check if we're receiving email+password instead of a user data object
    if (typeof userDataOrEmail === 'string' && typeof passwordOrJwt === 'string') {
      const email = userDataOrEmail;
      const password = passwordOrJwt;
      
      try {
        // Make API call to authenticate
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
        console.log('Attempting login to:', `${backendUrl}/api/auth/signin`);
        
        const response = await fetch(`${backendUrl}/api/auth/signin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({ email, password })
        }).catch(err => {
          console.error('Fetch error details:', err);
          throw new Error(`Connection error: ${err.message}`);
        });
        
        if (!response.ok) {
          const errorText = await response.text().catch(() => 'No error details');
          console.error('Login failed response:', response.status, errorText);
          throw new Error(`Login failed: ${response.status} - ${errorText}`);
        }
        
        // Get JWT from response header
        const jwt = response.headers.get('X-JWT');
        
        // Parse response body
        const userData = await response.json().catch(err => {
          console.error('JSON parse error:', err);
          return { email }; // Fallback with minimal data
        });
        
        console.log('Login successful, user data:', userData);
        
        // Handle the authenticated user data
        handleUserData(userData, jwt, rememberMe);
        return userData;
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
    } else {
      // Handle the original case where userDataResponse is an object
      handleUserData(userDataOrEmail, passwordOrJwt, rememberMe);
      return userDataOrEmail;
    }
  };
  
  // Helper function to process user data
  const handleUserData = (userData, jwt = null, rememberMe = false) => {
    if (!userData) {
      console.error('No user data provided to handleUserData');
      return;
    }
    
    console.log('Processing user data:', userData);
    
    // Handle different response structures
    let processedUserData = userData;

    // Check if response has a nested userData property
    if (userData.userProfile) {
      processedUserData = userData.userProfile;
    }
    
    // For handling simple email login case
    if (typeof userData === 'string' && userData.includes('@')) {
      // Create a minimal user object when only email is available
      processedUserData = {
        email: userData,
        id: btoa(userData), // Use base64 encoded email as ID in this case
      };
    }

    // Extract userId with fallbacks for different API response formats
    const userId = processedUserData.ID || 
                   processedUserData.id || 
                   processedUserData.userId || 
                   processedUserData.user?.id;

    if (!userId) {
      console.error('No user ID found in login response:', processedUserData);
      return;
    }

    // Store userId in sessionStorage or localStorage based on rememberMe
    const storage = rememberMe ? localStorage : sessionStorage;
    storage.setItem('userId', userId);

    // Store email if available (from different possible response formats)
    const email = processedUserData.email || processedUserData.user?.email;
    if (email) {
      storage.setItem('userEmail', email);
    }

    // Handle name fields with fallbacks
    const firstName = processedUserData.firstName || processedUserData.user?.firstName;
    const lastName = processedUserData.lastName || processedUserData.user?.lastName;
    const fullNameFromResponse = processedUserData.fullName || processedUserData.user?.fullName;

    // Construct full name with priority
    const fullName = fullNameFromResponse ||
      (firstName || lastName ? `${firstName || ''} ${lastName || ''}`.trim() : null) ||
      email ||
      'User';

    // Store names in storage
    storage.setItem('userFullName', fullName);
    if (firstName) storage.setItem('firstName', firstName);
    if (lastName) storage.setItem('lastName', lastName);

    // Store auth token if available
    const token = processedUserData.token || processedUserData.accessToken || processedUserData.user?.token;
    if (token) {
      storage.setItem('token', token);
    }

    // Store the JWT if available
    if (jwt) {
      localStorage.setItem('jwt', jwt);
      console.log('JWT stored from login response');
    }
    // Also check if JWT is available in the response body
    else if (userData.jwt || processedUserData.jwt) {
      const jwtFromBody = userData.jwt || processedUserData.jwt;
      localStorage.setItem('jwt', jwtFromBody);
      console.log('JWT from response body stored');
    }

    // Update state
    setUser({
      id: userId,
      name: fullName,
      email: email || '',
      firstName: firstName || '',
      lastName: lastName || ''
    });

    setIsLoggedIn(true);

    console.log('User logged in:', { id: userId, name: fullName });
  };

  // Logout function
  const logout = () => {
    console.log('Logging out user');

    // Clear localStorage items
    localStorage.removeItem('fp');
    localStorage.removeItem('jwt');
    localStorage.removeItem('failedSignInAttempts');
    localStorage.removeItem('fpFailedAttempts');

    // Clear sessionStorage items
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('userEmail');
    sessionStorage.removeItem('userFullName');
    sessionStorage.removeItem('firstName');
    sessionStorage.removeItem('lastName');
    sessionStorage.removeItem('token');

    // Update state
    setUser(null);
    setIsLoggedIn(false);

    console.log('User logged out');
  };

  // Value to be provided by the context
  const value = {
    user,
    isLoggedIn,
    loading,
    login,
    logout,
    checkUserLoggedIn
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
