/**
 * Transforms API match data into the format expected by the TournamentPlanner component
 * @param {Array} apiMatches - Match data from the API
 * @returns {Array} - Formatted match data
 */
export const transformApiMatchesToInitialMatches = (apiMatches) => {
  try {
    if (!Array.isArray(apiMatches)) {
      console.warn('Input is not an array:', apiMatches);
      return [];
    }
    
    return apiMatches.map((match) => {
      let player1 = { firstname: '', lastname: '' };
      let player2 = { firstname: '', lastname: '' };
      let playersString = '';
      
      // Extract player IDs if available
      const playerIds = Object.keys(match.players || {});
      
      // Get player 1 data
      if (playerIds.length >= 1) {
        player1 = match.players[playerIds[0]] || player1;
        playersString = `${player1.firstname} ${player1.lastname}`;
      }
      
      // Get player 2 data
      if (playerIds.length >= 2) {
        player2 = match.players[playerIds[1]] || player2;
        playersString += ` vs ${player2.firstname} ${player2.lastname}`;
      }
      
      // Calculate reasonable defaults for court and timeSlot if not provided
      const court = (parseInt(match.matchnumber || 0) % 8) + 1;
      const timeSlot = (parseInt(match.round || 0) * 2) % 9;
      
      return {
        id: match.MID || `match-${Math.random().toString(36).substr(2, 9)}`,
        court: court,
        timeSlot: timeSlot,
        players: playersString || 'Unnamed Players', // Keep for backward compatibility
        // Add separate player objects for individual manipulation
        player1: {
          id: playerIds[0] || '',
          name: `${player1.firstname} ${player1.lastname}`.trim(),
          firstname: player1.firstname,
          lastname: player1.lastname
        },
        player2: {
          id: playerIds[1] || '',
          name: `${player2.firstname} ${player2.lastname}`.trim(),
          firstname: player2.firstname,
          lastname: player2.lastname
        },
        round: match.round || '1',
        matchNumber: match.matchnumber || '0',
        stage: match.stage || 'unknown',
        matchFormat: match.matchFormat || 'singles',
        divisionId: match.DID || 'unknown-division',
        tournamentId: match.TID || 'unknown-tournament'
      };
    });
  } catch (error) {
    console.error('Error transforming matches:', error);
    return [];
  }
};