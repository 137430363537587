export const divisions = [

];

export const venues = [
  { id: 1, name: "Tennis Center - June 15, 2025" },
  { id: 2, name: "Tennis Center - June 16, 2025" },
  { id: 3, name: "Tennis Center - June 17, 2025" },
  { id: 4, name: "Sports Complex - June 15, 2025" },
  { id: 5, name: "Sports Complex - June 16, 2025" },
  { id: 6, name: "Downtown Courts - June 17, 2025" },
  { id: 7, name: "Downtown Courts - June 18, 2025" },
];

export const initialMatches = [
  { id: 101, court: 1, timeSlot: 0, players: "Player A vs Player B" },
  { id: 105, court: 1, timeSlot: 2, players: "Player E vs Player F" },
  { id: 112, court: 1, timeSlot: 5, players: "Player I vs Player J" },
  { id: 118, court: 1, timeSlot: 8, players: "Player M vs Player N" },
  { id: 102, court: 2, timeSlot: 0, players: "Player C vs Player D" },
  { id: 106, court: 2, timeSlot: 2, players: "Player G vs Player H" },
  { id: 110, court: 2, timeSlot: 4, players: "Player K vs Player L" },
  { id: 115, court: 2, timeSlot: 7, players: "Player O vs Player P" },
];

export const initialDrawPlayers = [
  // Round 1
  { id: 1, round: 1, matchIndex: 0, position: 'top', name: 'Player A', isWinner: true },
  { id: 2, round: 1, matchIndex: 0, position: 'bottom', name: 'Player B', isWinner: false },
  { id: 3, round: 1, matchIndex: 1, position: 'top', name: 'Player C', isWinner: false },
  { id: 4, round: 1, matchIndex: 1, position: 'bottom', name: 'Player D', isWinner: true },
  { id: 5, round: 1, matchIndex: 2, position: 'top', name: 'Player E', isWinner: true },
  { id: 6, round: 1, matchIndex: 2, position: 'bottom', name: 'Player F', isWinner: false },
  { id: 7, round: 1, matchIndex: 3, position: 'top', name: 'Player G', isWinner: false },
  { id: 8, round: 1, matchIndex: 3, position: 'bottom', name: 'Player H', isWinner: true },
  
  // Semifinals
  { id: 9, round: 2, matchIndex: 0, position: 'top', name: 'Player A', isWinner: true },
  { id: 10, round: 2, matchIndex: 0, position: 'bottom', name: 'Player D', isWinner: false },
  { id: 11, round: 2, matchIndex: 1, position: 'top', name: 'Player E', isWinner: false },
  { id: 12, round: 2, matchIndex: 1, position: 'bottom', name: 'Player H', isWinner: true },
  
  // Finals
  { id: 13, round: 3, matchIndex: 0, position: 'top', name: 'Player A', isWinner: true },
  { id: 14, round: 3, matchIndex: 0, position: 'bottom', name: 'Player H', isWinner: false },
];

export const timeSlots = [
  '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM', 
  '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM'
];