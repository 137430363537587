// Utility functions for formatting match data

/**
 * Format a match for backend API
 */
export const formatMatchForBackend = (match, tournamentId) => {
  // Create players map and team arrays as required by backend
  const players = {};
  const teamA = [];
  const teamB = [];
  
  // Process player1 data
  if (match.player1) {
    const player1Id = match.player1.id || `player-${Date.now()}-1`;
    teamA.push(player1Id);
    
    // Add to players map
    players[player1Id] = {
      ID: player1Id,
      FirstName: getFirstName(match.player1.name || ''),
      LastName: getLastName(match.player1.name || ''),
      Email: match.player1.email || '',
      Phone: match.player1.phone || '',
      Skill: match.player1.skill || '',
      Rating: match.player1.rating || '',
      Age: match.player1.age || ''
    };
  }
  
  // Process player2 data
  if (match.player2) {
    const player2Id = match.player2.id || `player-${Date.now()}-2`;
    teamB.push(player2Id);
    
    // Add to players map
    players[player2Id] = {
      ID: player2Id,
      FirstName: getFirstName(match.player2.name || ''),
      LastName: getLastName(match.player2.name || ''),
      Email: match.player2.email || '',
      Phone: match.player2.phone || '',
      Skill: match.player2.skill || '',
      Rating: match.player2.rating || '',
      Age: match.player2.age || ''
    };
  }
  
  // Handle doubles format
  if (match.matchFormat === 'doubles') {
    if (match.player1b) {
      const player1bId = match.player1b.id || `player-${Date.now()}-1b`;
      teamA.push(player1bId);
      players[player1bId] = {
        ID: player1bId,
        FirstName: getFirstName(match.player1b.name || ''),
        LastName: getLastName(match.player1b.name || ''),
        Email: match.player1b.email || '',
        Phone: match.player1b.phone || '',
        Skill: match.player1b.skill || '',
        Rating: match.player1b.rating || '',
        Age: match.player1b.age || ''
      };
    }
    
    if (match.player2b) {
      const player2bId = match.player2b.id || `player-${Date.now()}-2b`;
      teamB.push(player2bId);
      players[player2bId] = {
        ID: player2bId,
        FirstName: getFirstName(match.player2b.name || ''),
        LastName: getLastName(match.player2b.name || ''),
        Email: match.player2b.email || '',
        Phone: match.player2b.phone || '',
        Skill: match.player2b.skill || '',
        Rating: match.player2b.rating || '',
        Age: match.player2b.age || ''
      };
    }
  }
  
  // Format scores properly
  const formattedScores = {};
  if (match.scores) {
    Object.entries(match.scores).forEach(([setKey, setData]) => {
      formattedScores[setKey] = [
        setData.player1 || '0',
        setData.player2 || '0'
      ];
    });
  }
  
  // Format officials
  const officials = [];
  if (match.chairUmpire) {
    officials.push({
      ID: `official-chair-${match.id || Date.now()}`,
      FirstName: match.chairUmpire,
      LastName: '',
      Email: '',
      Phone: '',
      Role: 'Chair Umpire'
    });
  }
  
  if (match.lineJudges) {
    officials.push({
      ID: `official-line-${match.id || Date.now()}`,
      FirstName: match.lineJudges,
      LastName: '',
      Email: '',
      Phone: '',
      Role: 'Line Judge'
    });
  }
  
  // Build the properly formatted match object
  return {
    TID: match.tournamentId || tournamentId,
    DID: match.divisionId || '',
    MID: match.id,
    Round: match.round || '',
    MatchNumber: match.matchNumber || '',
    Stage: match.stage || '',
    MatchFormat: match.matchFormat || 'singles',
    AgeCategory: match.ageCategory || '',
    SkillCategory: match.skillCategory || '',
    Division: match.division || '',
    Players: players,
    TeamA: teamA,
    TeamB: teamB,
    ScheduledTime: match.scheduledTime || '',
    ActualStartTime: match.actualStartTime || '',
    ActualEndTime: match.actualEndTime || '',
    CourtID: match.court ? match.court.toString() : '',
    Venue: match.venue || '',
    MatchStatus: match.status || 'scheduled',
    Scores: formattedScores,
    WinnerID: match.winnerId || '',
    LoserID: match.loserId || '',
    Officials: officials
  };
};

/**
 * Helper function to extract first name
 */
export const getFirstName = (fullName) => {
  if (!fullName) return '';
  const parts = fullName.split(' ');
  return parts[0] || '';
};

/**
 * Helper function to extract last name
 */
export const getLastName = (fullName) => {
  if (!fullName) return '';
  const parts = fullName.split(' ');
  return parts.length > 1 ? parts.slice(1).join(' ') : '';
};

/**
 * Parse a backend match into frontend format
 */
export const parseMatchFromBackend = (backendMatch) => {
  // Extract player info from Players map and TeamA/TeamB arrays
  let player1 = null;
  let player2 = null;
  let player1b = null;
  let player2b = null;
  
  if (backendMatch.TeamA && backendMatch.TeamA.length > 0) {
    const player1Id = backendMatch.TeamA[0];
    const player1Info = backendMatch.Players[player1Id];
    
    if (player1Info) {
      player1 = {
        id: player1Id,
        name: `${player1Info.FirstName} ${player1Info.LastName}`.trim(),
        email: player1Info.Email,
        phone: player1Info.Phone,
        skill: player1Info.Skill,
        rating: player1Info.Rating,
        age: player1Info.Age
      };
    }
    
    // Check for doubles
    if (backendMatch.TeamA.length > 1) {
      const player1bId = backendMatch.TeamA[1];
      const player1bInfo = backendMatch.Players[player1bId];
      
      if (player1bInfo) {
        player1b = {
          id: player1bId,
          name: `${player1bInfo.FirstName} ${player1bInfo.LastName}`.trim(),
          email: player1bInfo.Email,
          phone: player1bInfo.Phone,
          skill: player1bInfo.Skill,
          rating: player1bInfo.Rating,
          age: player1bInfo.Age
        };
      }
    }
  }
  
  if (backendMatch.TeamB && backendMatch.TeamB.length > 0) {
    const player2Id = backendMatch.TeamB[0];
    const player2Info = backendMatch.Players[player2Id];
    
    if (player2Info) {
      player2 = {
        id: player2Id,
        name: `${player2Info.FirstName} ${player2Info.LastName}`.trim(),
        email: player2Info.Email,
        phone: player2Info.Phone,
        skill: player2Info.Skill,
        rating: player2Info.Rating,
        age: player2Info.Age
      };
    }
    
    // Check for doubles
    if (backendMatch.TeamB.length > 1) {
      const player2bId = backendMatch.TeamB[1];
      const player2bInfo = backendMatch.Players[player2bId];
      
      if (player2bInfo) {
        player2b = {
          id: player2bId,
          name: `${player2bInfo.FirstName} ${player2bInfo.LastName}`.trim(),
          email: player2bInfo.Email,
          phone: player2bInfo.Phone,
          skill: player2bInfo.Skill,
          rating: player2bInfo.Rating,
          age: player2bInfo.Age
        };
      }
    }
  }
  
  // Format scores
  const scores = {};
  if (backendMatch.Scores) {
    Object.entries(backendMatch.Scores).forEach(([set, scoreArray]) => {
      scores[set] = {
        player1: scoreArray[0] || '0',
        player2: scoreArray[1] || '0'
      };
    });
  }
  
  // Extract officials
  let chairUmpire = '';
  let lineJudges = '';
  
  if (backendMatch.Officials && backendMatch.Officials.length > 0) {
    backendMatch.Officials.forEach(official => {
      if (official.Role === 'Chair Umpire') {
        chairUmpire = `${official.FirstName} ${official.LastName}`.trim();
      } else if (official.Role === 'Line Judge') {
        lineJudges = `${official.FirstName} ${official.LastName}`.trim();
      }
    });
  }
  
  // Build the match object for frontend use
  return {
    id: backendMatch.MID,
    tournamentId: backendMatch.TID,
    divisionId: backendMatch.DID,
    round: backendMatch.Round,
    matchNumber: backendMatch.MatchNumber,
    stage: backendMatch.Stage,
    matchFormat: backendMatch.MatchFormat || 'singles',
    court: parseInt(backendMatch.CourtID) || null,
    timeSlot: parseInt(backendMatch.TimeSlot) || null,
    scheduledTime: backendMatch.ScheduledTime,
    actualStartTime: backendMatch.ActualStartTime,
    actualEndTime: backendMatch.ActualEndTime,
    venue: backendMatch.Venue,
    status: backendMatch.MatchStatus,
    
    // Player information
    player1,
    player2,
    player1b,
    player2b,
    
    // For backward compatibility
    players: player1 && player2 ? `${player1.name} vs ${player2.name}` : '',
    
    // Score and results
    scores,
    winnerId: backendMatch.WinnerID,
    loserId: backendMatch.LoserID,
    
    // Officials
    chairUmpire,
    lineJudges
  };
};