import { useEffect } from 'react';

// Export the sendFingerprint function
export const sendFingerprint = async () => {
  const loadFingerprintJS = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/@fingerprintjs/fingerprintjs@3/dist/fp.min.js';
      script.crossOrigin = 'anonymous';
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  };

  // Check if JWT token exists, is not expired, and has matching fingerprint
  const isJwtValid = () => {
    const jwt = localStorage.getItem('jwt');
    if (!jwt) return false;

    // Get stored fingerprint from localStorage
    const storedFingerprint = localStorage.getItem('fp');
    if (!storedFingerprint) {
      console.log('No fingerprint found in localStorage');
      return false;
    }

    // Try to decode the JWT to check expiration and fingerprint
    try {
      // JWT structure: header.payload.signature
      const payload = jwt.split('.')[1];
      if (!payload) return false;

      // Decode base64
      const decodedPayload = JSON.parse(atob(payload));

      // Check if token has fingerprint claim
      const tokenFingerprint = decodedPayload.fp || decodedPayload.fingerprint;
      if (!tokenFingerprint) {
        console.log('No fingerprint claim found in JWT');
        return true; // Continue with regular validation if no fp in token
      }

      // Compare fingerprints
      if (tokenFingerprint !== storedFingerprint) {
        console.warn('Fingerprint mismatch: JWT fingerprint does not match stored fingerprint');
        return false;
      }

      // Check if token has an expiration time
      if (!decodedPayload.exp) return true; // No expiration = valid

      // Check if token is expired (exp is in seconds since epoch)
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedPayload.exp <= currentTime) {
        console.log('JWT token has expired');
        return false;
      }

      console.log('JWT is valid with matching fingerprint');
      return true;
    } catch (e) {
      console.error('Error decoding JWT:', e);
      return false; // If we can't decode, assume invalid
    }
  };

  // Function to reset authentication data
  const resetAuthData = () => {
    console.log('Resetting authentication data after too many failed fingerprint API calls');
    localStorage.removeItem('jwt');
    localStorage.removeItem('fpFailedAttempts');
  };

  // Get the current number of failed attempts
  const getFailedAttempts = () => {
    const attempts = localStorage.getItem('fpFailedAttempts');
    return attempts ? parseInt(attempts, 10) : 0;
  };

  // Increment failed attempts counter and handle fingerprint mismatch
  const incrementFailedAttempts = (reason = 'api_failure') => {
    const currentAttempts = getFailedAttempts();
    const newAttempts = currentAttempts + 1;
    localStorage.setItem('fpFailedAttempts', newAttempts.toString());

    // Handle fingerprint mismatch immediately
    if (reason === 'fingerprint_mismatch') {
      console.warn('Security alert: Fingerprint in JWT does not match browser fingerprint');
      // Remove JWT and fingerprint from localStorage
      localStorage.removeItem('jwt');
      localStorage.removeItem('fp');
      // Reset failed attempts since we're handling this specially
      localStorage.setItem('fpFailedAttempts', '0');

      // Log the event
      console.log('Security action: Removed JWT and fingerprint from storage due to mismatch');

      // Add a small delay before refreshing to allow logs to be sent
      setTimeout(() => {
        console.log('Refreshing page after fingerprint mismatch detection');
        window.location.reload();
      }, 200);

      return 0; // Return 0 since we reset the counter
    }

    // Check if we need to reset for regular API failures
    if (newAttempts >= 3) {
      resetAuthData();
    }

    return newAttempts;
  };

  // Reset failed attempts counter
  const resetFailedAttempts = () => {
    localStorage.setItem('fpFailedAttempts', '0');
  };

  // First check if we have a valid JWT
  if (isJwtValid()) {
    console.log('JWT is still valid, skipping fingerprint request');
    // Reset failed attempts counter on valid JWT
    resetFailedAttempts();
    return;
  }

  try {
    await loadFingerprintJS();
    if (window.FingerprintJS) {
      const fp = await window.FingerprintJS.load();
      const result = await fp.get();
      const visitorId = result.visitorId;
      console.log(visitorId); // Fingerprint hash

      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

      // Create headers with Content-Type
      const headers = {
        'Content-Type': 'application/json',
      };

      // Add X-JWT header if JWT is already available in local storage
      const existingJwt = localStorage.getItem('jwt');
      if (existingJwt) {
        console.log('Including existing JWT in fingerprint request');
        headers['X-JWT'] = existingJwt;
      }

      try {
        const response = await fetch(`${backendUrl}/api/auth/jwt`, {
          method: 'POST',
          headers,
          body: JSON.stringify({ "fp": visitorId }),
          credentials: 'include' // Include cookies for CORS
        });

        if (!response.ok) {
          // Handle API error
          const attempts = incrementFailedAttempts();
          console.error(`Fingerprint API call failed (attempt ${attempts} of 3)`);
          throw new Error(`Network response was not ok (status ${response.status})`);
        }

        // API call was successful, reset failed attempts counter
        resetFailedAttempts();

        // Get the response body
        const data = await response.json();
        console.log('JWT:', data.jwt ? 'Received' : 'Not received');

        // Use JWT from response
        const newJwt = data.jwt;

        // Save JWT and fingerprint to local storage
        if (newJwt) {
          localStorage.setItem('jwt', newJwt);
          localStorage.setItem('fp', visitorId);
          console.log('JWT and fingerprint saved to local storage');
        } else {
          console.warn('API call succeeded but no JWT was returned');
        }
      } catch (apiError) {
        console.error('API error:', apiError);
        // Note: incrementFailedAttempts was already called if response.ok was false
        // Only increment again if it was a network/other error
        if (apiError.message && !apiError.message.includes('Network response was not ok')) {
          incrementFailedAttempts();
        }
      }
    } else {
      console.error('FingerprintJS is not defined');
    }
  } catch (error) {
    console.error('Error loading or using FingerprintJS:', error);
  }
};

const Fingerprint = () => {
  useEffect(() => {
    // Expose sendFingerprint globally for components that can't import it
    window.sendFingerprint = sendFingerprint;

    // Run sendFingerprint on component mount
    sendFingerprint();
  }, []);

  return null;
};

export default Fingerprint;
