import React from 'react';
import TextField from '../fields/TextField';
import NumberField from '../fields/NumberField';
import MultiSelectField from '../fields/MultiSelectField';
import TextareaField from '../fields/TextareaField';
import DateField from '../fields/DateField';
import SelectField from '../fields/SelectField';
import CheckboxField from '../fields/CheckboxField';
import GroupFieldEditor from './GroupFieldEditor';

// Update the formatAddressSummary function to handle both formats
const formatAddressSummary = (address) => {
    if (!address) return 'No address specified';
    
    console.log('Formatting address for display:', address);
    
    const parts = [];
    
    // Handle addressLine1/street
    if (address.addressLine1 || address.street) 
        parts.push(address.addressLine1 || address.street);
    
    // Handle addressLine2/street2
    if (address.addressLine2 || address.street2) 
        parts.push(address.addressLine2 || address.street2);
    
    if (address.city) parts.push(address.city);
    if (address.state) parts.push(address.state);
    if (address.zipCode) parts.push(address.zipCode);
    if (address.country) parts.push(address.country);

    return parts.length > 0 ? parts.join(', ') : 'Address details not available';
};

const ArrayFieldEditor = ({
    fieldName,
    field,
    formData,
    setFormData,
    errors,
    setErrors,
    inputRefs,
    handleKeyDown
}) => {
    // Get the array value, ensuring it's an array
    const arrayValue = Array.isArray(formData[fieldName]) ? formData[fieldName] : [];

    // Handle adding a new item
    const handleAddItem = () => {
        // Create a new array with an empty item added
        const newArray = [...arrayValue, {}];

        // Update formData with the new array
        const newFormData = { ...formData, [fieldName]: newArray };
        setFormData(newFormData);

        // Clear any errors for this new item
        const newErrors = { ...errors };
        Object.keys(newErrors).forEach(key => {
            if (key.startsWith(`${fieldName}[${arrayValue.length}]`)) {
                delete newErrors[key];
            }
        });
        setErrors(newErrors);
    };

    // Handle removing an item
    const handleRemoveItem = (index) => {
        // Create a new array with the item removed
        const newArray = arrayValue.filter((_, i) => i !== index);

        // Update formData with the new array
        const newFormData = { ...formData, [fieldName]: newArray };
        setFormData(newFormData);

        // Remove any errors for the removed item and reindex errors for higher items
        const newErrors = { ...errors };
        Object.keys(newErrors).forEach(key => {
            // Remove errors for this index
            if (key.startsWith(`${fieldName}[${index}]`)) {
                delete newErrors[key];
            }

            // Reindex errors for higher indexes
            const match = key.match(new RegExp(`${fieldName}\\[(\\d+)\\]`));
            if (match && parseInt(match[1]) > index) {
                const newIndex = parseInt(match[1]) - 1;
                const newKey = key.replace(`${fieldName}[${match[1]}]`, `${fieldName}[${newIndex}]`);
                newErrors[newKey] = newErrors[key];
                delete newErrors[key];
            }
        });
        setErrors(newErrors);
    };

    const updateItem = (index, itemFieldName, value) => {
        //  console.log('ArrayFieldEditor updateItem:', { index, itemFieldName, value });
        const newArray = [...arrayValue];
        newArray[index] = {
            ...newArray[index],
            [itemFieldName]: value
        };

        setFormData({
            ...formData,
            [fieldName]: newArray
        });

        // Clear error if field was updated
        if (errors[`${fieldName}[${index}].${itemFieldName}`]) {
            setErrors({
                ...errors,
                [`${fieldName}[${index}].${itemFieldName}`]: ''
            });
        }
    };

    const renderField = (itemFieldName, itemField, item, index) => {
        const error = errors[`${fieldName}[${index}].${itemFieldName}`] || '';
        const value = item[itemFieldName];

        // Render appropriate field based on type
        switch (itemField.type) {
            case 'text':
                return (
                    <TextField
                        fieldName={`${fieldName}[${index}].${itemFieldName}`}
                        label={itemField.label}
                        value={value || ''}
                        onChange={(value) => updateItem(index, itemFieldName, value)}
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}[${index}].${itemFieldName}`] = el}
                        handleKeyDown={handleKeyDown}
                        helpText={itemField.helpText}
                    />
                );
            case 'number':
                return (
                    <NumberField
                        fieldName={`${fieldName}[${index}].${itemFieldName}`}
                        label={itemField.label}
                        value={value || ''}
                        onChange={(e) => updateItem(index, itemFieldName, e.target.value)}
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}[${index}].${itemFieldName}`] = el}
                        onKeyDown={handleKeyDown}
                        helpText={itemField.helpText}
                    />
                );
            case 'textarea':
                return (
                    <TextareaField
                        fieldName={`${fieldName}[${index}].${itemFieldName}`}
                        label={itemField.label}
                        value={value || ''}
                        onChange={(e) => updateItem(index, itemFieldName, e.target.value)}
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}[${index}].${itemFieldName}`] = el}
                        onKeyDown={handleKeyDown}
                        helpText={itemField.helpText}
                    />
                );
            case 'select':
                return (
                    <SelectField
                        fieldName={`${fieldName}[${index}].${itemFieldName}`}
                        label={itemField.label}
                        value={value || ''}
                        options={itemField.options || []}
                        onChange={(e) => updateItem(index, itemFieldName, e.target.value)}
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}[${index}].${itemFieldName}`] = el}
                        onKeyDown={handleKeyDown}
                        helpText={itemField.helpText}
                    />
                );
            case 'multiselect':
                return (
                    <MultiSelectField
                        fieldName={`${fieldName}[${index}].${itemFieldName}`}
                        label={itemField.label}
                        value={Array.isArray(value) ? value : []}
                        options={itemField.options || []}
                        onChange={(selected) => updateItem(index, itemFieldName, selected)}
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}[${index}].${itemFieldName}`] = el}
                        helpText={itemField.helpText}
                    />
                );
            case 'date':
                return (
                    <DateField
                        fieldName={`${fieldName}[${index}].${itemFieldName}`}
                        label={itemField.label}
                        value={value || ''}
                        onChange={(date) => updateItem(index, itemFieldName, date)}
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}[${index}].${itemFieldName}`] = el}
                        onKeyDown={handleKeyDown}
                        helpText={itemField.helpText}
                    />
                );
            case 'checkbox':
                return (
                    <CheckboxField
                        fieldName={`${fieldName}[${index}].${itemFieldName}`}
                        label={itemField.label}
                        checked={!!value}
                        onChange={(e) => updateItem(index, itemFieldName, e.target.checked)}
                        error={error}
                        inputRef={(el) => inputRefs.current[`${fieldName}[${index}].${itemFieldName}`] = el}
                        helpText={itemField.helpText}
                    />
                );
            case 'group':
                if (itemField.type === "group" && itemField.fields) {
                    return (
                        <GroupFieldEditor
                            key={`${index}-${fieldName}`}
                            config={itemField}
                            value={item[itemFieldName] || {}}
                            onChange={(groupValue) => {
                                const newValue = { ...item, [itemFieldName]: groupValue };
                                updateItem(index, itemFieldName, newValue);
                            }}
                            errors={errors?.[itemFieldName]}
                        />
                    );
                }
                return (
                    <GroupFieldEditor
                        fieldName={`${fieldName}[${index}].${itemFieldName}`}
                        field={itemField}
                        formData={{ [`${fieldName}[${index}].${itemFieldName}`]: value || {} }}
                        setFormData={(newData) => {
                            updateItem(index, itemFieldName, newData[`${fieldName}[${index}].${itemFieldName}`]);
                        }}
                        errors={errors}
                        setErrors={setErrors}
                        inputRefs={inputRefs}
                        handleKeyDown={handleKeyDown}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="form-group array-field">
            <label>{field.label}</label>
            <div className="input-container array-container">
                {/* Display summaries of existing addresses if this is a venue array */}
                {fieldName.includes('venues') && arrayValue.length > 0 && (
                    <div className="existing-addresses">
                        <h4>Existing Venues</h4>
                        {arrayValue.map((item, index) => (
                            <div className="address-summary" key={index}>
                                <p className="venue-name">{item.name || `Venue ${index + 1}`}</p>
                                <p>{formatAddressSummary(item.address)}</p>
                                <p>Number of Courts: {item.numberOfCourts || 'Not specified'}</p>
                                <button
                                    type="button"
                                    className="button-delete"
                                    onClick={() => handleRemoveItem(index)}
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                    </div>
                )}

                {/* Form for adding new items */}
                <div className="array-items">
                    {arrayValue.map((item, index) => (
                        <div key={index} className="array-item">
                            <div className="array-item-header">
                                <h4 className="array-item-title">
                                    {field.label} #{index + 1}
                                </h4>
                                <button
                                    type="button"
                                    className="button-delete"
                                    onClick={() => handleRemoveItem(index)}
                                >
                                    Remove
                                </button>
                            </div>

                            {/* If itemFields is an object, render each field */}
                            {typeof field.itemFields === 'object' && !field.itemFields.type && (
                                Object.keys(field.itemFields).map(itemFieldName => {
                                    const itemField = field.itemFields[itemFieldName];
                                    const itemFieldPath = `${fieldName}[${index}].${itemFieldName}`;

                                    // For group fields (like address)
                                    if (itemField.type === 'group') {
                                        const addressFieldValue = item?.[itemFieldName] || {};
                                        return (
                                            <GroupFieldEditor
                                                key={itemFieldPath}
                                                fieldName={itemFieldPath}
                                                field={itemField}
                                                formData={formData}
                                                setFormData={setFormData}
                                                errors={errors}
                                                setErrors={setErrors}
                                                inputRefs={inputRefs}
                                                handleKeyDown={handleKeyDown}
                                                value={addressFieldValue}
                                                onChange={(addressValue) => updateItem(index, itemFieldName, addressValue)}
                                            />
                                        );
                                    }

                                    // For other field types, render appropriate input
                                    return (
                                        <div key={itemFieldName} className="array-item-field">
                                            {renderField(itemFieldName, itemField, item, index)}
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    ))}

                    <div className="array-actions">
                        <button
                            type="button"
                            className="button-add"
                            onClick={handleAddItem}
                        >
                            Add {field.label}
                        </button>
                    </div>
                </div>

                {field.helpText && <p className="help-text">{field.helpText}</p>}
            </div>
        </div>
    );
};

export default ArrayFieldEditor;