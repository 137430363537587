import React from 'react';

const SkillCategorySelect = ({ value, onChange, options }) => {
  return (
    <div className="form-group">
      <label htmlFor="skill">Skill Category</label>
      <select 
        id="skill" 
        name="skill" 
        value={value} 
        onChange={onChange}
        required
      >
        <option value="">Select Skill Category</option>
        {options.map(skill => (
          <option key={skill} value={skill}>{skill}</option>
        ))}
      </select>
    </div>
  );
};

export default SkillCategorySelect;