import React, { useState, useEffect, useRef } from 'react';
import './TournamentPlanner.css';

const MatchDetailsPopover = ({ match, position, onClose, onUpdate, courts, timeSlots }) => {
  const [matchDetails, setMatchDetails] = useState({...match});
  const popoverRef = useRef(null);
  const [activeSection, setActiveSection] = useState('basic'); // Track active section
  
  console.log("MatchDetailsPopover rendering with position:", position);

  // Position the popover in the center of the visible viewport
  useEffect(() => {
    if (!popoverRef.current) return;
    
    // Get viewport dimensions
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    
    // Get popover dimensions
    const popoverRect = popoverRef.current.getBoundingClientRect();
    const popoverHeight = popoverRect.height;
    const popoverWidth = popoverRect.width;
    
    // Reset transform to correctly measure the element
    popoverRef.current.style.transform = 'none';
    
    // Calculate a position that's centered in the viewport
    let top = (viewportHeight - popoverHeight) / 2;
    let left = (viewportWidth - popoverWidth) / 2;
    
    // Ensure popover stays within viewport bounds (with 20px margin)
    top = Math.max(20, Math.min(top, viewportHeight - popoverHeight - 20));
    left = Math.max(20, Math.min(left, viewportWidth - popoverWidth - 20));
    
    // Apply the position
    popoverRef.current.style.top = `${top}px`;
    popoverRef.current.style.left = `${left}px`;
    
  }, []);

  // Handler functions for updating match details
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // If changing match format, initialize proper team structure
    if (name === 'matchFormat') {
      const isDoubles = value === 'doubles' || value === 'mixed';
      
      setMatchDetails(prev => {
        // Initialize proper team structure based on format
        let teamA = [...(prev.teamA || [])];
        let teamB = [...(prev.teamB || [])];
        
        // Ensure we have proper number of players in each team
        if (isDoubles) {
          // Doubles: ensure each team has 2 players
          if (teamA.length < 2) teamA = [...teamA, ...Array(2 - teamA.length).fill('')];
          if (teamB.length < 2) teamB = [...teamB, ...Array(2 - teamB.length).fill('')];
        } else {
          // Singles: ensure each team has 1 player
          teamA = teamA.slice(0, 1);
          if (teamA.length === 0) teamA = [''];
          
          teamB = teamB.slice(0, 1);
          if (teamB.length === 0) teamB = [''];
        }
        
        return {
          ...prev,
          [name]: value,
          teamA,
          teamB
        };
      });
    } else {
      setMatchDetails(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };
  
  // Handle team player changes
  const handleTeamPlayerChange = (team, index, value) => {
    setMatchDetails(prev => {
      const teamArray = [...(prev[team] || [])];
      teamArray[index] = value;
      
      // Also update the players object for backward compatibility
      let players = {...(prev.players || {})};
      
      // If it's TeamA[0], it's equivalent to player1
      if (team === 'teamA' && index === 0) {
        players = {
          ...players,
          player1: {
            ...(players.player1 || {}),
            name: value,
            id: players.player1?.id || `player-${Date.now()}-1`
          }
        };
      }
      
      // If it's TeamB[0], it's equivalent to player2
      if (team === 'teamB' && index === 0) {
        players = {
          ...players,
          player2: {
            ...(players.player2 || {}),
            name: value,
            id: players.player2?.id || `player-${Date.now()}-2`
          }
        };
      }
      
      return {
        ...prev,
        [team]: teamArray,
        players
      };
    });
  };
  
  // Handle player field changes (for backward compatibility)
  const handlePlayerChange = (playerKey, field, value) => {
    setMatchDetails(prev => {
      // Create updated player object
      const updatedPlayer = {
        ...(prev[playerKey] || {}),
        [field]: value,
        // If we don't have an ID yet, generate one
        id: prev[playerKey]?.id || `player-${Date.now()}-${playerKey === 'player1' ? '1' : '2'}`
      };
      
      // For backwards compatibility, also update the players string
      const player1Name = playerKey === 'player1' && field === 'name' ? 
        value : (prev.player1?.name || 'Player 1');
      const player2Name = playerKey === 'player2' && field === 'name' ? 
        value : (prev.player2?.name || 'Player 2');
      
      // Also update teamA/teamB arrays
      let teamA = [...(prev.teamA || [])];
      let teamB = [...(prev.teamB || [])];
      
      if (playerKey === 'player1') {
        if (field === 'name') {
          if (teamA.length > 0) teamA[0] = value;
          else teamA = [value];
        }
      } else if (playerKey === 'player2') {
        if (field === 'name') {
          if (teamB.length > 0) teamB[0] = value;
          else teamB = [value];
        }
      }
      
      return {
        ...prev,
        [playerKey]: updatedPlayer,
        players: `${player1Name} vs ${player2Name}`,
        teamA,
        teamB
      };
    });
  };
  
  const handleOfficialChange = (index, field, value) => {
    setMatchDetails(prev => {
      const officials = [...(prev.officials || [])];
      if (!officials[index]) {
        officials[index] = { ID: `official-${Date.now()}-${index}`, Role: '' };
      }
      officials[index][field] = value;
      return { ...prev, officials };
    });
  };
  
  const handleScoreChange = (set, player, value) => {
    setMatchDetails(prev => {
      const scores = {...(prev.scores || {})};
      
      if (!scores[`set${set}`]) {
        scores[`set${set}`] = { player1: '0', player2: '0' };
      }
      
      scores[`set${set}`][player] = value;
      
      return {
        ...prev,
        scores
      };
    });
  };
  
  // Set winner based on selected team
  const handleWinnerChange = (team) => {
    if (team === 'teamA') {
      setMatchDetails(prev => ({
        ...prev,
        winnerId: 'teamA',
        loserId: 'teamB'
      }));
    } else if (team === 'teamB') {
      setMatchDetails(prev => ({
        ...prev,
        winnerId: 'teamB',
        loserId: 'teamA'
      }));
    } else {
      setMatchDetails(prev => ({
        ...prev,
        winnerId: '',
        loserId: ''
      }));
    }
  };
  
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    onUpdate(matchDetails);
  };
  
  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose();
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);
  
  // Make popover draggable
  useEffect(() => {
    const popoverElement = popoverRef.current;
    const headerElement = popoverElement?.querySelector('.popover-header');
    
    if (!popoverElement || !headerElement) return;
    
    let isDragging = false;
    let offsetX = 0;
    let offsetY = 0;
    
    const handleMouseDown = (e) => {
      isDragging = true;
      offsetX = e.clientX - popoverElement.getBoundingClientRect().left;
      offsetY = e.clientY - popoverElement.getBoundingClientRect().top;
      
      // Add dragging class for styling
      popoverElement.classList.add('dragging');
    };
    
    const handleMouseMove = (e) => {
      if (!isDragging) return;
      
      const newX = e.clientX - offsetX;
      const newY = e.clientY - offsetY;
      
      popoverElement.style.left = `${newX}px`;
      popoverElement.style.top = `${newY}px`;
    };
    
    const handleMouseUp = () => {
      isDragging = false;
      popoverElement.classList.remove('dragging');
    };
    
    headerElement.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    
    return () => {
      headerElement.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);
  
  // Match status options
  const matchStatusOptions = [
    'scheduled',
    'in_progress',
    'completed',
    'postponed',
    'cancelled',
    'walkover'
  ];
  
  // Check if doubles match
  const isDoubles = matchDetails.matchFormat === 'doubles' || matchDetails.matchFormat === 'mixed';
  
  // Navigation sections
  const sections = [
    { id: 'basic', label: 'Basic Info' },
    { id: 'scheduling', label: 'Scheduling' },
    { id: 'teams', label: 'Teams' },
    { id: 'officials', label: 'Officials' },
    { id: 'scores', label: 'Scores & Results' }
  ];
  
  // Render the active section content
  const renderSectionContent = () => {
    switch(activeSection) {
      case 'basic':
        return (
          <>
            <h3 className="section-title">Basic Match Information</h3>
            <div className="form-grid-2">
              <div className="form-group">
                <label>Match #</label>
                <input 
                  name="matchNumber" 
                  type="text"
                  value={matchDetails.matchNumber || ''} 
                  onChange={handleChange}
                  placeholder="Match number"
                />
              </div>
              
              <div className="form-group">
                <label>Round</label>
                <input 
                  name="round" 
                  type="text"
                  value={matchDetails.round || ''} 
                  onChange={handleChange}
                  placeholder="Round number or name"
                />
              </div>
              
              <div className="form-group">
                <label>Stage</label>
                <input 
                  name="stage" 
                  type="text"
                  value={matchDetails.stage || ''} 
                  onChange={handleChange}
                  placeholder="e.g. Semi-final"
                />
              </div>
              
              <div className="form-group">
                <label>Format</label>
                <select 
                  name="matchFormat" 
                  value={matchDetails.matchFormat || 'singles'} 
                  onChange={handleChange}
                >
                  <option value="singles">Singles</option>
                  <option value="doubles">Doubles</option>
                  <option value="mixed">Mixed Doubles</option>
                </select>
              </div>
              
              <div className="form-group">
                <label>Division</label>
                <input 
                  name="division" 
                  type="text"
                  value={matchDetails.division || ''} 
                  onChange={handleChange}
                  placeholder="Division name"
                />
              </div>
              
              <div className="form-group">
                <label>Age Category</label>
                <input 
                  name="ageCategory" 
                  type="text"
                  value={matchDetails.ageCategory || ''} 
                  onChange={handleChange}
                  placeholder="e.g. U18, Senior"
                />
              </div>
              
              <div className="form-group">
                <label>Skill Category</label>
                <input 
                  name="skillCategory" 
                  type="text"
                  value={matchDetails.skillCategory || ''} 
                  onChange={handleChange}
                  placeholder="e.g. Beginner, Pro"
                />
              </div>
              
              <div className="form-group">
                <label>Status</label>
                <select 
                  name="matchStatus" 
                  value={matchDetails.matchStatus || 'scheduled'} 
                  onChange={handleChange}
                >
                  {matchStatusOptions.map(status => (
                    <option key={status} value={status}>
                      {status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        );
        
      case 'scheduling':
        return (
          <>
            <h3 className="section-title">Scheduling</h3>
            <div className="form-grid-2">
              <div className="form-group">
                <label>Court</label>
                <select 
                  name="court" 
                  value={matchDetails.court || ''} 
                  onChange={handleChange}
                >
                  <option value="">Select Court</option>
                  {courts.map(court => (
                    <option key={court} value={court}>Court {court}</option>
                  ))}
                </select>
              </div>
              
              <div className="form-group">
                <label>Venue</label>
                <input 
                  name="venue" 
                  type="text"
                  value={matchDetails.venue || ''} 
                  onChange={handleChange}
                />
              </div>
              
              <div className="form-group">
                <label>Time Slot</label>
                <select 
                  name="timeSlot" 
                  value={matchDetails.timeSlot || ''} 
                  onChange={handleChange}
                >
                  <option value="">Select Time</option>
                  {timeSlots.map((slot, index) => (
                    <option key={index} value={index}>{slot}</option>
                  ))}
                </select>
              </div>
              
              <div className="form-group">
                <label>Scheduled</label>
                <input 
                  name="scheduledTime" 
                  type="datetime-local"
                  value={matchDetails.scheduledTime || ''} 
                  onChange={handleChange}
                />
              </div>
              
              <div className="form-group">
                <label>Started</label>
                <input 
                  name="actualStartTime" 
                  type="datetime-local"
                  value={matchDetails.actualStartTime || ''} 
                  onChange={handleChange}
                />
              </div>
              
              <div className="form-group">
                <label>Ended</label>
                <input 
                  name="actualEndTime" 
                  type="datetime-local"
                  value={matchDetails.actualEndTime || ''} 
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        );
        
      case 'teams':
        return (
          <>
            <h3 className="section-title">Teams</h3>
            <h4>{isDoubles ? 'Doubles Teams' : 'Singles Players'}</h4>
            <div className="teams-container">
              <div className="team-column">
                <h5 className="team-header">Team A</h5>
                <div className="form-grid-1">
                  <div className="form-group">
                    <label>Player 1</label>
                    <input 
                      type="text" 
                      value={(matchDetails.teamA && matchDetails.teamA[0]) || ''}
                      onChange={(e) => handleTeamPlayerChange('teamA', 0, e.target.value)}
                      placeholder="Enter name" 
                    />
                  </div>
                  
                  {isDoubles && (
                    <div className="form-group">
                      <label>Player 2</label>
                      <input 
                        type="text" 
                        value={(matchDetails.teamA && matchDetails.teamA[1]) || ''}
                        onChange={(e) => handleTeamPlayerChange('teamA', 1, e.target.value)}
                        placeholder="Enter name" 
                      />
                    </div>
                  )}
                  
                  {/* For compatibility with existing player structure */}
                  {matchDetails.player1 && (
                    <div className="player-details">
                      <h6>Player Details</h6>
                      <div className="form-group">
                        <label>Rating</label>
                        <input 
                          type="text" 
                          value={matchDetails.player1.rating || ''} 
                          onChange={(e) => handlePlayerChange('player1', 'rating', e.target.value)} 
                        />
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <input 
                          type="email" 
                          value={matchDetails.player1.email || ''} 
                          onChange={(e) => handlePlayerChange('player1', 'email', e.target.value)} 
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              <div className="team-column">
                <h5 className="team-header">Team B</h5>
                <div className="form-grid-1">
                  <div className="form-group">
                    <label>Player 1</label>
                    <input 
                      type="text" 
                      value={(matchDetails.teamB && matchDetails.teamB[0]) || ''}
                      onChange={(e) => handleTeamPlayerChange('teamB', 0, e.target.value)}
                      placeholder="Enter name" 
                    />
                  </div>
                  
                  {isDoubles && (
                    <div className="form-group">
                      <label>Player 2</label>
                      <input 
                        type="text" 
                        value={(matchDetails.teamB && matchDetails.teamB[1]) || ''}
                        onChange={(e) => handleTeamPlayerChange('teamB', 1, e.target.value)}
                        placeholder="Enter name" 
                      />
                    </div>
                  )}
                  
                  {/* For compatibility with existing player structure */}
                  {matchDetails.player2 && (
                    <div className="player-details">
                      <h6>Player Details</h6>
                      <div className="form-group">
                        <label>Rating</label>
                        <input 
                          type="text" 
                          value={matchDetails.player2.rating || ''} 
                          onChange={(e) => handlePlayerChange('player2', 'rating', e.target.value)} 
                        />
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <input 
                          type="email" 
                          value={matchDetails.player2.email || ''} 
                          onChange={(e) => handlePlayerChange('player2', 'email', e.target.value)} 
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        );
        
      case 'officials':
        return (
          <>
            <h3 className="section-title">Officials</h3>
            <div className="form-grid-1">
              <div className="form-group">
                <label>Chair Umpire</label>
                <input 
                  type="text" 
                  value={(matchDetails.officials?.find(o => o.Role === 'Chair Umpire')?.FirstName) || ''} 
                  onChange={(e) => handleOfficialChange(0, 'FirstName', e.target.value)} 
                />
              </div>
              
              <div className="form-group">
                <label>Line Judge</label>
                <input 
                  type="text" 
                  value={(matchDetails.officials?.find(o => o.Role === 'Line Judge')?.FirstName) || ''} 
                  onChange={(e) => {
                    const official = { 
                      FirstName: e.target.value,
                      LastName: '',
                      Role: 'Line Judge'
                    };
                    setMatchDetails(prev => ({
                      ...prev,
                      officials: [
                        ...(prev.officials?.filter(o => o.Role !== 'Line Judge') || []),
                        official
                      ]
                    }));
                  }} 
                />
              </div>
            </div>
          </>
        );
        
      case 'scores':
        return (
          <>
            <h3 className="section-title">Scores & Results</h3>
            
            <div className="scores-grid">
              <div className="score-header">Sets</div>
              <div className="score-header">
                Team A {isDoubles ? (
                  <span className="team-players">
                    ({matchDetails.teamA?.join(' / ') || 'Team A'})
                  </span>
                ) : ''}
              </div>
              <div className="score-header">
                Team B {isDoubles ? (
                  <span className="team-players">
                    ({matchDetails.teamB?.join(' / ') || 'Team B'})
                  </span>
                ) : ''}
              </div>
              
              {[1, 2, 3].map(set => (
                <React.Fragment key={`set-${set}`}>
                  <div className="set-label">Set {set}</div>
                  <div>
                    <input 
                      type="text" 
                      value={matchDetails.scores?.[`set${set}`]?.player1 || ''}
                      onChange={(e) => handleScoreChange(set, 'player1', e.target.value)}
                      className="score-input"
                      maxLength={2}
                    />
                  </div>
                  <div>
                    <input 
                      type="text" 
                      value={matchDetails.scores?.[`set${set}`]?.player2 || ''}
                      onChange={(e) => handleScoreChange(set, 'player2', e.target.value)}
                      className="score-input"
                      maxLength={2}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
            
            <div className="winner-selection">
              <p className="winner-label">Match Winner:</p>
              <div className="radio-group">
                <label className="radio-label">
                  <input 
                    type="radio" 
                    name="winner" 
                    checked={matchDetails.winnerId === 'teamA'}
                    onChange={() => handleWinnerChange('teamA')}
                  />
                  Team A {isDoubles ? `(${matchDetails.teamA?.join(' / ')})` : ''}
                </label>
                <label className="radio-label">
                  <input 
                    type="radio" 
                    name="winner" 
                    checked={matchDetails.winnerId === 'teamB'}
                    onChange={() => handleWinnerChange('teamB')}
                  />
                  Team B {isDoubles ? `(${matchDetails.teamB?.join(' / ')})` : ''}
                </label>
                <label className="radio-label">
                  <input 
                    type="radio" 
                    name="winner" 
                    checked={!matchDetails.winnerId}
                    onChange={() => handleWinnerChange(null)}
                  />
                  No winner yet
                </label>
              </div>
            </div>
          </>
        );
        
      default:
        return <div>Select a section</div>;
    }
  };
  
  return (
    <div className="match-details-popover" ref={popoverRef}>
      <div className="popover-header">
        <h3>Edit Match Details</h3>
        <button className="close-button" onClick={onClose}>×</button>
      </div>
      
      <div className="popover-content">
        <div className="left-sidebar">
          <ul className="section-nav">
            {sections.map(section => (
              <li 
                key={section.id}
                className={activeSection === section.id ? 'active' : ''}
                onClick={() => setActiveSection(section.id)}
              >
                {section.label}
              </li>
            ))}
          </ul>
          
          <div className="action-buttons">
            <button 
              type="button" 
              className="cancel-button"
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
              type="button"
              className="save-button"
              onClick={handleSubmit}
            >
              Save Changes
            </button>
          </div>
        </div>
        
        <div className="right-content">
          {renderSectionContent()}
        </div>
      </div>
    </div>
  );
};

export default MatchDetailsPopover;