import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const Home = () => {
  const [allTournaments, setAllTournaments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  // Categorized tournaments
  const [usapTournaments, setUsapTournaments] = useState([]);
  const [appTournaments, setAppTournaments] = useState([]);
  const [sanctionedTournaments, setSanctionedTournaments] = useState([]);
  const [otherTournaments, setOtherTournaments] = useState([]);
  
  // Banner rotation state
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const bannerImages = [
    '/images/banner-ads/0.jpg',
    '/images/banner-ads/1.jpg',
    '/images/banner-ads/2.jpg',
    '/images/banner-ads/3.jpg'
  ];
  
  const navigate = useNavigate();
  
  // Define navigation functions
  const navigateToTournament = (tournamentId) => {
    navigate(`/tournaments/${tournamentId}`);
  };
  
  const navigateToSignIn = () => {
    navigate('/login');
  };

  // Helper function to scroll tournament containers horizontally
  const scrollContainer = (containerId, direction) => {
    const container = document.getElementById(containerId);
    if (container) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  // Effect to rotate banner images
  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setCurrentBannerIndex(prevIndex => (prevIndex + 1) % bannerImages.length);
    }, 5000); // Change image every 5 seconds
    
    return () => clearInterval(rotationInterval); // Clean up on unmount
  }, [bannerImages.length]);

  useEffect(() => {
    // Check if user is authenticated
    const jwt = localStorage.getItem('jwt');
    setIsAuthenticated(!!jwt);
    
    const fetchTournaments = async () => {
      setIsLoading(true);
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
        
        // Prepare headers with JWT if available
        const headers = {};
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
          headers['X-JWT'] = jwt;
        }
        
        console.log('Fetching tournaments with headers:', headers);
        
        const response = await fetch(`${backendUrl}/api/public/tournaments`, {
          method: 'GET',
          headers: headers,
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error(`Failed to fetch tournaments: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Fetched tournaments:', data);
        
        // Store all tournaments
        setAllTournaments(data);
        
        // Categorize tournaments
        categorizeTournaments(data);
        
      } catch (err) {
        console.error('Error fetching tournaments:', err);
        setError('Failed to load tournaments. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTournaments();
  }, []);
  
  // Function to categorize tournaments based on criteria
  const categorizeTournaments = (tournaments) => {
    if (!Array.isArray(tournaments)) {
      console.error('Expected tournaments array, got:', tournaments);
      setError('Invalid tournament data received');
      return;
    }
    
    // Removed goldenTicket array
    const usap = [];
    const app = [];
    const sanctioned = [];
    const other = [];
    const categorizedIds = new Set(); // Track IDs that have been categorized
    
    tournaments.forEach(tournament => {
      const id = tournament.TID || tournament.id;
      let isCategorized = false;
      
      // Check for flat or nested operatorDetails
      const runningOrg = tournament.operatorDetails?.runningOrganization || tournament.runningOrganization || '';
      const tournamentType = tournament.operatorDetails?.tournamentType || tournament.tournamentType || '';
      // Removed isGoldenTicket check
      
      // Case-insensitive string comparison
      const runningOrgLower = runningOrg.toLowerCase();
      const tournamentTypeLower = tournamentType.toLowerCase();
      
      // Removed Golden Ticket section
      
      // USA Pickleball Tournaments
      if (runningOrgLower.includes('usa pickleball') || runningOrgLower.includes('usap')) {
        usap.push(tournament);
        isCategorized = true;
      }
      
      // APP Tournaments
      if (runningOrgLower.includes('app') || 
          runningOrgLower.includes('association of pickleball players')) {
        app.push(tournament);
        isCategorized = true;
      }
      
      // Sanctioned Tournaments (regardless of organizer)
      if (tournamentTypeLower.includes('usap sanctioned') || 
          tournamentTypeLower.includes('sanctioned tournament')) {
        sanctioned.push(tournament);
        isCategorized = true;
      }
      
      // Other Tournaments - only if not placed in any other category
      if (!isCategorized) {
        other.push(tournament);
      }
      
      if (isCategorized) {
        categorizedIds.add(id);
      }
    });
    
    // Removed setGoldenTicketTournaments
    setUsapTournaments(usap);
    setAppTournaments(app);
    setSanctionedTournaments(sanctioned);
    setOtherTournaments(other);
    
    console.log('Categorized tournaments:', {
      // Removed goldenTicket log
      usap: usap.length,
      app: app.length,
      sanctioned: sanctioned.length,
      other: other.length,
      uniqueTournaments: categorizedIds.size,
      totalTournaments: tournaments.length
    });
    
    // Log tournaments that appear in multiple categories
    const multiCategoryTournaments = [...categorizedIds].filter(id => {
      let count = 0;
      // Removed goldenTicket check
      if (usap.some(t => (t.TID || t.id) === id)) count++;
      if (app.some(t => (t.TID || t.id) === id)) count++;
      if (sanctioned.some(t => (t.TID || t.id) === id)) count++;
      return count > 1;
    });
    
    if (multiCategoryTournaments.length > 0) {
      console.log(`${multiCategoryTournaments.length} tournaments appear in multiple categories:`, 
        multiCategoryTournaments);
    }
  };

  const renderTournamentCard = (tournament) => {
    // Get location information
    let location = 'Location not available';
    if (tournament.venues && tournament.venues.length > 0) {
      const venue = tournament.venues[0];
      if (venue.address) {
        location = `${venue.address.city || ''}, ${venue.address.state || ''}`;
        if (!location.trim()) {
          location = 'Location not available';
        }
      }
    }
    
    const logo = Array.isArray(tournament.logos) && tournament.logos.length > 0
      ? tournament.logos[0].galleryUrl || tournament.logos[0].originalUrl || tournament.logos[0]
      : tournament.logo || '';
    
    // Fallback image for tournaments without logos
    const fallbackLogo = '/images/default-tournament-logo.png';
    
    return (
      <div 
        className="tournament-card" 
        key={tournament.TID || tournament.id}
        onClick={() => navigateToTournament(tournament.TID || tournament.id)}
      >
        <div className="card-logo-container">
          <img 
            src={logo || fallbackLogo} 
            alt={`${tournament.name} logo`}
            className="tournament-logo"
            onError={(e) => {
              e.target.onerror = null; 
              e.target.src = fallbackLogo;
            }}
          />
        </div>
        
        <div className="card-header">
          <h3>{tournament.name}</h3>
          {tournament.operatorDetails?.runningOrganization || tournament.runningOrganization ? (
            <span className="organization-badge">
              {tournament.operatorDetails?.runningOrganization || tournament.runningOrganization}
            </span>
          ) : null}
        </div>
        
        <div className="card-dates">
          <span className="date-range">
            {tournament.importantDates?.startDate ? new Date(tournament.importantDates.startDate).toLocaleDateString() : 'TBD'}
            {tournament.importantDates?.endDate ? ` - ${new Date(tournament.importantDates.endDate).toLocaleDateString()}` : ''}
          </span>
        </div>
        
        <div className="card-location">
          <i className="location-icon">📍</i> {location}
        </div>
        
        {tournament.operatorDetails?.tournamentType || tournament.tournamentType ? (
          <div className="card-type">
            {tournament.operatorDetails?.tournamentType || tournament.tournamentType}
          </div>
        ) : null}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading tournaments...</p>
      </div>
    );
  }

  return (
    <div className="home-container">
      <div className="home-header">
        <div className="header-actions">
          {!isAuthenticated && (
            <button className="sign-in-button" onClick={navigateToSignIn}>
              Sign In
            </button>
          )}
        </div>
      </div>
      
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
      
      {/* Rotating Banner Ads */}
      <div className="ad-banner">
        <div className="ad-content">
          <img 
            src={bannerImages[currentBannerIndex]} 
            alt={`Promotional Banner ${currentBannerIndex + 1}`} 
            className="ad-banner-image"
            style={{ transition: 'opacity 0.5s ease-in-out' }}
          />
        </div>
      </div>
      
      {/* USA Pickleball Tournaments Section - Scrollable */}
      {usapTournaments.length > 0 && (
        <section className="home-section usap-section">
          <div className="section-header">
            <h2>USA Pickleball</h2>
          </div>
          <div className="scrollable-container-wrapper">
            <button 
              className="scroll-arrow left-arrow" 
              onClick={() => scrollContainer('usap-container', 'left')}
              aria-label="Scroll left"
            >
              &lt;
            </button>
            <div id="usap-container" className="scrollable-card-grid">
              {usapTournaments.map(tournament => renderTournamentCard(tournament))}
            </div>
            <button 
              className="scroll-arrow right-arrow" 
              onClick={() => scrollContainer('usap-container', 'right')}
              aria-label="Scroll right"
            >
              &gt;
            </button>
          </div>
        </section>
      )}

      {/* APP Tournaments Section - Scrollable */}
      {appTournaments.length > 0 && (
        <section className="home-section app-section">
          <div className="section-header">
            <h2>APP Tour</h2>
          </div>
          <div className="scrollable-container-wrapper">
            <button 
              className="scroll-arrow left-arrow" 
              onClick={() => scrollContainer('app-container', 'left')}
              aria-label="Scroll left"
            >
              &lt;
            </button>
            <div id="app-container" className="scrollable-card-grid">
              {appTournaments.map(tournament => renderTournamentCard(tournament))}
            </div>
            <button 
              className="scroll-arrow right-arrow" 
              onClick={() => scrollContainer('app-container', 'right')}
              aria-label="Scroll right"
            >
              &gt;
            </button>
          </div>
        </section>
      )}

      {/* Sanctioned Tournaments Section - Scrollable */}
      {sanctionedTournaments.length > 0 && (
        <section className="home-section">
          <div className="section-header">
            <h2>Sanctioned Tournaments</h2>
          </div>
          <div className="scrollable-container-wrapper">
            <button 
              className="scroll-arrow left-arrow" 
              onClick={() => scrollContainer('sanctioned-container', 'left')}
              aria-label="Scroll left"
            >
              &lt;
            </button>
            <div id="sanctioned-container" className="scrollable-card-grid">
              {sanctionedTournaments.map(tournament => renderTournamentCard(tournament))}
            </div>
            <button 
              className="scroll-arrow right-arrow" 
              onClick={() => scrollContainer('sanctioned-container', 'right')}
              aria-label="Scroll right"
            >
              &gt;
            </button>
          </div>
        </section>
      )}

      {/* Other Tournaments Section - Scrollable */}
      {otherTournaments.length > 0 && (
        <section className="home-section">
          <div className="section-header">
            <h2>Tournaments</h2>
          </div>
          <div className="scrollable-container-wrapper">
            <button 
              className="scroll-arrow left-arrow" 
              onClick={() => scrollContainer('other-container', 'left')}
              aria-label="Scroll left"
            >
              &lt;
            </button>
            <div id="other-container" className="scrollable-card-grid">
              {otherTournaments.map(tournament => renderTournamentCard(tournament))}
            </div>
            <button 
              className="scroll-arrow right-arrow" 
              onClick={() => scrollContainer('other-container', 'right')}
              aria-label="Scroll right"
            >
              &gt;
            </button>
          </div>
        </section>
      )}

      {/* Show empty state if no tournaments at all */}
      {allTournaments.length === 0 && (
        <div className="empty-state">
          <h2>No tournaments available</h2>
          <p>Check back soon for upcoming tournaments!</p>
        </div>
      )}
    </div>
  );
};

export default Home;
