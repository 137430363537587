import React from 'react';

const AgeGroupSelect = ({ value, onChange, options }) => {
  return (
    <div className="form-group">
      <label htmlFor="age">Age</label>
      {options.length > 0 ? (
        <select
          id="age"
          name="age"
          value={value}
          onChange={onChange}
        >
          <option value="">Select Age Group</option>
          {options.map(age => (
            <option key={age} value={age}>{age}</option>
          ))}
        </select>
      ) : (
        <input 
          type="text" 
          id="age" 
          name="age" 
          value={value} 
          onChange={onChange}
          placeholder="Enter age (e.g., 18+ or 50+)"
        />
      )}
    </div>
  );
};

export default AgeGroupSelect;