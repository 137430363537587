import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './TournamentList.css';

const TournamentList = () => {
    const [tournaments, setTournaments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTournaments = async () => {
            try {
                setLoading(true);
                const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
                const response = await fetch(`${backendUrl}/api/tournaments`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        ...(localStorage.getItem('jwt') && { 'X-JWT': localStorage.getItem('jwt') })
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch tournaments: ${response.status}`);
                }

                const data = await response.json();
                setTournaments(data);
            } catch (err) {
                setError(err.message);
                console.error('Error fetching tournaments:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTournaments();
    }, []);

    // Update this function
    const handleTournamentClick = (tournament) => {
        // If user can edit this tournament, go to edit page
        if (canEditTournament(tournament)) {
            navigate(`/edittournament/${tournament.TID}`);
        } else {
            // For non-editable tournaments, you could redirect to a view-only page
            // navigate(`/tournament/${tournament.id}`);

            // For now just alert
            alert(`Viewing tournament: ${tournament.name}`);
        }
    };

    // Helper function to determine if user can edit a tournament
    const canEditTournament = (tournament) => {
        return isLoggedIn;
    };

    if (loading) {
        return <div className="tournament-list-container loading">Loading tournaments...</div>;
    }

    if (error) {
        return <div className="tournament-list-container error">Error: {error}</div>;
    }

    if (tournaments.length === 0) {
        return (
            <div className="tournament-list-container empty">
                <h2>No tournaments found</h2>
                {isLoggedIn && (
                    <Link to="/createtournament" className="create-tournament-button">
                        Create a Tournament
                    </Link>
                )}
            </div>
        );
    }

    return (
        <div className="tournament-list-container">
            <h2>Tournaments</h2>
            {isLoggedIn && (
                <Link to="/createtournament" className="create-tournament-button">
                    Create a Tournament
                </Link>
            )}
            <div className="tournament-grid">
                {tournaments.map((tournament, index) => (
                    <div
                        key={tournament.TID || tournament.id || `tournament-${index}`}
                        className="tournament-card"
                        onClick={() => handleTournamentClick(tournament)}
                    >
                        <div className="tournament-logo">
                            {tournament.logos && tournament.logos.length > 0 ? (
                                <img
                                    src={tournament.logos[0].thumbnailUrl || tournament.logos[0].galleryUrl || tournament.logos[0].originalUrl}
                                    alt={tournament.logos[0].description || tournament.name}
                                />
                            ) : (
                                <div className="placeholder-logo">🏆</div>
                            )}
                        </div>
                        <div className="tournament-details">
                            <h3>{tournament.name}</h3>
                            <p className="tournament-status">Status: {tournament.status}</p>
                            <p className="tournament-dates">
                                {tournament.importantDates?.startDate && (
                                    <>
                                        {new Date(tournament.importantDates.startDate).toLocaleDateString()} - {' '}
                                        {tournament.importantDates?.endDate ?
                                            new Date(tournament.importantDates.endDate).toLocaleDateString() : 'TBD'}
                                    </>
                                )}
                            </p>
                            <div className="tournament-description">
                                {tournament.description?.substring(0, 100)}
                                {tournament.description?.length > 100 ? '...' : ''}
                            </div>
                        </div>
                        <div className="tournament-actions" onClick={e => e.stopPropagation()}>
                            <button className="view-button" onClick={() => handleTournamentClick(tournament)}>
                                View Details
                            </button>
                            {isLoggedIn && (
                                <button 
                                    className="schedule-button" 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/schedule-tournament/${tournament.TID}`);
                                    }}
                                >
                                    Schedule
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TournamentList;