import React from 'react';
import { getGenderText, cleanLabel } from '../utils/formatUtils';

const DivisionSelect = ({ selected, options, onChange, genderFormats }) => {
  return (
    <div className="form-group">
      <label htmlFor="division">Division</label>
      <div className="custom-multi-select">
        <div className="selected-items">
          {selected.length === 0 && (
            <div className="placeholder">Select Divisions</div>
          )}
          {selected.map(divValue => {
            const division = options.find(d => d.value === divValue);
            const label = division ? (division.label || division.value) : divValue;
            
            return (
              <div key={divValue} className="selected-tag">
                <span>{cleanLabel(label)}</span>
                <button 
                  type="button" 
                  className="remove-tag" 
                  onClick={() => onChange(divValue, true)}
                >
                  ×
                </button>
              </div>
            );
          })}
        </div>
        <div className="dropdown">
          {options
            .filter(division => {
              // Only filter if match formats are selected
              if (genderFormats.length === 0) return true;
              
              const divisionText = (division.label || division.value || '').toLowerCase();
              
              // Enhanced matching for all formats - show division if it matches ANY selected format
              return genderFormats.some(gender => {
                const genderText = getGenderText(gender);
                
                // Try generic matching first
                if (divisionText.includes(genderText)) {
                  return true;
                }
                
                // Singles matching
                if (genderText.includes('singles') || genderText.includes('single')) {
                  const isMensFormat = genderText.includes('men') && !genderText.includes('women');
                  const isWomensFormat = genderText.includes('women');
                  
                  // Check for men's singles
                  if (isMensFormat && 
                      (divisionText.includes('men') || divisionText.includes("men's")) && 
                      (divisionText.includes('single') || divisionText.includes('sgl') || 
                       (!divisionText.includes('double') && !divisionText.includes('dbl')))) {
                    return true;
                  }
                  
                  // Check for women's singles
                  if (isWomensFormat && 
                      (divisionText.includes('women') || divisionText.includes("women's")) && 
                      (divisionText.includes('single') || divisionText.includes('sgl') || 
                       (!divisionText.includes('double') && !divisionText.includes('dbl')))) {
                    return true;
                  }
                  
                  // Generic singles
                  if (!isMensFormat && !isWomensFormat && 
                      (divisionText.includes('single') || divisionText.includes('sgl'))) {
                    return true;
                  }
                }
                
                // Doubles matching
                if (genderText.includes('doubles') || genderText.includes('double')) {
                  const isMensFormat = genderText.includes('men') && !genderText.includes('mixed');
                  const isWomensFormat = genderText.includes('women') && !genderText.includes('mixed');
                  const isMixedFormat = genderText.includes('mixed');
                  
                  // Men's doubles
                  if (isMensFormat && 
                      (divisionText.includes('men') || divisionText.includes("men's")) && 
                      !divisionText.includes('women') && !divisionText.includes('mixed') &&
                      (divisionText.includes('double') || divisionText.includes('dbl'))) {
                    return true;
                  }
                  
                  // Women's doubles
                  if (isWomensFormat && 
                      (divisionText.includes('women') || divisionText.includes("women's")) && 
                      !divisionText.includes('mixed') &&
                      (divisionText.includes('double') || divisionText.includes('dbl'))) {
                    return true;
                  }
                  
                  // Mixed doubles
                  if (isMixedFormat && 
                      divisionText.includes('mixed') && 
                      (divisionText.includes('double') || divisionText.includes('dbl'))) {
                    return true;
                  }
                  
                  // Generic doubles
                  if (!isMensFormat && !isWomensFormat && !isMixedFormat && 
                      (divisionText.includes('double') || divisionText.includes('dbl'))) {
                    return true;
                  }
                }
                
                return false;
              });
            })
            .map(division => {
              const isSelected = selected.includes(division.value);
              const cleanedLabel = cleanLabel(division.label || '');
              
              return (
                <div 
                  key={division.value} 
                  className={`option ${isSelected ? 'selected' : ''} ${division.isSuggested ? 'suggested' : ''}`}
                  onClick={() => onChange(division.value, isSelected)}
                >
                  <input 
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => {}} // Handled by onClick
                  />
                  <span>{cleanedLabel}</span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DivisionSelect;