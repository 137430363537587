import React from 'react';
import './CommunicationSettings.css';

const CommunicationSettings = ({ value = {}, onChange, field }) => {
  // Handle changes for each field
  const handleChange = (fieldName, fieldValue) => {
    onChange({
      ...value,
      [fieldName]: fieldValue
    });
  };

  return (
    <div className="comm-settings-container" data-fieldname="communication-settings">
      <h3 className="comm-settings-title">Communication Settings</h3>
      
      {/* Checkboxes section */}
      <div className="comm-settings-section">
        <div className="comm-checkboxes">
          <label className="comm-checkbox">
            <input 
              type="checkbox" 
              checked={value.isTextingEnabled || false}
              onChange={(e) => handleChange('isTextingEnabled', e.target.checked)}
            />
            Enable Texting
          </label>
          
          <label className="comm-checkbox">
            <input 
              type="checkbox" 
              checked={value.isEmailEnabled || false} 
              onChange={(e) => handleChange('isEmailEnabled', e.target.checked)}
            />
            Enable Email
          </label>
          
          <label className="comm-checkbox">
            <input 
              type="checkbox" 
              checked={value.isPrivate || false} 
              onChange={(e) => handleChange('isPrivate', e.target.checked)}
            />
            Private Tournament
          </label>
        </div>
      </div>
      
      {/* Contact & Timezone section */}
      <div className="comm-settings-section">
        <div className="comm-contact-row">
          <div className="comm-field">
            <label>Primary Contact</label>
            <input 
              type="text" 
              value={value.primaryContact || ''} 
              onChange={(e) => handleChange('primaryContact', e.target.value)}
              className="comm-input"
            />
          </div>
          
          <div className="comm-field">
            <label>Timezone</label>
            <input 
              type="text" 
              value={value.timezone || ''} 
              onChange={(e) => handleChange('timezone', e.target.value)}
              className="comm-input"
            />
          </div>
        </div>
      </div>
      
      {/* Additional Contacts section */}
      <div className="comm-settings-section">
        <label>Additional Contacts</label>
        <div className="comm-array">
          {(value.additionalContacts || []).map((contact, index) => (
            <div key={index} className="comm-array-item">
              <input
                type="text"
                value={contact}
                onChange={(e) => {
                  const newContacts = [...(value.additionalContacts || [])];
                  newContacts[index] = e.target.value;
                  handleChange('additionalContacts', newContacts);
                }}
                className="comm-input"
              />
              <button 
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const newContacts = [...(value.additionalContacts || [])];
                  newContacts.splice(index, 1);
                  handleChange('additionalContacts', newContacts);
                }}
                className="comm-remove-button"
              >
                Remove
              </button>
            </div>
          ))}
          <button 
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const newContacts = [...(value.additionalContacts || []), ''];
              handleChange('additionalContacts', newContacts);
            }}
            className="comm-add-button"
          >
            Add Contact
          </button>
        </div>
      </div>
      
      {/* Sponsors section */}
      <div className="comm-settings-section">
        <label>Sponsors</label>
        <div className="comm-array">
          {(value.sponsors || []).map((sponsor, index) => (
            <div key={index} className="comm-array-item">
              <input
                type="text"
                value={sponsor}
                onChange={(e) => {
                  const newSponsors = [...(value.sponsors || [])];
                  newSponsors[index] = e.target.value;
                  handleChange('sponsors', newSponsors);
                }}
                className="comm-input"
              />
              <button 
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const newSponsors = [...(value.sponsors || [])];
                  newSponsors.splice(index, 1);
                  handleChange('sponsors', newSponsors);
                }}
                className="comm-remove-button"
              >
                Remove
              </button>
            </div>
          ))}
          <button 
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const newSponsors = [...(value.sponsors || []), ''];
              handleChange('sponsors', newSponsors);
            }}
            className="comm-add-button"
          >
            Add Sponsor
          </button>
        </div>
      </div>
      
      {field.helpText && (
        <div className="comm-help-text">{field.helpText}</div>
      )}
    </div>
  );
};

export default CommunicationSettings;