import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import './DivisionConfigSelector.css';

const DivisionConfigSelector = ({ value, onChange, field }) => {
    // State for divisions and their configurations
    const [availableDivisions, setAvailableDivisions] = useState([]);
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [divisionsToMerge, setDivisionsToMerge] = useState([]);  // New state for merge selection
    const [divisionConfigs, setDivisionConfigs] = useState({});
    const [mergedDivisions, setMergedDivisions] = useState([]);
    const [selectedMergedDivision, setSelectedMergedDivision] = useState(null); // Track selected merged division
    
    // Initialize component with any existing data
    useEffect(() => {
        if (value) {
            // If we have division configs from value, set them
            if (value.divisionConfigs) {
                setDivisionConfigs(value.divisionConfigs);
            }
            
            // If we have merged divisions from value, set them
            if (value.mergedDivisions) {
                setMergedDivisions(value.mergedDivisions);
            }
            
            // If we have selected divisions from value, set them
            if (value.selectedDivisions) {
                const formattedSelections = value.selectedDivisions.map(div => {
                    if (typeof div === 'object' && div !== null && !div.hasOwnProperty('value')) {
                        return {
                            value: div.id || JSON.stringify(div),
                            label: div.name || String(div),
                            data: div
                        };
                    }
                    return div;
                });
                setSelectedDivisions(formattedSelections);
            }
        }
        
        // Check different possible locations for the generated divisions
        // Try directly in the value
        if (value && value.generatedDivisions) {
            const divs = value.generatedDivisions.map(div => {
                if (typeof div === 'object' && div !== null) {
                    return { 
                        value: div.id || JSON.stringify(div),
                        label: div.name || String(div),
                        data: div  // Keep original data accessible
                    };
                }
                return { value: div, label: String(div) };
            });
            setAvailableDivisions(divs);
        }
        // Try in the divisions property
        else if (value && value.divisions && value.divisions.generatedDivisions) {
            const divs = value.divisions.generatedDivisions.map(div => {
                if (typeof div === 'object' && div !== null) {
                    return { 
                        value: div.id || JSON.stringify(div),
                        label: div.name || String(div),
                        data: div
                    };
                }
                return { value: div, label: String(div) };
            });
            setAvailableDivisions(divs);
        }
       
    }, [value]);
    
    // When division configuration changes, notify parent
    const notifyChanges = useCallback(() => {
        if (onChange) {
            onChange({
                ...(value || {}),
                divisionConfigs,
                selectedDivisions,
                mergedDivisions
            });
        }
    }, [onChange, value, divisionConfigs, selectedDivisions, mergedDivisions]);
    
    // Call notify after state changes have settled
    useEffect(() => {
        const timer = setTimeout(() => {
            notifyChanges();
        }, 0);
        
        return () => clearTimeout(timer);
    }, [notifyChanges]);
    
    // Handle division selection for configuration
    const handleDivisionSelect = (selected) => {
        // Check if any of the new selections are part of merged divisions
        const isValid = selected ? selected.every(div => 
            !mergedDivisions.some(md => md.sourceDivisions.includes(div.value))
        ) : true;
        
        if (!isValid) {
            // If invalid selection, don't update state - you could also show a warning
            console.warn("Cannot select divisions that are part of merged divisions");
            return;
        }
        
        setSelectedDivisions(selected || []);
        
        // Initialize config for newly selected divisions
        const newConfigs = { ...divisionConfigs };
        
        if (selected) {
            selected.forEach(div => {
                if (!newConfigs[div.value]) {
                    newConfigs[div.value] = {
                        format: "round-robin",
                        minPlayers: value?.minimumPlayersPerDivision || 4,
                        maxPlayers: value?.maximumPlayersPerDivision || 16
                    };
                }
            });
        }
        
        setDivisionConfigs(newConfigs);
        
        // When selecting a division, clear any selected merged division
        setSelectedMergedDivision(null);
        
        // Clear any conflicting divisions from the merge selection
        if (selected && selected.length > 0) {
            setDivisionsToMerge(prev => 
                prev.filter(div => !selected.some(s => s.value === div.value))
            );
        }
    };
    
    // Handle selecting a merged division to configure
    const handleSelectMergedDivision = (mergedDivision) => {
        setSelectedMergedDivision(mergedDivision);
        // Clear regular division selection when a merged division is selected
        setSelectedDivisions([]);
    };
    
    // Handle selection of divisions to merge
    const handleMergeSelect = (selected) => {
        // Check if any of the new selections conflict with regular divisions
        const isValid = selected ? selected.every(div => 
            !selectedDivisions.some(sd => sd.value === div.value) &&
            !mergedDivisions.some(md => md.sourceDivisions.includes(div.value))
        ) : true;
        
        if (!isValid) {
            // If invalid selection, don't update state
            console.warn("Cannot select divisions that are already configured");
            return;
        }
        
        setDivisionsToMerge(selected || []);
    };
    
    // Handle tournament format change for a division
    const handleFormatChange = (divisionName, format) => {
        setDivisionConfigs(prev => ({
            ...prev,
            [divisionName]: {
                ...prev[divisionName],
                format
            }
        }));
    };
    
    // Handle min players change
    const handleMinPlayersChange = (divisionName, minPlayers) => {
        setDivisionConfigs(prev => ({
            ...prev,
            [divisionName]: {
                ...prev[divisionName],
                minPlayers: parseInt(minPlayers, 10) || 0
            }
        }));
    };
    
    // Handle max players change
    const handleMaxPlayersChange = (divisionName, maxPlayers) => {
        setDivisionConfigs(prev => ({
            ...prev,
            [divisionName]: {
                ...prev[divisionName],
                maxPlayers: parseInt(maxPlayers, 10) || 0
            }
        }));
    };
    
    // Handle division merge
    const handleMergeDivisions = () => {
        if (divisionsToMerge.length < 2) return;
        
        // Create a new merged division name
        const mergeValues = divisionsToMerge.map(d => d.value);
        const newMergedName = mergeValues.join(' + ');
        
        // Add to merged divisions list
        setMergedDivisions(prev => [
            ...prev,
            {
                name: newMergedName,
                sourceDivisions: mergeValues
            }
        ]);
        
        // Initialize config for the new merged division
        setDivisionConfigs(prev => ({
            ...prev,
            [newMergedName]: {
                format: "round-robin",
                minPlayers: value?.minimumPlayersPerDivision || 4,
                maxPlayers: value?.maximumPlayersPerDivision || 16,
                isMerged: true
            }
        }));
        
        // Clear merge selection
        setDivisionsToMerge([]);
        
        // Auto-select the newly created merged division
        setSelectedMergedDivision({
            name: newMergedName,
            sourceDivisions: mergeValues
        });
        
        // Clear regular division selection
        setSelectedDivisions([]);
    };
    
    // Handle removing a merged division
    const handleRemoveMergedDivision = (mergedDivisionName) => {
        // Remove from merged divisions list
        setMergedDivisions(prev => 
            prev.filter(md => md.name !== mergedDivisionName)
        );
        
        // Remove config for this merged division
        const newConfigs = { ...divisionConfigs };
        delete newConfigs[mergedDivisionName];
        setDivisionConfigs(newConfigs);
        
        // Clear selection if the removed division was selected
        if (selectedMergedDivision && selectedMergedDivision.name === mergedDivisionName) {
            setSelectedMergedDivision(null);
        }
    };
    
    // Format options
    const formatOptions = [
        { value: 'round-robin', label: 'Round Robin' },
        { value: 'single-elimination', label: 'Single Elimination' },
        { value: 'double-elimination', label: 'Double Elimination' }
    ];
    
    // Get merged division options for select dropdown
    const mergedDivisionOptions = mergedDivisions.map(md => ({
        value: md.name,
        label: md.name
    }));

    // Get options for the regular divisions select, excluding divisions that are used in merges
    const regularDivisionOptions = availableDivisions.filter(div => 
        // Exclude divisions that are already part of merged divisions
        !mergedDivisions.some(md => 
            md.sourceDivisions.includes(div.value)
        )
    );

    // Get options for the merge select, excluding divisions that are already being configured
    const mergeableOptions = availableDivisions.filter(div => 
        // Exclude divisions that are already part of merged divisions
        !mergedDivisions.some(md => 
            md.sourceDivisions.includes(div.value)
        ) &&
        // Allow the currently selected divisions for merging
        (divisionsToMerge.some(d => d.value === div.value) || 
        // Exclude divisions that are already selected for configuration
        !selectedDivisions.some(d => d.value === div.value))
    );
    
    return (
        <div className="division-config-selector" data-fieldname="divisionSettings">
            <div className="division-config-layout">
                <div className="division-selection-panel">
                    <h3>Regular Divisions</h3>
                    <p className="help-text">Select divisions to configure their format and settings</p>
                    
                    <Select
                        isMulti
                        options={regularDivisionOptions} // Use filtered options
                        value={selectedDivisions}
                        onChange={handleDivisionSelect}
                        placeholder="Select divisions to configure"
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                    
                    {/* Section for merged division selection */}
                    {mergedDivisions.length > 0 && (
                        <div className="merged-division-selection">
                            <h3>Merged Divisions</h3>
                            <p className="help-text">Select a merged division to configure</p>
                            
                            <Select
                                options={mergedDivisionOptions.map(div => ({
                                    value: typeof div.value === 'object' ? JSON.stringify(div.value) : div.value,
                                    label: typeof div.label === 'object' ? 
                                        (div.label.name || String(div.label)) : 
                                        div.label
                                }))}
                                value={selectedMergedDivision ? 
                                    { 
                                        value: typeof selectedMergedDivision === 'object' ? 
                                            selectedMergedDivision.name : 
                                            selectedMergedDivision,
                                        label: typeof selectedMergedDivision === 'object' ? 
                                            selectedMergedDivision.name : 
                                            selectedMergedDivision
                                    } 
                                    : null
                                }
                                onChange={(selected) => handleSelectMergedDivision(selected ? selected.value : null)}
                                placeholder="Select a merged division"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                isClearable
                            />
                        </div>
                    )}
                    
                    {/* New section for merging divisions */}
                    <div className="merge-section">
                        <h3>Create Merged Division</h3>
                        <p className="help-text">Select two or more divisions to merge them</p>
                        
                        <Select
                            isMulti
                            options={mergeableOptions} // Use filtered options
                            value={divisionsToMerge}
                            onChange={handleMergeSelect}
                            placeholder="Select divisions to merge"
                            className="react-select-container"
                            classNamePrefix="react-select"
                        />
                        
                        {divisionsToMerge.length >= 2 && (
                            <button 
                                type="button" 
                                className="btn-merge"
                                onClick={handleMergeDivisions}
                            >
                                Create Merged Division
                            </button>
                        )}
                    </div>
                </div>
                
                <div className="division-config-panel">
                    <h3>Division Configuration</h3>
                    <p className="help-text">Configure tournament format for selected division(s)</p>
                    
                    {selectedDivisions.length === 0 && !selectedMergedDivision && Object.keys(divisionConfigs).length === 0 && (
                        <div className="no-divisions-message">
                            Select divisions from the left panel to configure their settings
                        </div>
                    )}
                    
                    {/* Show configurations for selected divisions */}
                    {selectedDivisions.map(division => (
                        <div className="division-config-item" key={division.value}>
                            <h4 className="division-name">{division.label}</h4>
                            
                            <div className="config-row">
                                <label>Tournament Format:</label>
                                <Select
                                    options={formatOptions.map(opt => ({
                                        value: typeof opt.value === 'object' ? JSON.stringify(opt.value) : opt.value,
                                        label: typeof opt.label === 'object' ? String(opt.label) : opt.label
                                    }))}
                                    value={formatOptions.find(opt => 
                                        opt.value === divisionConfigs[division.value]?.format
                                    )}
                                    onChange={(selected) => 
                                        handleFormatChange(division.value, selected.value)
                                    }
                                    className="format-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            
                            <div className="config-row">
                                <label>Minimum Players:</label>
                                <input
                                    type="number"
                                    min="2"
                                    value={divisionConfigs[division.value]?.minPlayers || ""}
                                    onChange={(e) => 
                                        handleMinPlayersChange(division.value, e.target.value)
                                    }
                                    className="player-input"
                                />
                            </div>
                            
                            <div className="config-row">
                                <label>Maximum Players:</label>
                                <input
                                    type="number"
                                    min="2"
                                    value={divisionConfigs[division.value]?.maxPlayers || ""}
                                    onChange={(e) => 
                                        handleMaxPlayersChange(division.value, e.target.value)
                                    }
                                    className="player-input"
                                />
                            </div>
                        </div>
                    ))}
                    
                    {/* Show configuration for selected merged division */}
                    {selectedMergedDivision && (
                        <div className="division-config-item merged">
                            <div className="merged-division-header-with-action">
                                <h4 className="division-name">{selectedMergedDivision.name}</h4>
                                <button 
                                    type="button" 
                                    className="btn-remove"
                                    onClick={() => handleRemoveMergedDivision(selectedMergedDivision.name)}
                                >
                                    Remove Merged Division
                                </button>
                            </div>
                            
                            <div className="merged-sources">
                                <strong>Source Divisions:</strong>
                                <ul>
                                    {selectedMergedDivision.sourceDivisions.map((source, idx) => (
                                        <li key={idx}>{source}</li>
                                    ))}
                                </ul>
                            </div>
                            
                            <div className="config-row">
                                <label>Tournament Format:</label>
                                <Select
                                    options={formatOptions.map(opt => ({
                                        value: typeof opt.value === 'object' ? JSON.stringify(opt.value) : opt.value,
                                        label: typeof opt.label === 'object' ? String(opt.label) : opt.label
                                    }))}
                                    value={formatOptions.find(opt => 
                                        opt.value === divisionConfigs[selectedMergedDivision.name]?.format
                                    )}
                                    onChange={(selected) => 
                                        handleFormatChange(selectedMergedDivision.name, selected.value)
                                    }
                                    className="format-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            
                            <div className="config-row">
                                <label>Minimum Players:</label>
                                <input
                                    type="number"
                                    min="2"
                                    value={divisionConfigs[selectedMergedDivision.name]?.minPlayers || ""}
                                    onChange={(e) => 
                                        handleMinPlayersChange(selectedMergedDivision.name, e.target.value)
                                    }
                                    className="player-input"
                                />
                            </div>
                            
                            <div className="config-row">
                                <label>Maximum Players:</label>
                                <input
                                    type="number"
                                    min="2"
                                    value={divisionConfigs[selectedMergedDivision.name]?.maxPlayers || ""}
                                    onChange={(e) => 
                                        handleMaxPlayersChange(selectedMergedDivision.name, e.target.value)
                                    }
                                    className="player-input"
                                />
                            </div>
                        </div>
                    )}
                    
                    {/* Show inactive division configs (ones that aren't currently selected) */}
                    {Object.keys(divisionConfigs)
                        .filter(divName => 
                            !selectedDivisions.some(sd => sd.value === divName) &&
                            (!selectedMergedDivision || selectedMergedDivision.name !== divName) &&
                            !mergedDivisions.some(md => md.name === divName)
                        )
                        .map(divName => (
                            <div className="division-config-item inactive" key={divName}>
                                <h4 className="division-name">{divName}</h4>
                                
                                <div className="config-row">
                                    <label>Tournament Format:</label>
                                    <span className="value-display">
                                        {formatOptions.find(opt => 
                                            opt.value === divisionConfigs[divName]?.format
                                        )?.label || "Round Robin"}
                                    </span>
                                </div>
                                
                                <div className="config-row">
                                    <label>Minimum Players:</label>
                                    <span className="value-display">
                                        {divisionConfigs[divName]?.minPlayers || 4}
                                    </span>
                                </div>
                                
                                <div className="config-row">
                                    <label>Maximum Players:</label>
                                    <span className="value-display">
                                        {divisionConfigs[divName]?.maxPlayers || 16}
                                    </span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            
            {/* Add these to your render section, just before the merged divisions summary */}
            <div className="division-selection-legend">
                <div className="legend-item">
                    <span className="legend-marker available"></span>
                    <span>Available divisions</span>
                </div>
                <div className="legend-item">
                    <span className="legend-marker configured"></span>
                    <span>Configured divisions</span>
                </div>
                <div className="legend-item">
                    <span className="legend-marker merged"></span>
                    <span>Part of merged divisions</span>
                </div>
            </div>

            {/* Summary of Merged Divisions */}
            {mergedDivisions.length > 0 && (
                <div className="merged-divisions-summary">
                    <h3>Merged Divisions Summary</h3>
                    <p className="help-text">Overview of all merged divisions in this tournament</p>
                    
                    <div className="merged-divisions-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Merged Division Name</th>
                                    <th>Source Divisions</th>
                                    <th>Format</th>
                                    <th>Min Players</th>
                                    <th>Max Players</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mergedDivisions.map((merged, index) => (
                                    <tr key={index} className={selectedMergedDivision?.name === merged.name ? 'selected-row' : ''}>
                                        <td>{merged.name}</td>
                                        <td>{merged.sourceDivisions.join(', ')}</td>
                                        <td>{formatOptions.find(opt => 
                                            opt.value === divisionConfigs[merged.name]?.format
                                        )?.label || "Round Robin"}</td>
                                        <td>{divisionConfigs[merged.name]?.minPlayers || 4}</td>
                                        <td>{divisionConfigs[merged.name]?.maxPlayers || 16}</td>
                                        <td>
                                            <button 
                                                type="button" 
                                                className="btn-edit"
                                                onClick={() => handleSelectMergedDivision(merged)}
                                            >
                                                Edit
                                            </button>
                                            <button 
                                                type="button" 
                                                className="btn-remove"
                                                onClick={() => handleRemoveMergedDivision(merged.name)}
                                            >
                                                Remove
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DivisionConfigSelector;