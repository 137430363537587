/**
 * Utility functions for working with address objects
 */

/**
 * Normalize an address to use consistent field names
 * @param {Object} address - The address object to normalize
 * @returns {Object} Normalized address object
 */
export const normalizeAddress = (address) => {
  if (!address || typeof address !== 'object') return {};
  
  // Create a normalized address object with consistent field names
  return {
    addressLine1: address.addressLine1 || address.street || '',
    addressLine2: address.addressLine2 || address.street2 || '',
    city: address.city || '',
    state: address.state || address.province || '',
    zipCode: address.zipCode || address.postalCode || address.zip || '',
    country: address.country || ''
  };
};

/**
 * Convert to API format (using street/street2)
 * @param {Object} address - Address in UI format
 * @returns {Object} Address in API format
 */
export const toApiAddressFormat = (address) => {
  if (!address || typeof address !== 'object') return {};
  
  return {
    addressLine1: address.addressLine1 || address.street || '',
    addressLine2: address.addressLine2 || address.street2 || '',
    city: address.city || '',
    state: address.state || '',
    zipCode: address.zipCode || '',
    country: address.country || ''
  };
};

/**
 * Convert to UI format (using addressLine1/addressLine2)
 * @param {Object} address - Address in API format
 * @returns {Object} Address in UI format
 */
export const toUiAddressFormat = (address) => {
  if (!address || typeof address !== 'object') return {};
  
  return {
    addressLine1: address.street || address.addressLine1 || '',
    addressLine2: address.street2 || address.addressLine2 || '',
    city: address.city || '',
    state: address.state || '',
    zipCode: address.zipCode || '',
    country: address.country || ''
  };
};

/**
 * Format an address as a single line string
 * @param {Object} address - The address object
 * @returns {string} Formatted address string
 */
export const formatAddressOneLine = (address) => {
  if (!address) return '';
  
  // Use standardized field names, with fallbacks for backward compatibility
  const normalized = normalizeAddress(address);
  
  const parts = [];
  if (normalized.addressLine1) parts.push(normalized.addressLine1);
  if (normalized.addressLine2) parts.push(normalized.addressLine2);
  if (normalized.city) parts.push(normalized.city);
  if (normalized.state) parts.push(normalized.state);
  if (normalized.zipCode) parts.push(normalized.zipCode);
  if (normalized.country) parts.push(normalized.country);
  
  return parts.join(', ');
};

/**
 * Check if an address has data
 * @param {Object} address - The address object to check
 * @returns {boolean} True if any address field is filled
 */
export const hasAddressData = (address) => {
  if (!address) return false;
  
  return !!(address.addressLine1 || address.street || 
            address.addressLine2 || address.street2 || 
            address.city || address.state || 
            address.zipCode || address.country);
};