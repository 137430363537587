import React from 'react';

const NumberField = ({
    fieldName,
    label,
    value,
    onChange,
    error,
    inputRef,
    onKeyDown,
    helpText,
    min,
    max,
    step = 1
}) => {
    // Handle change to ensure numeric values
    const handleChange = (e) => {
        // Allow empty value
        if (e.target.value === '') {
            onChange(e);
            return;
        }

        // Validate numeric input
        const numValue = parseFloat(e.target.value);
        if (!isNaN(numValue)) {
            onChange(e);
        }
    };

    return (
        <div className="form-group">
            <label htmlFor={fieldName}>{label}</label>
            <input
                type="number"
                id={fieldName}
                name={fieldName}
                value={value}
                onChange={handleChange}
                className={`form-control ${error ? 'error' : ''}`}
                ref={inputRef}
                onKeyDown={onKeyDown}
                min={min}
                max={max}
                step={step}
            />
            {helpText && <p className="help-text">{helpText}</p>}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default NumberField;