import React, { useState, useRef, useCallback, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useDebounce } from '../hooks/useDebounce';
import { highlightMatch } from '../utils/searchUtils';

// Sample player data - in a real app, this would come from an API
const initialPlayers = [
  { id: 1, name: "John Smith", rating: "4.5", division: "Men's Singles" },
  { id: 2, name: "Sarah Johnson", rating: "3.5", division: "Women's Singles" },
  { id: 3, name: "Michael Davis", rating: "4.0", division: "Men's Singles" },
  { id: 4, name: "Emily Wilson", rating: "3.0", division: "Women's Singles" },
  { id: 5, name: "David Brown", rating: "5.0", division: "Men's Singles" },
  { id: 6, name: "Jennifer Lee", rating: "4.5", division: "Women's Singles" },
  { id: 7, name: "Robert Jones", rating: "3.5", division: "Mixed Doubles" },
  { id: 8, name: "Lisa Miller", rating: "4.0", division: "Women's Singles" },
  { id: 9, name: "James Taylor", rating: "3.0", division: "Men's Singles" },
  { id: 10, name: "Olivia Chen", rating: "3.5", division: "Mixed Doubles" },
  { id: 11, name: "William Clark", rating: "4.0", division: "Men's Singles" },
  { id: 12, name: "Emma Martinez", rating: "4.5", division: "Women's Singles" }
];

const PlayerSearch = ({ onPlayersChange }) => {
  const [playerSearchQuery, setPlayerSearchQuery] = useState('');
  const [activePlayers, setActivePlayers] = useState([]);
  const [showPlayerSuggestions, setShowPlayerSuggestions] = useState(false);
  const [playerKeyboardSelectedIndex, setPlayerKeyboardSelectedIndex] = useState(-1);
  const playerSuggestionsRef = useRef(null);
  const playerSuggestionItemsRef = useRef([]);
  
  const [players] = useState(initialPlayers);
  const [playerFuse] = useState(() => new Fuse(players, {
    keys: ['name', 'rating', 'division'],
    threshold: 0.3,
    distance: 100,
    ignoreLocation: true,
    minMatchCharLength: 1
  }));

  const getPlayerCurrentSearchTerm = useCallback(() => {
    const terms = playerSearchQuery.split(',');
    return terms[terms.length - 1].trim();
  }, [playerSearchQuery]);

  const getFilteredPlayers = useCallback(() => {
    const currentTerm = getPlayerCurrentSearchTerm();
    
    if (!currentTerm) {
      return [];
    }
    
    let results = [];
    if (currentTerm.length > 0) {
      results = playerFuse.search(currentTerm).map(result => result.item);
    } else {
      results = players;
    }
    
    return results.filter(player => 
      !activePlayers.some(activePlayer => activePlayer.id === player.id)
    );
  }, [getPlayerCurrentSearchTerm, playerFuse, players, activePlayers]);

  const handlePlayerSearch = useCallback((e) => {
    setPlayerSearchQuery(e.target.value);
    setShowPlayerSuggestions(e.target.value.trim() !== '');
    
    if (e.target.value.trim() === '') {
      setActivePlayers([]);
      onPlayersChange([]);
    }
  }, [onPlayersChange]);

  const handlePlayerClick = useCallback((player) => {
    if (!activePlayers.some(p => p.id === player.id)) {
      const newActivePlayers = [...activePlayers, player];
      setActivePlayers(newActivePlayers);
      onPlayersChange(newActivePlayers);
      
      const existingTerms = playerSearchQuery.split(',').slice(0, -1);
      const newSearchQuery = [
        ...existingTerms.map(term => term.trim()),
        player.name,
        ''
      ].join(', ');
      
      setPlayerSearchQuery(newSearchQuery);
    } else {
      setPlayerSearchQuery(playerSearchQuery + ', ');
    }
    
    setShowPlayerSuggestions(false);
  }, [activePlayers, playerSearchQuery, onPlayersChange]);

  const removePlayer = useCallback((playerId) => {
    const newActivePlayers = activePlayers.filter(p => p.id !== playerId);
    setActivePlayers(newActivePlayers);
    onPlayersChange(newActivePlayers);
    
    const newSearchQuery = newActivePlayers
      .map(p => p.name)
      .join(', ');
    
    setPlayerSearchQuery(newSearchQuery + (newActivePlayers.length > 0 ? ', ' : ''));
  }, [activePlayers, onPlayersChange]);

  const handlePlayerInputFocus = useCallback(() => {
    const currentTerm = getPlayerCurrentSearchTerm();
    if (currentTerm !== '') {
      setShowPlayerSuggestions(true);
    }
  }, [getPlayerCurrentSearchTerm]);

  const handlePlayerKeyDown = useCallback((e) => {
    if (!showPlayerSuggestions) return;
    
    const suggestions = getFilteredPlayers();
    
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setPlayerKeyboardSelectedIndex(prevIndex => {
          const newIndex = prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0;
          if (playerSuggestionItemsRef.current[newIndex]) {
            playerSuggestionItemsRef.current[newIndex].scrollIntoView({ block: 'nearest' });
          }
          return newIndex;
        });
        break;
        
      case 'ArrowUp':
        e.preventDefault();
        setPlayerKeyboardSelectedIndex(prevIndex => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1;
          if (playerSuggestionItemsRef.current[newIndex]) {
            playerSuggestionItemsRef.current[newIndex].scrollIntoView({ block: 'nearest' });
          }
          return newIndex;
        });
        break;
        
      case 'Enter':
        if (playerKeyboardSelectedIndex >= 0 && playerKeyboardSelectedIndex < suggestions.length) {
          e.preventDefault();
          handlePlayerClick(suggestions[playerKeyboardSelectedIndex]);
        }
        break;
        
      case 'Escape':
        e.preventDefault();
        setShowPlayerSuggestions(false);
        break;
        
      default:
        break;
    }
  }, [showPlayerSuggestions, playerKeyboardSelectedIndex, getFilteredPlayers, handlePlayerClick]);

  const debouncedPlayerSearchTerm = useDebounce(playerSearchQuery, 300);

  useEffect(() => {
    const currentTerm = getPlayerCurrentSearchTerm();
    setShowPlayerSuggestions(currentTerm.length > 0);
    setPlayerKeyboardSelectedIndex(-1);
  }, [debouncedPlayerSearchTerm, getPlayerCurrentSearchTerm]);

  useEffect(() => {
    playerSuggestionItemsRef.current = playerSuggestionItemsRef.current.slice(0, getFilteredPlayers().length);
  }, [debouncedPlayerSearchTerm, getFilteredPlayers]);

  return (
    <div className="player-nav">
      <div className="player-search">
        <input 
          type="text" 
          placeholder="Search players (name, rating, division)..." 
          value={playerSearchQuery}
          onChange={handlePlayerSearch}
          onFocus={handlePlayerInputFocus}
          onKeyDown={handlePlayerKeyDown}
        />
        
        <div className="selected-players">
          {activePlayers.map(player => (
            <div key={player.id} className="player-tag">
              <span className="player-name">{player.name}</span>
              <span className="player-rating">{player.rating}</span>
              <span 
                className="remove-player" 
                onClick={() => removePlayer(player.id)}
              >
                ×
              </span>
            </div>
          ))}
        </div>
      </div>
      
      {showPlayerSuggestions && (
        <div className="players-list" ref={playerSuggestionsRef}>
          {getFilteredPlayers().length > 0 ? (
            getFilteredPlayers().map((player, index) => (
              <div 
                key={player.id} 
                ref={el => playerSuggestionItemsRef.current[index] = el}
                className={`player-item ${
                  activePlayers.some(p => p.id === player.id) ? 'active' : ''
                } ${playerKeyboardSelectedIndex === index ? 'keyboard-selected' : ''}`}
                onClick={() => handlePlayerClick(player)}
              >
                <div className="player-info">
                  {highlightMatch(player.name, getPlayerCurrentSearchTerm())}
                  <div className="player-details">
                    <span className="rating">{player.rating}</span>
                    <span className="division">{player.division}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-results">No players match your search</div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlayerSearch;