import React, { useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateField.css';

const DateField = ({
    fieldName,
    label,
    value,
    onChange,
    error,
    inputRef,
    onKeyDown,
    helpText
}) => {
    const containerRef = useRef(null);
    
    useEffect(() => {
        if (inputRef && containerRef.current) {
            inputRef.current = containerRef.current;
        }
    }, [inputRef]);

    // Set default start date if it's the startDate field and no value exists
    useEffect(() => {
        if (fieldName === 'startDate' && !value) {
            // Set default to March 12, 2025
            const defaultStartDate = new Date(2025, 2, 12); // Month is 0-indexed (2 = March)
            onChange(defaultStartDate);
        }
    }, [fieldName, value, onChange]);

    const handleDateChange = (date) => {
        onChange(date);
    };

    // Get standard label based on fieldName
    const getStandardLabel = () => {
        switch(fieldName) {
            case 'startDate': return 'Start Date';
            case 'endDate': return 'End Date';
            case 'registrationOpenDate': return 'Open Registration Date';
            case 'registrationCloseDate': return 'Close Registration Date';
            case 'publicRegistrationDate': return 'Public Registration Date';
            default: return label || fieldName;
        }
    };

    return (
        <div className="form-group date-field-container inline-date-field" ref={containerRef}>
            <label htmlFor={fieldName} className="date-field-label">{getStandardLabel()}</label>
            <div className="date-field-content">
                <div className="date-picker-wrapper">
                    <DatePicker
                        id={fieldName}
                        selected={value ? new Date(value) : null}
                        onChange={handleDateChange}
                        dateFormat="MM/dd/yyyy"
                        className={`form-control date-field-input ${error ? 'error' : ''}`}
                        onKeyDown={onKeyDown}
                        isClearable={false}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="MM/DD/YYYY"
                        yearDropdownItemNumber={10}
                    />
                </div>
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default DateField;