import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './ClubList.css'; // Import your CSS file for styling

const ClubList = () => {
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { user, isLoggedIn } = useAuth(); // Get auth state from context

  useEffect(() => {
    const fetchUserClubs = async () => {
      try {
        setLoading(true);

        // Check if user is logged in using AuthContext
        if (!isLoggedIn) {
          navigate('/signin?returnTo=/clubs');
          return;
        }

        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

        // Get JWT from localStorage
        const jwt = localStorage.getItem('jwt');

        // Add auth headers
        const headers = {};
        if (jwt) {
          headers['X-JWT'] = jwt; // Use X-JWT header instead of Authorization
        } else {
          console.warn('No JWT found in localStorage');
          // If no JWT, redirect to signin
          navigate('/signin?returnTo=/clubs');
          return;
        }

        const response = await fetch(`${backendUrl}/api/clubs`, {
          method: 'GET',
          headers,
          credentials: 'include' // Include cookies for CORS
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch clubs: ${response.status} ${response.statusText}`);
        }

        const clubsData = await response.json();
        console.log('Clubs data received:', clubsData);
        // Check if any club is missing an CID
        const missingIds = clubsData.filter(club => !club.CID);
        if (missingIds.length > 0) {
          console.warn('Clubs missing IDs:', missingIds);
        }
        setClubs(clubsData);
      } catch (err) {
        setError(err.message || 'Failed to load clubs');
        console.error('Error fetching clubs:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserClubs();
  }, [navigate, isLoggedIn, user]);

  if (loading) {
    return (
      <div className="view-club-container loading">
        <div className="loading-spinner"></div>
        <p>Loading your clubs...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="view-club-container error">
        <h2>Error</h2>
        <p>{error}</p>
        <button
          className="button-secondary"
          onClick={() => navigate('/')}
        >
          Back to Home
        </button>
      </div>
    );
  }

  if (clubs.length === 0) {
    return (
      <div className="view-club-container">
        <h2>Your Clubs</h2>
        <div className="empty-state">
          <p>You don't have any clubs yet.</p>
          <button
            className="button-primary"
            onClick={() => navigate('/createclub')}
          >
            Create a Club
          </button>
        </div>
      </div>
    );
  }

  // Update the club card rendering to make the entire card clickable

  return (
    <div className="view-club-container">
      <h2 className="clubs-heading">Your Clubs</h2>
      <div className="club-cards-container">
        {clubs.map(club => (
          <div
            key={club.CID}
            className="club-card"
            onClick={() => {
              if (club.CID) {
                navigate(`/editclub/${club.CID}`);
              } else {
                console.error('Club has no ID:', club);
                setError('Cannot edit club: Club ID is missing');
              }
            }}
            role="button"
            tabIndex="0"
            aria-label={`Edit ${club.clubName}`}
            onKeyPress={(e) => {
              // Add keyboard accessibility
              if (e.key === 'Enter' || e.key === ' ') {
                if (club.CID) {
                  navigate(`/editclub/${club.CID}`);
                } else {
                  console.error('Club has no ID:', club);
                  setError('Cannot edit club: Club ID is missing');
                }
              }
            }}
          >
            {/* Top section: Club logo */}
            <div className="club-card-left">
              <div className="club-logo">
                {club.clubLogos && club.clubLogos[0] ? (
                  <img
                    src={club.clubLogos[0].thumbnailUrl || club.clubLogos[0]}
                    alt={`${club.clubName} logo`}
                    onError={(e) => {
                      e.target.onerror = null;
                      // Instead of trying to load a default image that might also fail,
                      // replace the img element with a placeholder div
                      const placeholder = document.createElement('div');
                      placeholder.className = 'placeholder-logo';
                      placeholder.textContent = '🏢'; // Building/club emoji
                      e.target.parentNode.replaceChild(placeholder, e.target);
                    }}
                  />
                ) : (
                  <div className="placeholder-logo">
                    🏢 {/* Building/club emoji as placeholder */}
                  </div>
                )}
              </div>
            </div>

            {/* Bottom section: Club details and button */}
            <div className="club-card-right">
              <div className="club-card-content">
                <h3 className="club-name">{club.clubName}</h3>
                <div className="club-details">
                  <p className="club-type">{club.clubType}</p>
                  {club.address && (
                    <p className="club-location">
                      <span className="location-dot">•</span> {club.address.city}, {club.address.state}
                    </p>
                  )}
                </div>
              </div>
              <div className="club-card-actions">
                <button
                  className="view-club-button"
                  onClick={(e) => {
                    // Stop propagation to prevent double navigation
                    e.stopPropagation();
                    if (club.CID) {
                      navigate(`/editclub/${club.CID}`);
                    } else {
                      console.error('Club has no ID:', club);
                      setError('Cannot edit club: Club ID is missing');
                    }
                  }}
                >
                  Edit Club
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="create-new-club">
        <button
          className="create-club-button"
          onClick={() => navigate('/createclub')}
        >
          Create Another Club
        </button>
      </div>
    </div>
  );
};

export default ClubList;