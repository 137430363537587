import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../context/AuthContext";
import "./SignInMenu.css";

// Import all SVG icons
import ContactUsIcon from "../assets/icons/contactus.svg";
import CreateATournamentIcon from "../assets/icons/createatournament.svg";
import EmailIcon from "../assets/icons/email.svg";
import FindMeIcon from "../assets/icons/findme.svg";
import HistoryIcon from "../assets/icons/history.svg";
import PasswordIcon from "../assets/icons/password.svg";
import RegisterIcon from "../assets/icons/register.svg";
import WishlistIcon from "../assets/icons/wishlist.svg";
import YourClubIcon from "../assets/icons/yourclub.svg";
import YourFavoritePlayersIcon from "../assets/icons/yourfavoriteplayers.svg";
import YourTournamentsIcon from "../assets/icons/yourtournaments.svg";

// We'll use a placeholder user icon for the main button
import UserIcon from "../assets/icons/yourclub.svg"; // Replace with user.svg if available

const SignInMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  // Use AuthContext instead of managing state locally
  const { user, isLoggedIn, logout } = useAuth();

  // Function to close menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Function to handle navigation to the Sign page
  const navigateToSignPage = () => {
    setIsOpen(false); // Close the menu
    navigate("/signin");
  };

  // Function to handle navigation to the home page
  const navigateToHomePage = () => {
    setIsOpen(!isOpen); // Toggle the menu bar
  };

  // Function to handle navigation to the Create Clubs page
  const navigateToCreateClubsPage = () => {
    setIsOpen(false); // Close the menu
    navigate("/createclub");
  };

  // Function to handle navigation to the Create Tournaments page
  const navigateToCreateTournamentPage = () => {
    setIsOpen(false); // Close the menu
    navigate("/createtournament");
  };

  // Navigate to Your Club page
  const navigateToYourClubPage = () => {
    setIsOpen(false); // Close the menu

    // Check if user is logged in first using AuthContext
    if (!isLoggedIn) {
      // If not logged in, redirect to sign in page with a return path
      navigate("/signin?returnTo=/clubs");
      return;
    }

    navigate("/clubs");

  };

  // Navigate to Your Tournaments page
  const navigateToYourTournamentsPage = () => {
    setIsOpen(false); // Close the menu

    // Check if user is logged in first using AuthContext
    if (!isLoggedIn) {
      // If not logged in, redirect to sign in page with a return path
      navigate("/signin?returnTo=/tournaments");
      return;
    }

    // Instead of fetching tournaments first, let's direct users to the tournaments page
    navigate("/tournaments");

  };

  // Add this navigation function with your other navigation functions
  const navigateToRegisterForTournamentPage = () => {
    setIsOpen(false); // Close the menu
    navigate("/register-tournament");
  };
  
  // Logout function - now using AuthContext's logout
  const handleLogout = () => {
    setIsOpen(false);
    logout(); // Use the logout function from AuthContext
    navigate('/');
  };

  return (
    <div className="menu-container" ref={menuRef}>
      {/* Sign In button (always visible) */}
      <button className="sign-in-button" onClick={navigateToHomePage}>
        <img src={UserIcon} alt="User" className="icon" />
        <span className="text">
          {isLoggedIn ? `Hello, ${user?.name?.split(' ')[0] || 'User'}` : 'Sign In'}
        </span>
        ▼
      </button>

      {/* Dropdown Menu (Appears only when clicked) */}
      {isOpen && (
        <div className="dropdown-menu">
          {!isLoggedIn ? (
            <>
              {/* Show this when user is NOT logged in */}
              <div className="flex justify-between items-center mb-4">
                <p className="text-gray-600 text-sm">Sign in for a faster PTMS experience</p>
              </div>
              <div className="menu-header">
                <button className="sign-in-link" onClick={navigateToSignPage}>
                  <img src={UserIcon} alt="Sign In" className="icon-inline" />
                  Sign In | Create Account
                </button>
              </div>
            </>
          ) : (
            <>
              {/* Show this when user IS logged in */}
              <div className="menu-header">
                <div className="user-info">
                  <img src={UserIcon} alt="User" className="icon-inline" />
                  <span>{user?.name || 'User'}</span>
                </div>
                <button className="logout-button" onClick={handleLogout}>
                  Sign Out
                </button>
              </div>
            </>
          )}

          <div className="menu-items">
            <p className="font-semibold mb-2">Your Account</p>
            <ul className="text-sm text-gray-700 space-y-2">
              <li onClick={navigateToRegisterForTournamentPage}>
                <img src={RegisterIcon} alt="Register" className="icon-inline" />
                Register for a Tournament
              </li>
              <li onClick={navigateToYourTournamentsPage}>
                <img src={YourTournamentsIcon} alt="Your Tournaments" className="icon-inline" />
                Your Tournaments
              </li>
              <li>
                <img src={WishlistIcon} alt="Wishlist" className="icon-inline" />
                Wish List Tournaments
              </li>
              <li>
                <img src={YourFavoritePlayersIcon} alt="Favorite Players" className="icon-inline" />
                Your Favorite Players
              </li>
              <li onClick={navigateToYourClubPage}>
                <img src={YourClubIcon} alt="Your Club" className="icon-inline" />
                Your Club
              </li>
              <li onClick={navigateToCreateClubsPage}>
                <img src={YourClubIcon} alt="Create Club" className="icon-inline" />
                Create a Club
              </li>
              <li onClick={navigateToCreateTournamentPage}>
                <img src={CreateATournamentIcon} alt="Create Tournament" className="icon-inline" />
                Create a Tournament
              </li>
              <li>
                <img src={HistoryIcon} alt="History" className="icon-inline" />
                History
              </li>
              <li>
                <img src={FindMeIcon} alt="Find Me" className="icon-inline" />
                Find Me™
              </li>
            </ul>
          </div>

          <div className="menu-items">
            <p className="font-semibold mb-2">Account Settings</p>
            <ul className="text-sm text-gray-700 space-y-2">
              <li>
                <img src={PasswordIcon} alt="Password" className="icon-inline" />
                Password & Personal Info
              </li>
              <li>
                <img src={EmailIcon} alt="Email" className="icon-inline" />
                Email & Mail Preferences
              </li>
            </ul>
          </div>

          <div className="menu-items">
            <p className="font-semibold mb-2">Support</p>
            <ul className="text-sm text-gray-700">
              <li>
                <img src={ContactUsIcon} alt="Contact Us" className="icon-inline" />
                Contact Us
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignInMenu;
