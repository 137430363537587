import React, { useState } from 'react';
import './TournamentLogos.css';

const TournamentLogos = ({ logos = [], onChange }) => {
    const [drag, setDrag] = useState(false);
    
    // Ensure logos is always an array
    const safeLogos = Array.isArray(logos) ? logos : [];
    
    console.log("TournamentLogos rendered with:", safeLogos);

    const handleDragOver = (e) => {
        e.preventDefault();
        setDrag(true);
    };

    const handleDragLeave = () => {
        setDrag(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDrag(false);
        
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFiles(e.dataTransfer.files);
        }
    };

    const handleFileInput = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            handleFiles(e.target.files);
        }
    };

    const handleFiles = (files) => {
        console.log(`Processing ${files.length} files`);
        
        const newLogos = [];
        
        Array.from(files).forEach(file => {
            console.log(`Processing file: ${file.name}, ${file.size} bytes`);
            
            const fileObject = {
                file,           // Original File object
                name: file.name,
                type: file.type,
                size: file.size,
                dataUrl: null   // Will be filled in later
            };
            newLogos.push(fileObject);
            
            // Create previews with FileReader
            const reader = new FileReader();
            reader.onload = (e) => {
                const dataUrl = e.target.result;
                console.log(`Created dataUrl for ${file.name}`);
                
                // IMPORTANT CHANGE: Directly update the file object
                fileObject.dataUrl = dataUrl;
                
                // Trigger a re-render by creating a new array
                // Don't pass a function to onChange!
                const updatedLogos = [...safeLogos, ...newLogos];
                onChange(updatedLogos);
            };
            
            reader.readAsDataURL(file);
        });
        
        // If there are no logos yet, add the new ones immediately
        // so the UI shows something while waiting for dataUrls
        if (safeLogos.length === 0) {
            onChange([...newLogos]);
        }
    };

    const handleRemoveLogo = (index) => {
        console.log(`Removing logo at index ${index}`);
        // Create a new array without the removed logo
        const newLogos = [...safeLogos];
        newLogos.splice(index, 1);
        onChange(newLogos);
    };

    return (
        <div className="tournament-logos-container">
            <h3>Tournament Logos</h3>
            <p className="help-text">Upload images to use as tournament logos. Recommended size: 512x512px.</p>
            
            <div 
                className={`logo-drop-area ${drag ? 'dragging' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <div className="drop-instructions">
                    <p>Drag images here, or</p>
                    <label className="upload-button">
                        Browse Files
                        <input 
                            type="file" 
                            accept="image/*"
                            multiple
                            onChange={handleFileInput}
                            style={{display: 'none'}} 
                        />
                    </label>
                </div>
            </div>
            
            {safeLogos.length > 0 && (
                <div className="logo-preview-container">
                    <h4>Uploaded Logos ({safeLogos.length})</h4>
                    <div className="logo-previews">
                        {safeLogos.map((logo, index) => (
                            <div key={index} className="logo-preview-item">
                                {logo.dataUrl ? (
                                    <img 
                                        src={logo.dataUrl} 
                                        alt={`Logo ${index + 1}`}
                                        className="logo-preview-image" 
                                    />
                                ) : (
                                    <div className="logo-loading">Loading...</div>
                                )}
                                <div className="logo-name">{logo.name}</div>
                                <button 
                                    type="button"
                                    className="remove-logo-button"
                                    onClick={() => handleRemoveLogo(index)}
                                >
                                    ✕
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TournamentLogos;