import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { formConfig } from './config/formConfig';
import {
    fetchTournament,
    createTournament,
    updateTournament,
    createEmptyFormData,
    publishTournament,
    unpublishTournament
} from './utils/api';

// Import components
import FieldNavigation from './components/FieldNavigation';
import FormActions from './components/FormActions';
import ArrayFieldEditor from './components/ArrayFieldEditor';
import GroupFieldEditor from './components/GroupFieldEditor';
import CommunicationSettings from './components/CommunicationSettings';
import TournamentOperatorDetails from './components/TournamentOperatorDetails';
import PublicToggle from './components/PublicToggle'; // Add this import

// Field components
import TextField from './fields/TextField';
import TextareaField from './fields/TextareaField';
import SelectField from './fields/SelectField';
import MultiSelectField from './fields/MultiSelectField';
import DateField from './fields/DateField';
import CheckboxField from './fields/CheckboxField';
import NumberField from './fields/NumberField';
import FileField from './fields/FileField';
import TournamentLogos from './components/TournamentLogos';
import DivisionSelector from './components/DivisionSelector';
import DivisionConfigSelector from './components/DivisionConfigSelector';
import RichTextEditor from './fields/RichTextEditor';

import './CreateTournament.css';
import './FormResetStyles.css'; // Import after all other CSS files

const CreateTournament = () => {
    // Use tid from URL params to match the route parameter in App.js (:tid)
    const { tid: tournamentId } = useParams();
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();
    const isEditMode = Boolean(tournamentId);

    // Field references for navigation
    const inputRefs = useRef({});

    // Form state
    const [formData, setFormData] = useState(createEmptyFormData());
    const [currentField, setCurrentField] = useState(Object.keys(formConfig)[0]);
    const [fieldNames] = useState(Object.keys(formConfig));

    // UI state
    const [errors, setErrors] = useState({});
    const [generalError, setGeneralError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(isEditMode);
    
    // Replace isPublished with isPublic
    const [isPublic, setIsPublic] = useState(false);
    const [isPublicToggling, setIsPublicToggling] = useState(false);

    // Check if user is logged in with proper cleanup
    useEffect(() => {
        let isMounted = true;

        if (isMounted && !isLoggedIn) {
            navigate('/signin?returnTo=' + (isEditMode ?
                `/edittournament/${tournamentId}` :
                '/createtournament'));
        }

        return () => {
            isMounted = false;
        };
    }, [navigate, isLoggedIn, tournamentId, isEditMode]);

    // Focus the current field when it changes
    useEffect(() => {
        const currentInputRef = inputRefs.current[currentField];
        let timeoutId;

        if (currentInputRef && typeof currentInputRef.focus === 'function') {
            timeoutId = setTimeout(() => {
                if (currentInputRef) {
                    currentInputRef.focus();
                }
            }, 100);
        }

        // Clean up the timeout when component unmounts or field changes
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [currentField]);

    // Scroll the selected navigation item into view when it changes
    useEffect(() => {
        const selectedNavItem = document.querySelector('.field-nav-item.active');
        if (selectedNavItem) {
            selectedNavItem.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });
        }
    }, [currentField]);

    // Fetch tournament data if in edit mode
    useEffect(() => {
        if (isEditMode) {
            let isMounted = true;

            const loadTournamentData = async () => {
                setIsLoading(true);
                try {
                    const tournamentData = await fetchTournament(tournamentId);

                    if (isMounted) {
                        // Extract division settings if they exist
                        if (tournamentData.divisionSettings) {
                            tournamentData.divisionConfigs = tournamentData.divisionSettings.divisionConfigs || {};
                            tournamentData.selectedDivisions = tournamentData.divisionSettings.selectedDivisions || [];
                            tournamentData.mergedDivisions = tournamentData.divisionSettings.mergedDivisions || [];
                        }
                        
                        setFormData(tournamentData);
                        
                        // Properly parse isPublic as a boolean - it could be a string "true"/"false" or a boolean
                        const parsedIsPublic = typeof tournamentData.isPublic === 'string' 
                            ? tournamentData.isPublic.toLowerCase() === 'true'
                            : !!tournamentData.isPublic;
                        
                        setIsPublic(parsedIsPublic);
                        console.log('Raw isPublic value:', tournamentData.isPublic);
                        console.log('Parsed isPublic value:', parsedIsPublic);
                        console.log('Tournament public status:', parsedIsPublic ? 'Public' : 'Private');
                        
                        setIsLoading(false);
                    }
                } catch (error) {
                    if (isMounted) {
                        console.error('Error fetching tournament data:', error);
                        setGeneralError('Failed to load tournament data. ' + error.message);
                        setIsLoading(false);
                    }
                }
            };

            loadTournamentData();

            return () => {
                isMounted = false;
            };
        }
    }, [tournamentId, isEditMode]);

    // Handle public toggle change
    const handlePublicToggleChange = async (e) => {
        const newIsPublic = e.target.checked;
        setIsPublicToggling(true);
        
        try {
            if (newIsPublic) {
                await publishTournament(tournamentId);
                // Update both the toggle state and form data
                setIsPublic(true);
                setFormData(prev => ({
                    ...prev,
                    isPublic: true
                }));
            } else {
                await unpublishTournament(tournamentId);
                // Update both the toggle state and form data
                setIsPublic(false);
                setFormData(prev => ({
                    ...prev,
                    isPublic: false
                }));
            }
        } catch (error) {
            setGeneralError(`Failed to ${newIsPublic ? 'publish' : 'unpublish'} tournament: ${error.message}`);
        } finally {
            setIsPublicToggling(false);
        }
    };

    // Handle input changes
    const handleInputChange = (fieldName, value) => {
        // Special handling for division settings
        if (fieldName === 'divisions') {
            setFormData(prev => ({
                ...prev,
                divisions: value
            }));
        } else if (fieldName === 'divisionSettings') {
            setFormData(prev => ({
                ...prev,
                divisionConfigs: value
            }));
        } else {
            // Normal field handling
            setFormData(prev => ({
                ...prev,
                [fieldName]: value
            }));
        }
        
        // Clear error for this field when user makes a change
        if (errors[fieldName]) {
            setErrors(prev => ({
                ...prev,
                [fieldName]: null
            }));
        }
    };

    // Handle group field input changes (nested objects)
    // eslint-disable-next-line no-unused-vars
    const handleGroupInputChange = (groupName, fieldName, value) => {
        setFormData(prev => ({
            ...prev,
            [groupName]: {
                ...prev[groupName],
                [fieldName]: value
            }
        }));

        // Clear errors when field is updated
        if (errors[`${groupName}.${fieldName}`]) {
            setErrors(prev => ({
                ...prev,
                [`${groupName}.${fieldName}`]: ''
            }));
        }
    };

    // Handle array field changes
    const handleArrayChange = (fieldName, index, itemValue, itemFieldName = null) => {
        setFormData(prev => {
            const newArray = [...(prev[fieldName] || [])];

            // If there's a specific field to update in the object
            if (itemFieldName) {
                newArray[index] = {
                    ...newArray[index],
                    [itemFieldName]: itemValue
                };
            } else {
                // Directly update the array item
                newArray[index] = itemValue;
            }

            return {
                ...prev,
                [fieldName]: newArray
            };
        });

        // Clear errors for this field
        const errorKey = itemFieldName
            ? `${fieldName}[${index}].${itemFieldName}`
            : `${fieldName}[${index}]`;

        if (errors[errorKey]) {
            setErrors(prev => ({
                ...prev,
                [errorKey]: ''
            }));
        }
    };

    // Add new item to an array field
    const handleAddArrayItem = (fieldName, emptyItem = {}) => {
        setFormData(prev => ({
            ...prev,
            [fieldName]: [...(prev[fieldName] || []), emptyItem]
        }));
    };

    // Remove item from an array field
    const handleRemoveArrayItem = (fieldName, index) => {
        setFormData(prev => {
            const newArray = [...(prev[fieldName] || [])];
            newArray.splice(index, 1);
            return {
                ...prev,
                [fieldName]: newArray
            };
        });
    };


    // Handle keyboard navigation
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            navigateToNextField();
        }
    };

    // Navigate to the next field
    const navigateToNextField = () => {
        const currentIndex = fieldNames.indexOf(currentField);
        if (currentIndex < fieldNames.length - 1) {
            setCurrentField(fieldNames[currentIndex + 1]);
        }
    };

    // Handle form submission with proper cleanup
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setGeneralError('');

        try {
            // Debug log form data
            console.log('Form data before submission:', formData);
            
            // Validate logos - show what we're working with
            if (formData.logos && Array.isArray(formData.logos)) {
                console.log(`Found ${formData.logos.length} logos to process`);
                formData.logos.forEach((logo, i) => {
                    console.log(`Logo ${i}:`, logo.name, logo.file ? 'has File object' : 'NO FILE OBJECT');
                });
            } else {
                console.log('No logos array found in form data');
            }
            
            // Add this debug check right before FormData creation
            console.log("Logos type:", typeof formData.logos); 
            if (typeof formData.logos === 'function') {
              console.error("CRITICAL ERROR: logos is a function, not an array!");
              // Force it to be an empty array to avoid errors
              formData.logos = [];
            } else if (Array.isArray(formData.logos)) {
              console.log(`Found ${formData.logos.length} logo files to upload`);
              formData.logos.forEach((logo, i) => {
                console.log(`Logo ${i}:`, logo);
              });
            }
            
            // Create a FormData object for multipart submission
            const submitFormData = new FormData();
            
            // Create a copy of form data without the file objects
            const tournamentDataCopy = { ...formData };
            
            // Remove file fields from JSON as we'll add them separately
            delete tournamentDataCopy.logos;
            
            // Add tournament data as JSON string
            submitFormData.append('tournamentData', JSON.stringify(tournamentDataCopy));
            console.log('Tournament data added as JSON string');
            
            // Process and add logo files if they exist
            let logoCount = 0;
            if (formData.logos && Array.isArray(formData.logos)) {
                formData.logos.forEach((logoItem, index) => {
                    if (logoItem && logoItem.file instanceof File) {
                        submitFormData.append('logos', logoItem.file); // Changed from 'logos' to 'logo'
                        console.log(`Added logo file ${index}: ${logoItem.file.name} (${logoItem.file.size} bytes)`);
                        logoCount++;
                    } else {
                        console.warn(`Logo ${index} missing file object:`, logoItem);
                    }
                });
            }
            
            // Alert if no logos were added
            if (logoCount === 0) {
                console.warn('No logo files were added to the FormData object!');
            }
            
            // Log FormData entries for debugging
            console.log('FormData entries:');
            for (let pair of submitFormData.entries()) {
                if (pair[1] instanceof File) {
                    console.log(`- ${pair[0]}: File ${pair[1].name} (${pair[1].size} bytes)`);
                } else {
                    console.log(`- ${pair[0]}: [data]`);
                }
            }
            
            // Send the request with the properly formed FormData
            if (isEditMode) {
                await updateTournament(tournamentId, submitFormData);
                alert('Tournament updated successfully!');
            } else {
                await createTournament(submitFormData);
                alert('Tournament created successfully!');
            }
            
            navigate('/tournaments');
        } catch (error) {
            console.error(`Error ${isEditMode ? 'updating' : 'creating'} tournament:`, error);
            setGeneralError(`Failed to ${isEditMode ? 'update' : 'create'} tournament: ${error.message}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Render a field based on its type
    const renderField = (fieldName) => {
        const field = formConfig[fieldName];

        if (!field) return null;

        switch (field.type) {
            case 'text':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <TextField
                            fieldName={fieldName}
                            label={field.label}
                            value={formData[fieldName] || ''}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            error={errors[fieldName]}
                            inputRef={el => inputRefs.current[fieldName] = el}
                            onKeyDown={handleKeyDown}
                            helpText={field.helpText}
                        />
                    </div>
                );

            case 'textarea':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <TextareaField
                            fieldName={fieldName}
                            label={field.label}
                            value={formData[fieldName] || ''}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            error={errors[fieldName]}
                            inputRef={el => inputRefs.current[fieldName] = el}
                            onKeyDown={handleKeyDown}
                            helpText={field.helpText}
                        />
                    </div>
                );

            case 'select':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <SelectField
                            fieldName={fieldName}
                            label={field.label}
                            value={formData[fieldName] || ''}
                            options={field.options || []}
                            onChange={(e) => handleInputChange(fieldName, e.target.value)}
                            error={errors[fieldName]}
                            inputRef={el => inputRefs.current[fieldName] = el}
                            onKeyDown={handleKeyDown}
                            helpText={field.helpText}
                        />
                    </div>
                );

            case 'multiselect':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <MultiSelectField
                            fieldName={fieldName}
                            label={field.label}
                            value={Array.isArray(formData[fieldName]) ? formData[fieldName] : []}
                            options={field.options || []}
                            onChange={(selected) => handleInputChange(fieldName, selected)}
                            error={errors[fieldName]}
                            inputRef={el => inputRefs.current[fieldName] = el}
                            helpText={field.helpText}
                        />
                    </div>
                );

            case 'number':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <NumberField
                            fieldName={fieldName}
                            label={field.label}
                            value={formData[fieldName] || ''}
                            onChange={(e) => handleInputChange(fieldName, e.target.value)}
                            error={errors[fieldName]}
                            inputRef={el => inputRefs.current[fieldName] = el}
                            onKeyDown={handleKeyDown}
                            helpText={field.helpText}
                        />
                    </div>
                );

            case 'date':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <DateField
                            fieldName={fieldName}
                            label={field.label}
                            value={formData[fieldName] || ''}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            error={errors[fieldName]}
                            inputRef={el => inputRefs.current[fieldName] = el}
                            onKeyDown={handleKeyDown}
                            helpText={field.helpText}
                        />
                    </div>
                );

            case 'checkbox':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <CheckboxField
                            fieldName={fieldName}
                            label={field.label}
                            checked={formData[fieldName] || false}
                            onChange={(e) => handleInputChange(fieldName, e.target.checked)}
                            error={errors[fieldName]}
                            inputRef={el => inputRefs.current[fieldName] = el}
                            helpText={field.helpText}
                        />
                    </div>
                );

            case 'group':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <GroupFieldEditor
                            fieldName={fieldName}
                            field={field}
                            formData={formData}
                            setFormData={setFormData}
                            errors={errors}
                            setErrors={setErrors}
                            inputRefs={inputRefs}
                            handleKeyDown={handleKeyDown}
                            handleInputChange={handleGroupInputChange} // Pass the function here
                        />
                    </div>
                );

            case 'array':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <ArrayFieldEditor
                            fieldName={fieldName}
                            field={field}
                            formData={formData}
                            setFormData={setFormData}
                            errors={errors}
                            setErrors={setErrors}
                            inputRefs={inputRefs}
                            handleKeyDown={handleKeyDown}
                            handleArrayChange={handleArrayChange} // Pass the function here
                            handleAddItem={handleAddArrayItem} // Pass the function here
                            handleRemoveItem={handleRemoveArrayItem} // Pass the function here
                        />
                    </div>
                );

            case 'file':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <FileField
                            fieldName={fieldName}
                            label={field.label}
                            value={formData[fieldName]}
                            onChange={(file) => handleInputChange(fieldName, file)}
                            error={errors[fieldName]}
                            inputRef={inputRefs.current[fieldName]}
                            accept={field.accept}
                            helpText={field.helpText}
                        />
                    </div>
                );

            case 'richText':
                return (
                    <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                        <h2>{field.label}</h2>
                        <RichTextEditor
                            fieldName={fieldName}
                            label={field.label}
                            value={formData[fieldName] || ''}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            required={field.required}
                            error={errors[fieldName]}
                            helpText={field.helpText}
                        />
                        {errors[fieldName] && <div className="error-message">{errors[fieldName]}</div>}
                    </div>
                );

            case 'custom':
                if (field.component === 'TournamentLogos') {
                    return (
                        <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                            <h2>{field.label}</h2>
                            <TournamentLogos
                                logos={formData[fieldName] || []}
                                onChange={(logos) => handleInputChange(fieldName, logos)}
                            />
                        </div>
                    );
                }
                if (field.component === 'DivisionSelector') {
                    return (
                        <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                            <h2>{field.label}</h2>
                            {field.helpText && <p className="help-text">{field.helpText}</p>}
                            <DivisionSelector
                                field={field}
                                value={formData[fieldName]}
                                onChange={(value) => handleInputChange(fieldName, value)}
                            />
                            {errors[fieldName] && <div className="error-message">{errors[fieldName]}</div>}
                        </div>
                    );
                } else if (field.component === 'CommunicationSettings') {
                    return (
                        <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                            <h2>{field.label}</h2>
                            {field.helpText && <p className="help-text">{field.helpText}</p>}
                            <CommunicationSettings
                                field={field}
                                value={formData[fieldName] || {}}
                                onChange={(value) => handleInputChange(fieldName, value)}
                            />
                            {errors[fieldName] && <div className="error-message">{errors[fieldName]}</div>}
                        </div>
                    );
                } else if (field.component === 'TournamentOperatorDetails') {
                    return (
                        <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                            <h2>{field.label}</h2>
                            {field.helpText && <p className="help-text">{field.helpText}</p>}
                            <TournamentOperatorDetails
                                field={field}
                                value={formData[fieldName] || {}}
                                onChange={(value) => handleInputChange(fieldName, value)}
                            />
                            {errors[fieldName] && <div className="error-message">{errors[fieldName]}</div>}
                        </div>
                    );
                } else if (field.component === 'DivisionConfigSelector') {
                    return (
                        <div className="field-editor" key={fieldName} data-fieldname={fieldName}>
                            <h2>{field.label}</h2>
                            {field.helpText && <p className="help-text">{field.helpText}</p>}
                            <DivisionConfigSelector
                                field={field}
                                value={formData}
                                onChange={(value) => {
                                    // Store all division config data in the formData
                                    setFormData(prev => ({
                                        ...prev,
                                        divisionConfigs: value.divisionConfigs || {},
                                        selectedDivisions: value.selectedDivisions || [],
                                        mergedDivisions: value.mergedDivisions || []
                                    }));
                                }}
                            />
                            {errors[fieldName] && <div className="error-message">{errors[fieldName]}</div>}
                        </div>
                    );
                }
                return null;

            default:
                return <div>Unknown field type: {field.type}</div>;
        }
    };

    // Show loading state
    if (isLoading) {
        return <div className="loading-container">Loading tournament data...</div>;
    }

    return (
        <div className="create-tournament-container">
            <div className="create-tournament-header">
                <h1>{isEditMode ? 'Edit Tournament' : 'Create Tournament'}</h1>
                {generalError && (
                    <div className="general-error">
                        <p>{generalError}</p>
                    </div>
                )}
            </div>

            <div className="create-tournament-content">
                <FieldNavigation
                    formConfig={formConfig}
                    currentField={currentField}
                    setCurrentField={setCurrentField}
                    errors={errors}
                />

                <div className="form-container">
                    <form onSubmit={handleSubmit}>
                        {/* Show only the public toggle in edit mode */}
                        {isEditMode && (
                            <div className="tournament-stats">
                                <div className="public-toggle-section">
                                    <PublicToggle
                                        isPublic={isPublic}
                                        onChange={handlePublicToggleChange}
                                        disabled={isPublicToggling || isSubmitting}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="current-field-container">
                            {renderField(currentField)}
                        </div>

                        <FormActions
                            isLastField={currentField === fieldNames[fieldNames.length - 1]}
                            navigateToNextField={navigateToNextField}
                            isSubmitting={isSubmitting}
                            isEditMode={isEditMode}
                            tournamentId={tournamentId}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateTournament;