import React from 'react';

export const highlightMatch = (text, query) => {
  if (!query || query.length < 2) return text;
  
  try {
    // Create a case-insensitive regex for highlighting
    const regex = new RegExp(`(${query.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')})`, 'gi');
    const parts = text.split(regex);
    
    return (
      <>
        {parts.map((part, i) => 
          regex.test(part) ? 
            <span key={i} className="highlight">{part}</span> : 
            part
        )}
      </>
    );
  } catch(e) {
    // Fallback if regex fails
    return text;
  }
};