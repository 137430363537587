import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import TournamentRegistrationPopover from './TournamentRegistrationPopover';
import './RegisterTournament.css';

const RegisterTournament = () => {
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [imageLoadFailed, setImageLoadFailed] = useState({});
  const [popoverState, setPopoverState] = useState({
    isOpen: false,
    tournament: null,
    position: { top: 0, left: 0 }
  });
  
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    let isMounted = true; // Flag to track if component is mounted

    if (!isLoggedIn) {
      navigate('/signin?returnTo=/register-tournament');
      return;
    }

    const fetchTournaments = async () => {
      try {
        setLoading(true);
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
        const headers = {};
        const jwt = localStorage.getItem('jwt');
        
        if (jwt) {
          headers['X-JWT'] = jwt;
        }

        // Fetch open tournaments for registration
        const response = await fetch(`${backendUrl}/api/public/tournaments`, {
          method: 'GET',
          headers,
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch tournaments: ${response.status}`);
        }

        const data = await response.json();
        
        // Only update state if component is still mounted
        if (isMounted) {
          setTournaments(data);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message || 'Failed to load tournaments');
        }
        console.error('Error fetching tournaments:', err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchTournaments();

    // Cleanup function to prevent updates after unmount
    return () => {
      isMounted = false;
    };
  }, [isLoggedIn, navigate]);

  const handleRegisterClick = (tournament, event) => {
    // Log the selected tournament data
    console.log('Selected tournament for registration:', tournament);
    console.log('Tournament divisions:', tournament.divisions);
    console.log('Tournament divisionSettings:', tournament.divisionSettings);
    
    // Check if divisions and divisionSettings exist
    if (!tournament.divisions) {
      // If divisions data is missing, fetch complete tournament data
      fetchCompleteData(tournament.TID)
        .then(completeData => {
          openPopover(completeData, event);
        })
        .catch(err => {
          console.error('Error fetching complete tournament data:', err);
          // Fall back to using the partial data
          openPopover(tournament, event);
        });
    } else {
      // Use existing tournament data
      openPopover(tournament, event);
    }
  };

  // Helper function to open the popover
  const openPopover = (tournament, event) => {
    // Get the position for the popover
    const rect = event.currentTarget.getBoundingClientRect();
    
    setPopoverState({
      isOpen: true,
      tournament,
      position: {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX + (rect.width / 2)
      }
    });
    
    // Prevent navigating away
    event.preventDefault();
    event.stopPropagation();
  };

  // Function to fetch complete tournament data if needed
  const fetchCompleteData = async (tournamentId) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
    const headers = {};
    const jwt = localStorage.getItem('jwt');
    
    if (jwt) {
      headers['X-JWT'] = jwt;
    }

    // Fetch complete tournament data
    const response = await fetch(`${backendUrl}/api/tournaments/${tournamentId}`, {
      method: 'GET',
      headers,
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch tournament: ${response.status}`);
    }

    const completeData = await response.json();
    console.log('Fetched complete tournament data:', completeData);
    return completeData;
  };

  const handleClosePopover = () => {
    setPopoverState({
      isOpen: false,
      tournament: null,
      position: { top: 0, left: 0 }
    });
  };
  
  // Update the handleSubmitRegistration function to handle the registration data
  const handleSubmitRegistration = async (formData) => {
    try {
      // Show loading state
      setLoading(true);
      
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
      const headers = {
        'Content-Type': 'application/json'
      };
      
      const jwt = localStorage.getItem('jwt');
      if (jwt) {
        headers['X-JWT'] = jwt;
      }

      console.log('Sending registration data to API:', formData);

      // Submit registration
      const response = await fetch(`${backendUrl}/api/tournaments/${popoverState.tournament.TID}/register`, {
        method: 'POST',
        headers,
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        const errorMessage = errorData?.message || `Registration failed: ${response.status}`;
        throw new Error(errorMessage);
      }

      // Show success message
      alert('Registration successful! You are now registered for the tournament.');
      
      // Close the popover
      handleClosePopover();
      
      // Redirect to the my registrations page or refresh
      navigate('/my-registrations');
    } catch (err) {
      setError(err.message || 'Failed to register for tournament');
      console.error('Error registering for tournament:', err);
      alert(`Registration failed: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Double-click handler for tournament cards
  const handleDoubleClick = (tournament, event) => {
    handleRegisterClick(tournament, event);
  };

  if (!isLoggedIn) {
    return null; // Will redirect in useEffect
  }

  if (loading) {
    return (
      <div className="register-tournament-container">
        <div className="loading">Loading available tournaments...</div>
      </div>
    );
  }

  return (
    <div className="register-tournament-container">
      <h1>Register for a Tournament</h1>
      
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <section className="tournaments-section">
        <h2>Tournaments Open for Registration</h2>
        
        {tournaments.length === 0 ? (
          <div className="empty-state">
            <p>No tournaments are currently open for registration.</p>
          </div>
        ) : (
          <div className="card-grid">
            {tournaments.map(tournament => (
              <div 
                key={tournament.TID} 
                className="card tournament-card"
                onDoubleClick={(e) => handleDoubleClick(tournament, e)}
              >
                <div className="card-logo">
                  {tournament.logos && tournament.logos.length > 0 ? (
                    imageLoadFailed[tournament.TID] ? (
                      <div className="placeholder-logo">🏆</div>
                    ) : (
                      <img 
                        src={tournament.logos[0].galleryUrl || tournament.logos[0].originalUrl} 
                        alt={tournament.logos[0].description || tournament.name} 
                        onError={(e) => {
                          // Prevent event from bubbling to global handler
                          e.stopPropagation();
                          // Update state safely
                          setImageLoadFailed(prev => ({
                            ...prev,
                            [tournament.TID]: true
                          }));
                        }}
                      />
                    )
                  ) : (
                    <div className="placeholder-logo">🏆</div>
                  )}
                </div>
                <div className="card-content">
                  <h3>{tournament.name}</h3>
                  <p className="card-status">Status: {tournament.status}</p>
                  {tournament.importantDates?.startDate && (
                    <p className="card-date">
                      {new Date(tournament.importantDates.startDate).toLocaleDateString()} - {' '}
                      {tournament.importantDates?.endDate ?
                        new Date(tournament.importantDates.endDate).toLocaleDateString() : 'TBD'}
                    </p>
                  )}
                  <button 
                    className="register-button" 
                    onClick={(e) => handleRegisterClick(tournament, e)}
                  >
                    Register Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </section>
      
      {/* Registration Popover */}
      {popoverState.isOpen && popoverState.tournament && (
        <TournamentRegistrationPopover
          tournament={popoverState.tournament}
          position={popoverState.position}
          onClose={handleClosePopover}
          onSubmit={handleSubmitRegistration}
        />
      )}
    </div>
  );
};

export default RegisterTournament;