import React, { useEffect, useRef } from 'react';
import './TournamentRegistrationPopover.css';

// Import form components
import SkillCategorySelect from './form/SkillCategorySelect';
import AgeGroupSelect from './form/AgeGroupSelect';
import MatchFormatSelect from './form/MatchFormatSelect';
import DivisionSelect from './form/DivisionSelect';
import PartnerInfoSection from './form/PartnerInfoSection';

// Import custom hooks
import { useTournamentForm } from './hooks/useTournamentForm';
import { useDivisionMatching } from './hooks/useDivisionMatching';

// Add this function at the top of your component to create proper division objects
const createDivisionObjects = (divisionStrings) => {
  if (!divisionStrings || !Array.isArray(divisionStrings)) return [];
  
  return divisionStrings.map(div => {
    if (typeof div === 'string') {
      return { 
        value: div,
        label: div,
        id: `div-${div}`
      };
    }
    return div;
  });
};

const TournamentRegistrationPopover = ({ 
  tournament, 
  onClose, 
  onSubmit, 
  position = { top: 0, left: 0 } 
}) => {
  // Log tournament data on initial render
  useEffect(() => {
    console.group('Tournament Registration Data');
    console.log('Full tournament object:', tournament);
    console.log('Tournament ID:', tournament.TID || tournament.id);
    console.log('Tournament name:', tournament.name);
    console.log('Divisions structure:', tournament.divisions);
    console.log('Division configs:', tournament.divisionSettings?.divisionConfigs);
    console.log('Selected divisions:', tournament.divisionSettings?.selectedDivisions);
    console.log('Merged divisions:', tournament.divisionSettings?.mergedDivisions);
    console.log('Generated divisions:', tournament.divisions?.generatedDivisions);
    console.groupEnd();
  }, [tournament]);

  // Get tournament form state and handlers
  const { 
    formData,
    setFormData,
    skillOptions,
    genderOptions,
    ratingOptions,
    ageOptions,
    availableDivisions,
    generatedDivisions,
    handleChange,
    handleMatchFormatChange,
    handleDivisionChange,
    handleSubmit // Use this directly from the hook
  } = useTournamentForm(tournament, onSubmit);

  // Ref for handling outside clicks
  const popoverRef = useRef(null);

  // Handle click outside to close popover
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const { matchingDivisions } = useDivisionMatching(
    formData.gender, 
    availableDivisions,
    formData
  );

  // Add this after the useDivisionMatching call
  useEffect(() => {
    console.log('Match formats changed:', formData.gender);
    console.log('Available divisions for matching:', availableDivisions);
  }, [formData.gender, availableDivisions]);

  // Update the useEffect that handles matching divisions
  useEffect(() => {
    // Update form data when matching divisions change
    console.log('Matching divisions update triggered:', matchingDivisions);
    
    if (matchingDivisions.length > 0) {
      console.log('Setting divisions to:', matchingDivisions);
      
      // Use a timeout to ensure this doesn't cause an infinite loop
      const timer = setTimeout(() => {
        setFormData(prev => {
          // Only update if different
          if (prev.division.length !== matchingDivisions.length || 
              !prev.division.every(d => matchingDivisions.includes(d))) {
            console.log('Updating division in form state');
            return {
              ...prev,
              division: matchingDivisions
            };
          }
          console.log('No changes needed to division state');
          return prev;
        });
      }, 0);
      
      return () => clearTimeout(timer);
    } else {
      console.log('No matching divisions found');
    }
  }, [matchingDivisions, setFormData]);

  // Enhance the division filtering in useDivisionMatching hook
  // Also, add this to directly handle division updates when match formats change
  // Update the handleMatchFormatChangeWithDivisions function with more precise matching
  // Update the handleMatchFormatChangeWithDivisions function to filter by skill and rating too
  const handleMatchFormatChangeWithDivisions = (format, isSelected) => {
    // First update the match format selection
    handleMatchFormatChange(format, isSelected);
    
    // Then manually update divisions based on the new selection
    setTimeout(() => {
      const updatedFormats = isSelected 
        ? formData.gender.filter(g => g !== format) 
        : [...formData.gender, format];
      
      console.log('Manually checking divisions for formats:', updatedFormats);
      
      // Use availableDivisions first, but fall back to the backup if empty
      const divisionsToSearch = availableDivisions.length > 0 
        ? availableDivisions 
        : createDivisionObjects(tournament.divisions?.generatedDivisions || []);
      
      console.log('Divisions to search:', divisionsToSearch);
      
      // Find matching divisions with more precise matching including skill and rating
      const matches = divisionsToSearch.filter(division => {
        const divText = (division.label || division.value || '').toLowerCase();
        
        // Filter by skill category if selected
        if (formData.skill && !divText.toLowerCase().includes(formData.skill.toLowerCase())) {
          return false;
        }
        
        // Filter by rating if selected
        if (formData.rating) {
          const ratingMatch = divText.includes(formData.rating);
          if (!ratingMatch) {
            return false;
          }
        }
        
        // Filter by age if selected
        if (formData.age) {
          // Check common age formats in division names: (18-34), (35-49), (50-59), (60+)
          const ageRegex = /\((\d+)-(\d+|\+)\)/;
          const ageMatch = divText.match(ageRegex);
          
          if (ageMatch) {
            const minAge = parseInt(ageMatch[1], 10);
            const maxAgeStr = ageMatch[2];
            const maxAge = maxAgeStr === '+' ? 999 : parseInt(maxAgeStr, 10);
            const userAge = parseInt(formData.age, 10);
            
            if (isNaN(userAge) || userAge < minAge || userAge > maxAge) {
              return false; // Age doesn't match this division
            }
          } else if (!divText.includes(formData.age)) {
            // If we can't parse the age range, do a simple text match
            return false;
          }
        }
        
        // Check if division matches any of the selected formats
        return updatedFormats.some(gender => {
          const formatText = typeof gender === 'string' 
            ? gender.toLowerCase() 
            : (gender.name || gender.value || '').toLowerCase();
          
          console.log(`Testing "${divText}" against "${formatText}"`);
          
          // Men's singles - check with word boundaries
          if (formatText === "men's singles") {
            // Make sure it's men's singles, not women's singles
            if (divText.includes("men's single") && !divText.includes("women's")) {
              console.log(`✓ Men's singles match found for "${divText}"`);
              return true;
            }
            return false;
          }
          
          // Women's singles
          if (formatText === "women's singles") {
            if (divText.includes("women's single")) {
              console.log(`✓ Women's singles match found for "${divText}"`);
              return true;
            }
            return false;
          }
          
          // Men's doubles
          if (formatText === "men's doubles") {
            // Make sure it's men's doubles, not women's doubles
            if (divText.includes("men's double") && !divText.includes("women's") && !divText.includes("mixed")) {
              console.log(`✓ Men's doubles match found for "${divText}"`);
              return true;
            }
            return false;
          }
          
          // Women's doubles
          if (formatText === "women's doubles") {
            if (divText.includes("women's double") && !divText.includes("mixed")) {
              console.log(`✓ Women's doubles match found for "${divText}"`);
              return true;
            }
            return false;
          }
          
          // Mixed doubles
          if (formatText === "mixed doubles") {
            if (divText.includes("mixed double")) {
              console.log(`✓ Mixed doubles match found for "${divText}"`);
              return true;
            }
            return false;
          }
          
          return false;
        });
      }).map(div => div.value);
      
      console.log('Manually found matching divisions:', matches);
      
      // Only update if we found matches
      if (matches.length > 0) {
        setFormData(prev => ({
          ...prev,
          division: matches
        }));
        console.log('Updated form data divisions to:', matches);
      } else {
        console.log('No matching divisions found, not updating form');
      }
    }, 10);
  };

  // Add this after your existing useEffect hooks
  useEffect(() => {
    // This effect ensures we have divisions available even if availableDivisions is empty
    if (availableDivisions.length === 0 && 
        tournament.divisions?.generatedDivisions?.length > 0) {
      console.log('Loading divisions from tournament data');
      
      // Create proper division objects from the generatedDivisions strings
      const divisionObjects = createDivisionObjects(tournament.divisions.generatedDivisions);
      
      // Store these as a backup for division matching
      window.allTournamentDivisions = divisionObjects;
      
      console.log('Created division objects:', divisionObjects);
    }
  }, [availableDivisions, tournament.divisions?.generatedDivisions]);

  const prevFormDataRef = useRef(formData);

  useEffect(() => {
    // Only run the effect if specific parts of formData changed
    if (prevFormDataRef.current.gender !== formData.gender) {
      // Your effect code
    }
    prevFormDataRef.current = formData;
  }, [formData]);

  return (
    <div 
      className="tournament-registration-popover" 
      style={{
        top: `${position.top}px`, 
        left: `${position.left}px`
      }}
      ref={popoverRef}
    >
      <div className="popover-header">
        <h3>Register for {tournament.name}</h3>
        <button className="close-button" onClick={onClose}>×</button>
      </div>
      
      <form onSubmit={handleSubmit}>
        {/* Skill Category */}
        <SkillCategorySelect 
          value={formData.skill}
          onChange={handleChange}
          options={skillOptions}
        />

        {/* Age Group */}
        <AgeGroupSelect 
          value={formData.age}
          onChange={handleChange}
          options={ageOptions}
        />
        
        {/* Skill Rating */}
        <div className="form-group">
          <label htmlFor="rating">Skill Rating</label>
          <select 
            id="rating" 
            name="rating" 
            value={formData.rating} 
            onChange={handleChange}
            required
          >
            <option value="">Select Rating</option>
            {ratingOptions.map(rating => (
              <option key={rating} value={rating}>{rating}</option>
            ))}
          </select>
        </div>
        
        {/* Match Format (formerly Gender) */}
        <MatchFormatSelect 
          selected={formData.gender}
          options={genderOptions}
          onChange={handleMatchFormatChangeWithDivisions}  // Use enhanced handler
        />
        
        {/* Partner Information */}
        <PartnerInfoSection 
          genderFormats={formData.gender}
          partners={formData.partners}
          onChange={handleChange}
        />
        
        {/* Division Selection */}
        <DivisionSelect 
          selected={formData.division}
          options={availableDivisions}
          onChange={handleDivisionChange}
          genderFormats={formData.gender}
        />

        {/* Proposed Divisions Display - Integrated with Division Selection */}
        {generatedDivisions.length > 0 && availableDivisions.length === 0 && (
          <div className="proposed-divisions-section">
            <h4>Suggested Divisions</h4>
            <div className="proposed-divisions-list">
              {generatedDivisions.map(div => (
                <div 
                  key={div.id}
                  className={`proposed-division-item ${formData.division.includes(div.value) ? 'selected' : ''}`}
                  onClick={() => {
                    setFormData(prev => ({
                      ...prev,
                      division: [div.value]
                    }));
                  }}
                >
                  {div.name}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Form Actions */}
        <div className="form-actions">
          <button type="button" className="cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button type="submit" className="submit-button">
            Register
          </button>
        </div>
      </form>
    </div>
  );
};

export default TournamentRegistrationPopover;