import React from 'react';

const CheckboxField = ({
    fieldName,
    label,
    checked,
    onChange,
    error,
    inputRef,
    helpText
}) => {
    return (
        <div className="form-group checkbox-field">
            <input
                type="checkbox"
                id={fieldName}
                name={fieldName}
                checked={checked || false}
                onChange={(e) => onChange(e.target.checked)}
                className={error ? 'error' : ''}
                ref={inputRef}
            />
            <label htmlFor={fieldName}>{label}</label>

            {helpText && <p className="help-text">{helpText}</p>}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default CheckboxField;