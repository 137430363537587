/**
 * Utility functions for formatting and text processing
 */

/**
 * Returns the normalized text representation of a gender/match format
 * @param {string|object} gender - The gender/match format value
 * @returns {string} - Normalized lowercase text
 */
export const getGenderText = (gender) => {
  if (!gender) return '';
  return typeof gender === 'string' ? gender.toLowerCase() : 
         (gender.name || gender.value || '').toLowerCase();
};

/**
 * Cleans a label by removing any leading checkmarks
 * @param {string} label - The label to clean
 * @returns {string} - Cleaned label
 */
export const cleanLabel = (label = '') => {
  return label.replace(/^[✓✔☑]\s*/, '');
};