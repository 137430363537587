import React from 'react';
import '../../createtournament/components/FormActions.css';

const FormActions = ({
    isLastField, 
    navigateToNextField, 
    isSubmitting, 
    isEditMode,
    isPublished,
    isPublishingAction,
    onPublish,
    onUnpublish,
    clubId
}) => {
    return (
        <div className="form-actions">
            {!isLastField && (
                <button
                    type="button"
                    className="next-button"
                    onClick={navigateToNextField}
                >
                    Next
                </button>
            )}

            <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting}
            >
                {isSubmitting ? 'Saving...' : isEditMode ? 'Save Changes' : 'Create Club'}
            </button>

            {/* Only show publish/unpublish buttons in edit mode */}
            {isEditMode && clubId && (
                <>
                    {!isPublished ? (
                        <button
                            type="button"
                            className="publish-club-button"
                            onClick={onPublish}
                            disabled={isSubmitting || isPublishingAction}
                        >
                            {isPublishingAction ? 'Publishing...' : 'Publish'}
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="unpublish-club-button"
                            onClick={onUnpublish}
                            disabled={isSubmitting || isPublishingAction}
                        >
                            {isPublishingAction ? 'Unpublishing...' : 'Unpublish'}
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default FormActions;