import React, { useState, useRef, useCallback, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useDebounce } from '../hooks/useDebounce';
import { highlightMatch } from '../utils/searchUtils';

// Simplified version following VenueSearch pattern
const DivisionSearch = ({ onDivisionsChange, initialDivisions = [], tournamentId, isLoading }) => {
  // State for managing divisions
  const [divisions, setDivisions] = useState([]);
  const [activeDivisions, setActiveDivisions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [keyboardSelectedIndex, setKeyboardSelectedIndex] = useState(-1);
  
  // Refs
  const suggestionsRef = useRef(null);
  const suggestionItemsRef = useRef([]);
  const fuseRef = useRef(null);
  
  // Initialize Fuse with divisions when they change
  useEffect(() => {
    if (divisions.length > 0) {
      fuseRef.current = new Fuse(divisions, {
        keys: ['name'],
        threshold: 0.3,
        distance: 100
      });
    }
  }, [divisions]);
  
  // Modify the initialDivisions effect to prevent re-initialization
  useEffect(() => {
    if (initialDivisions && initialDivisions.length > 0 && divisions.length === 0) {
      // Only set divisions if they haven't been set before
      console.log('Setting divisions from initialDivisions:', initialDivisions);
      setDivisions(initialDivisions);
      // Do NOT set active divisions automatically
      // Let the user select which ones they want
    }
  }, [initialDivisions, divisions.length]);
  
  // eslint-disable-next-line no-unused-vars
  const debouncedSearchTerm = useDebounce(searchQuery, 300);
  
  // Handle search query change
  const handleSearch = useCallback((e) => {
    setSearchQuery(e.target.value);
    setShowSuggestions(true);
  }, []);
  
  // Get current search term
  const getCurrentSearchTerm = useCallback(() => {
    return searchQuery.trim();
  }, [searchQuery]);
  
  // Get filtered divisions based on search
  const getFilteredDivisions = useCallback(() => {
    const currentTerm = getCurrentSearchTerm();
    
    if (!fuseRef.current || divisions.length === 0) {
      return [];
    }
    
    // First filter out already selected divisions
    const availableDivisions = divisions.filter(division => 
      !activeDivisions.some(activeDivision => activeDivision.id === division.id)
    );
    
    console.log('Available divisions after filtering active ones:', availableDivisions.length);
    
    // If no search term, return all available divisions
    if (currentTerm === '') {
      return availableDivisions;
    }
    
    // Otherwise use Fuse to search
    return fuseRef.current.search(currentTerm)
      .map(result => result.item)
      .filter(division => 
        !activeDivisions.some(activeDivision => activeDivision.id === division.id)
      );
  }, [divisions, activeDivisions, getCurrentSearchTerm]);
  
  // Update handleDivisionClick to use did instead of id
  const handleDivisionClick = useCallback((division) => {
    if (!activeDivisions.some(d => d.id === division.id || d.id === division.did)) {
      console.log('Adding division to selection:', division.name);
      
      // Ensure the division uses did as the id property if available
      const divisionWithId = {
        ...division,
        // Preserve the original did
        did: division.did,
        // Use did as the primary id if available, otherwise generate from name
        id: division.did || division.id || division.name?.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]/g, '')
      };
      
      console.log('Division with standardized ID:', divisionWithId);
      
      // Replace previous selection entirely (single selection mode)
      const newActiveDivisions = [divisionWithId];
      
      setActiveDivisions(newActiveDivisions);
      
      // Notify parent component with the enhanced division object
      onDivisionsChange(newActiveDivisions);
      
      // Clear search input
      setSearchQuery('');
      setShowSuggestions(false);
    }
  }, [activeDivisions, onDivisionsChange]);
  
  // Fix removeDivision to properly update the activeDivisions state
  const removeDivision = useCallback((divisionId) => {
    console.log('Removing division:', divisionId);
    
    // Filter out the division with the given ID
    const newActiveDivisions = activeDivisions.filter(d => d.id !== divisionId);
    
    // Update active divisions state
    setActiveDivisions(newActiveDivisions);
    
    // Notify parent component of the change
    onDivisionsChange(newActiveDivisions);
    
    // This ensures we have the latest UI state
    console.log('Divisions remaining after removal:', newActiveDivisions.length);
  }, [activeDivisions, onDivisionsChange]);
  
  // Show suggestions on input focus
  const handleInputFocus = useCallback(() => {
    setShowSuggestions(true);
  }, []);
  
  // Close suggestions on outside click
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(e.target) && 
          !e.target.matches('.division-search-input')) {
        setShowSuggestions(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  
  // Handle keyboard navigation
  const handleKeyDown = useCallback((e) => {
    if (!showSuggestions) return;
    
    const suggestions = getFilteredDivisions();
    
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setKeyboardSelectedIndex(prev => 
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
        break;
        
      case 'ArrowUp':
        e.preventDefault();
        setKeyboardSelectedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
        
      case 'Enter':
        e.preventDefault();
        if (keyboardSelectedIndex >= 0 && keyboardSelectedIndex < suggestions.length) {
          handleDivisionClick(suggestions[keyboardSelectedIndex]);
        }
        break;
        
      case 'Escape':
        e.preventDefault();
        setShowSuggestions(false);
        break;
        
      default:
        break;
    }
  }, [showSuggestions, keyboardSelectedIndex, getFilteredDivisions, handleDivisionClick]);
  
  // Make sure the division-tag structure is correct for the button to work
  return (
    <div className="division-search">
      {isLoading ? (
        <p>Loading tournament divisions...</p>
      ) : (
        <div className="divisions-nav">
          <h3>Divisions</h3>
          
          <div className="division-search-container">
            <input 
              className="division-search-input"
              type="text" 
              placeholder="Search divisions..." 
              value={searchQuery}
              onChange={handleSearch}
              onFocus={handleInputFocus}
              onKeyDown={handleKeyDown}
            />
          </div>
          
          <div className="active-divisions">
            {activeDivisions.map(division => (
              <div key={division.id} className="division-tag">
                <span className="division-name">{division.name}</span>
                <button 
                  type="button" 
                  className="remove-division"
                  onClick={() => removeDivision(division.id)}
                  aria-label={`Remove ${division.name}`}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
          
          {showSuggestions && (
            <div className="division-suggestions" ref={suggestionsRef}>
              {getFilteredDivisions().length > 0 ? (
                <ul className="division-list">
                  {getFilteredDivisions().map((division, index) => (
                    <li 
                      key={division.id}
                      ref={el => suggestionItemsRef.current[index] = el}
                      className={`division-item ${keyboardSelectedIndex === index ? 'keyboard-selected' : ''}`}
                      onClick={() => handleDivisionClick(division)}
                    >
                      {highlightMatch(division.name, getCurrentSearchTerm())}
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="no-suggestions">No matching divisions found</div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DivisionSearch;