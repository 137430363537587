import React, { useEffect, useState } from 'react';
import './TournamentOperatorDetails.css';

const TournamentOperatorDetails = ({ value = {}, onChange, field }) => {
  // Add state to track changes
  const [localValue, setLocalValue] = useState(value);
  
  // Update local state when props change
  useEffect(() => {
    console.log('TournamentOperatorDetails received value:', value);
    setLocalValue(value);
  }, [value]);

  // Ensure value is a valid object
  const safeValue = typeof localValue === 'object' && localValue !== null ? localValue : {};

  // Handle changes for each field
  const handleChange = (fieldName, fieldValue) => {
    const updatedValue = {
      ...safeValue,
      [fieldName]: fieldValue
    };
    
    setLocalValue(updatedValue);
    onChange(updatedValue);
    
    console.log(`Changed ${fieldName} to:`, fieldValue);
    console.log('New operator details value:', updatedValue);
  };

  return (
    <div className="operator-details-container">
      <h3>Tournament Operator Details</h3>
      
      <div className="form-group">
        <label htmlFor="runningOrganization">Running Organization</label>
        <select
          id="runningOrganization"
          value={safeValue.runningOrganization || ''}
          onChange={(e) => handleChange('runningOrganization', e.target.value)}
          className="form-control"
        >
          <option value="">Select Organization</option>
          {field.options?.runningOrganization?.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
        <small>Current value: "{safeValue.runningOrganization || ''}"</small>
      </div>
      
      <div className="form-group">
        <label htmlFor="tournamentType">Tournament Type</label>
        <select
          id="tournamentType"
          value={safeValue.tournamentType || ''}
          onChange={(e) => handleChange('tournamentType', e.target.value)}
          className="form-control"
        >
          <option value="">Select Type</option>
          {field.options?.tournamentType?.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
        <small>Current value: "{safeValue.tournamentType || ''}"</small>
      </div>
      
      <div className="form-check">
        <input
          id="isUSAPGoldenTicket"
          type="checkbox"
          checked={!!safeValue.isUSAPGoldenTicket}
          onChange={(e) => handleChange('isUSAPGoldenTicket', e.target.checked)}
          className="form-check-input"
        />
        <label className="form-check-label" htmlFor="isUSAPGoldenTicket">
          USA Pickleball Golden Ticket
        </label>
        <small>Current value: {safeValue.isUSAPGoldenTicket ? 'true' : 'false'}</small>
      </div>
      
    </div>
  );
};

export default TournamentOperatorDetails;