import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import './RichTextEditor.css'; // Custom styling

const RichTextEditor = ({
    fieldName,
    label,
    value,
    onChange,
    required,
    error,
    helpText
}) => {
    // Define toolbar options
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link'],
            ['clean']
        ]
    };

    // Define formats that should be available
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'link'
    ];

    return (
        <div className="form-group">
            <div className="input-container rich-text-wrapper">
                <ReactQuill
                    id={fieldName}
                    theme="snow"
                    value={value || ''}
                    onChange={onChange}
                    modules={modules}
                    formats={formats}
                    className={`scrollable-editor ${error ? 'error' : ''}`}
                />
                {helpText && <p className="help-text">{helpText}</p>}
                {error && <div className="error-message">{error}</div>}
            </div>
        </div>
    );
};

export default RichTextEditor;