import React from 'react';

const MultiSelectField = ({
    fieldName,
    label,
    value = [],
    options = [],
    onChange,
    error,
    inputRef,
    helpText
}) => {
    const handleOptionToggle = (option) => {
        if (value.includes(option)) {
            // Remove option if already selected
            onChange(value.filter(item => item !== option));
        } else {
            // Add option if not selected
            onChange([...value, option]);
        }
    };

    return (
        <div className="form-group">
            <label>{label}</label>
            <div 
                className={`multi-select-container ${error ? 'error' : ''}`}
                ref={inputRef}
            >
                {options.map((option, index) => (
                    <div key={index} className="multi-select-option">
                        <label>
                            <input
                                type="checkbox"
                                name={`${fieldName}[${index}]`}
                                checked={value.includes(option)}
                                onChange={() => handleOptionToggle(option)}
                            />
                            <span className="option-label">{option}</span>
                        </label>
                    </div>
                ))}
            </div>
            {helpText && <p className="help-text">{helpText}</p>}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default MultiSelectField;