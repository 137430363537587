export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Validates form data against form configuration
 * @param {Object} formData - The form data to validate
 * @param {Object} formConfig - The form configuration
 * @returns {Object} Object containing validation errors
 */
export const validateForm = (formData, formConfig) => {
  const errors = {};

  // Validate each field based on its configuration
  Object.keys(formConfig).forEach(fieldName => {
    const field = formConfig[fieldName];
    const value = formData[fieldName];

    // Check required fields
    if (field.required &&
      (value === undefined ||
        value === null ||
        value === '' ||
        (Array.isArray(value) && value.length === 0))) {
      errors[fieldName] = `${field.label} is required`;
    }

    // For group fields (like address)
    if (field.type === 'group' && field.required) {
      Object.keys(field.fields).forEach(subFieldName => {
        const subField = field.fields[subFieldName];
        const subValue = formData[fieldName]?.[subFieldName];

        if (subField.required && (!subValue || subValue.trim() === '')) {
          errors[`${fieldName}.${subFieldName}`] = `${subField.label} is required`;
        }
      });
    }

    // Add other validations as needed (format, min/max length, etc.)
  });

  return errors;
};