import React from 'react';
import './FormActions.css';

const FormActions = ({ 
    isLastField, 
    navigateToNextField, 
    isSubmitting, 
    isEditMode,
    tournamentId 
}) => {
    return (
        <div className="form-actions">
            {!isLastField && (
                <button
                    type="button"
                    className="next-button"
                    onClick={navigateToNextField}
                    disabled={isSubmitting}
                >
                    Next Field
                </button>
            )}
            <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting}
            >
                {isSubmitting ? 'Saving...' : isEditMode ? 'Update Tournament' : 'Create Tournament'}
            </button>
            {isEditMode && (
                <a 
                    href={`/tournament/${tournamentId}`} 
                    className="view-button" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    View Tournament
                </a>
            )}
        </div>
    );
};

export default FormActions;