export const formConfig = {
    name: {
        type: "text",
        label: "Tournament Name",
        required: true,
        helpText: "Official name of the tournament",
        navigation: {
            showNext: true,
            showSave: false,
            showCreate: false,
            showPublish: false
        }
    },

    description: {
        type: "richText", // Change from "textarea" to "richText"
        label: "Description",
        helpText: "Describe the tournament details and format",
        navigation: {
            showNext: true,
            showSave: false,
            showCreate: false,
            showPublish: false
        }
    },

    importantDates: {
        type: "group",
        label: "Important Dates",
        fields: {
            startDate: {
                type: "date",
                label: "Start Date",
                helpText: "Select the tournament start date"
            },
            endDate: {
                type: "date",
                label: "End Date",
                helpText: "Select the tournament end date"
            },
            openRegistrationDate: {
                type: "date",
                label: "Open Registration Date",
                helpText: "Select the date when registration opens"
            },
            closeRegistrationDate: {
                type: "date",
                label: "Close Registration Date",
                helpText: "Select the date when registration closes"
            },
            registrationPublicDate: {
                type: "date",
                label: "Public Registration Date",
                helpText: "Select the date when registration becomes public"
            }
        },
        helpText: "Key dates for tournament registration and scheduling",
        navigation: {
            showNext: true,
            showSave: false,
            showCreate: false,
            showPublish: false
        }
    },

    operatorDetails: {
        type: "custom",  // Change from "group" to "custom"
        component: "TournamentOperatorDetails",  // Specify the component name
        label: "Tournament Operator Details",
        helpText: "Provide details about the tournament organization and type",
        options: {  // Include options that the component will use
            runningOrganization: [
                "",
                "USA Pickleball (USAP)",
                "Professional Pickleball Association (PPA)",
                "Association of Pickleball Players (APP Tour)",
                "Major League Pickleball (MLP)",
                "National Pickleball League (NPL)"
            ],
            tournamentType: [
                "",
                "USAP Sanctioned Tournament",
                "National Championship",
                "Regional Championship",
                "Professional Event",
                "National Indoor Championship",
                "Select Series",
                "Pro Tournament",
                "Amateur Event",
                "Master Event",
                "Junior Event",
                "Open Event",
                "State Games & Senior Games",
                "Junior Nationals",
                "Diamond Amateur Championship"
            ]
        },
        navigation: {
            showNext: true,
            showSave: false,
            showCreate: false,
            showPublish: false
        }
    },

    venues: {
        type: "array",
        label: "Venues",
        itemFields: {
            name: {
                type: "text",
                label: "Venue Name",
                required: true,
                helpText: "Name of the venue"
            },
            address: {
                type: "group",
                label: "Venue Address",
                fields: {
                    addressLine1: {
                        type: "text",
                        label: "Address Line 1",
                        required: true
                    },
                    addressLine2: {
                        type: "text",
                        label: "Address Line 2"
                    },
                    city: {
                        type: "text",
                        label: "City",
                        required: true
                    },
                    state: {
                        type: "text",
                        label: "State/Province",
                        required: true
                    },
                    zipCode: {
                        type: "text",
                        label: "ZIP/Postal Code",
                        required: true
                    },
                    country: {
                        type: "text",
                        label: "Country",
                        required: true,
                        defaultValue: "United States"
                    }
                }
            },
            numberOfCourts: {
                type: "number",
                label: "Number of Courts",
                required: true
            }
        },
        helpText: "Enter details for each tournament venue",
        navigation: {
            showNext: true,
            showSave: false,
            showCreate: false,
            showPublish: false
        }
    },

    divisions: {
        type: "custom",
        label: "Divisions",
        component: "DivisionSelector",
        helpText: "Configure division categories and settings",
        fields: {
            skillCategory: {
                type: "multiselect",
                label: "Skill Category",
                options: ["Amateur", "Pro", "Senior", "Master", "Junior", "Collegiate", "Accessible"],
                helpText: "Select one or more skill categories"
            },
            divisionGenderCategory: {
                type: "multiselect",
                label: "Gender Category",
                options: ["Men's Singles", "Men's Doubles", "Women's Singles", "Women's Doubles", "Mixed Doubles"],
                helpText: "Select one or more division gender categories"
            },
            ratingsRange: {
                type: "multiselect",
                label: "Ratings Range",
                options: ["3.0", "3.5", "4.0", "4.5", "5+"],
                helpText: "Select one or more ratings ranges"
            },
            ageRanges: {
                type: "hierarchical",
                label: "Age Ranges",
                parentField: "skillCategory",
                childOptions: {
                    "Amateur": ["19-29", "30-39", "40-49", "50-54", "55-59", "60-64", "70-74", "75-79", "80+"],
                    "Pro": ["19-29", "30-39", "40-49", "50-54", "55-59", "60-64", "70-74", "75-79", "80+"],
                    "Senior": ["50-54", "55-59", "60-64", "70-74", "75-79", "80+"],
                    "Master": ["60-64", "70-74", "75-79", "80+"],
                    "Junior": ["12U", "14U", "16U", "18U"],
                    "Collegiate": ["18-25"],
                    "Accessible": ["All ages"]
                },
                helpText: "Select age ranges for each skill category"
            }
        },
        navigation: {
            showNext: true,
            showSave: false,
            showCreate: false,
            showPublish: false
        }
    },

    minimumPlayersPerDivision: {
        type: "number",
        label: "Minimum Players Per Division",
        helpText: "Specify the minimum number of players required per division",
        navigation: {
            showNext: true,
            showSave: false,
            showCreate: false,
            showPublish: false
        }
    },

    maximumPlayersPerDivision: {
        type: "number",
        label: "Maximum Players Per Division",
        helpText: "Specify the maximum number of players allowed per division",
        navigation: {
            showNext: true,
            showSave: false,
            showCreate: false,
            showPublish: false
        }
    },

    communication: {
        type: "custom", // Change to custom component
        component: "CommunicationSettings", // Use a custom component
        label: "Communication Settings",
        helpText: "Configure tournament communication preferences",
        navigation: {
            showNext: true,
            showSave: false,
            showCreate: false,
            showPublish: false
        }
    },

    divisionSettings: {
        type: "custom",
        component: "DivisionConfigSelector",
        label: "Division Settings",
        helpText: "Configure format and settings for each division",
        navigation: {
            showNext: true,
            showSave: false,
            showCreate: false,
            showPublish: false
        }
    },

    logos: {
        type: "custom",
        label: "Tournament Logos",
        component: "TournamentLogos",
        helpText: "Upload logos for the tournament (sponsors, organizers, etc.)",
        navigation: {
            showNext: false,
            showSave: true,
            showCreate: true,
            showPublish: true
        }
    }
};

// Helper function to get field names for navigation
export const getFieldNames = () => Object.keys(formConfig);

// Helper function to determine which buttons to show for a given step
export const getNavigationConfig = (fieldName) => {
    const field = formConfig[fieldName];
    return field.navigation || {
        showNext: true,
        showSave: false,
        showCreate: false,
        showPublish: false
    };
};
