import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateClub.css';

// Import form config
import { formConfig } from './config/formConfig';

// Import API utilities
import {
  fetchClub,
  createClub,
  updateClub,
  transformClubDataToFormData,
  parseFileUrls,
  createEmptyFormData,  // Add this line - make sure it's exported from api.js
  publishClub,
  unpublishClub
} from './utils/api';

// Import validation utilities
import { validateForm } from './utils/validation';  // Uncomment this line

import FormActions from './components/FormActions';

const CreateClub = () => {
  const navigate = useNavigate();
  const { clubId } = useParams(); // Get clubId from URL if present
  const isEditMode = Boolean(clubId);
  const fieldNames = Object.keys(formConfig);
  const [currentField, setCurrentField] = useState(fieldNames[0]);
  const inputRefs = useRef({});

  // Form state
  const [formData, setFormData] = useState(createEmptyFormData());

  // Add this state near your other state declarations
  const [filePreviewUrls, setFilePreviewUrls] = useState({
    clubLogos: [],
    waiver: [] // Keep as array for UI consistency
  });

  // UI state
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(isEditMode);

  // For storing URLs of existing files
  const [existingFiles, setExistingFiles] = useState({
    clubLogos: [],
    waiver: null // Not an empty array
  });

  const [isPublished, setIsPublished] = useState(false);
  const [isPublishingAction, setIsPublishingAction] = useState(false);

  // Check if user is logged in
  useEffect(() => {
    const userId = sessionStorage.getItem('userId');
    if (!userId) {
      navigate('/signin?returnTo=' + (isEditMode ? `/editclub/${clubId}` : '/createclub'));
    }
  }, [navigate, clubId, isEditMode]);

  // Focus the current field when it changes
  useEffect(() => {
    const currentInputRef = inputRefs.current[currentField];
    if (currentInputRef && typeof currentInputRef.focus === 'function') {
      setTimeout(() => {
        currentInputRef.focus();
      }, 100);
    }
  }, [currentField]);

  // Scroll the selected navigation item into view when it changes
  useEffect(() => {
    const selectedNavItem = document.querySelector('.field-nav-item.active');
    if (selectedNavItem) {
      selectedNavItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [currentField]);

  // Fetch club data if in edit mode
  useEffect(() => {
    if (isEditMode) {
      let isMounted = true;

      const loadClubData = async () => {
        setIsLoading(true);
        try {
          const clubData = await fetchClub(clubId);
          console.log('Club data fetched:', clubData); // Debug log

          if (isMounted) {
            // Get the transformed data
            const transformedData = transformClubDataToFormData(clubData);
            console.log('Transformed form data:', transformedData); // Debug log

            // Set the form data directly (no spreading/merging needed)
            setFormData(transformedData);

            // Set existing files
            setExistingFiles(parseFileUrls(clubData));

            setIsPublished(clubData.status === "Active" || clubData.status === "Published");

            setIsLoading(false);
          }
        } catch (error) {
          if (isMounted) {
            console.error('Error fetching club data:', error);
            setGeneralError('Failed to load club data. ' + error.message);
            setIsLoading(false);
          }
        }
      };

      loadClubData();

      return () => {
        isMounted = false;
      };
    }
  }, [clubId, isEditMode]);

  // Handle text, select, and checkbox inputs
  const handleInputChange = (fieldName, value) => {
    if (formConfig[fieldName]?.type === 'group') {
      return; // Groups are handled separately
    }

    setFormData(prev => ({
      ...prev,
      [fieldName]: value
    }));

    // Clear errors when field is updated
    if (errors[fieldName]) {
      setErrors(prev => ({
        ...prev,
        [fieldName]: ''
      }));
    }
  };

  // Handle address fields
  const handleGroupInputChange = (groupName, fieldName, value) => {
    setFormData(prev => ({
      ...prev,
      [groupName]: {
        ...prev[groupName],
        [fieldName]: value
      }
    }));

    // Clear errors when field is updated
    if (errors[`${groupName}.${fieldName}`]) {
      setErrors(prev => ({
        ...prev,
        [`${groupName}.${fieldName}`]: ''
      }));
    }
  };

  // Handle file uploads
const handleFileChange = (fieldName, files) => {
  if (!files || !files.length) return;

  // Process each uploaded file
  const newFiles = Array.from(files).map(file => {
    // Create object URL for preview
    const fileUrl = URL.createObjectURL(file);
    
    // Store the URL for preview and cleanup later
    setFilePreviewUrls(prev => ({
      ...prev,
      [fieldName]: [...(prev[fieldName] || []), fileUrl]
    }));
    
    // Return file object with added properties
    return {
      file: file, // The actual File object for upload
      name: file.name,
      type: file.type,
      size: file.size,
      // We don't save dataUrl here as it would be too large for state,
      // instead we use URL.createObjectURL for previews
    };
  });

  // Update form data with new files - handle single vs multiple files
  setFormData(prev => {
    if (fieldName === 'waiver') {
      // Waiver is a single file
      return {
        ...prev,
        [fieldName]: newFiles[0] // Just take the first file
      };
    } else {
      // Multiple files (e.g., for clubLogos)
      return {
        ...prev,
        [fieldName]: [...(prev[fieldName] || []), ...newFiles]
      };
    }
  });

  console.log(`Added ${newFiles.length} files to ${fieldName}`);
};

  // Handle file removal
  const handleRemoveFile = (fieldName, index) => {
    // Revoke the URL object to prevent memory leaks
    if (filePreviewUrls[fieldName] && filePreviewUrls[fieldName][index]) {
      URL.revokeObjectURL(filePreviewUrls[fieldName][index]);
    }

    setFormData(prev => {
      const updatedFiles = Array.isArray(prev[fieldName]) ? [...prev[fieldName]] : [];
      if (updatedFiles.length > index) {
        updatedFiles.splice(index, 1);
      }
      return {
        ...prev,
        [fieldName]: updatedFiles
      };
    });

    setFilePreviewUrls(prev => {
      const updatedUrls = Array.isArray(prev[fieldName]) ? [...prev[fieldName]] : [];
      if (updatedUrls.length > index) {
        updatedUrls.splice(index, 1);
      }
      return {
        ...prev,
        [fieldName]: updatedUrls
      };
    });
  };

  // Handle existing file removal
  const handleRemoveExistingFile = (fieldName, index) => {
    setExistingFiles(prev => ({
      ...prev,
      [fieldName]: prev[fieldName].filter((_, i) => i !== index)
    }));
  };

  // Handle Enter key presses for navigation only
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default form submission

      // If it's a textarea and the user didn't press Shift+Enter, don't navigate
      if (e.target.tagName.toLowerCase() === 'textarea' && !e.shiftKey) {
        return;
      }

      const currentIndex = fieldNames.indexOf(currentField);

      // Navigate to next field, never submit on Enter
      if (currentIndex < fieldNames.length - 1) {
        setCurrentField(fieldNames[currentIndex + 1]);
      }
    }
  };

  // Navigate to the next field
  const navigateToNextField = () => {
    const currentIndex = fieldNames.indexOf(currentField);
    if (currentIndex < fieldNames.length - 1) {
      setCurrentField(fieldNames[currentIndex + 1]);
    }
  };

  // Replace your handleSubmit function with this:

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);
  setGeneralError('');

  // Validate form
  const formErrors = validateForm(formData, formConfig);
  if (Object.keys(formErrors).length > 0) {
    setErrors(formErrors);
    setIsSubmitting(false);
    return;
  }

  try {
    // Create a FormData object for multipart submission
    const submitFormData = new FormData();
    
    // Create a copy of form data to convert to JSON, excluding files
    const clubDataCopy = { ...formData };
    
    // Remove file fields - we'll add them separately to the FormData
    delete clubDataCopy.clubLogos;
    delete clubDataCopy.waiver;
    
    // Add information about existing files that were kept
    clubDataCopy.existingLogos = existingFiles.clubLogos || [];
    clubDataCopy.existingWaiver = existingFiles.waiver || null;
    
    // Add clubData as JSON string
    submitFormData.append('clubData', JSON.stringify(clubDataCopy));
    console.log('Club data added as JSON:', JSON.stringify(clubDataCopy));
    
    // Add each club logo file
    if (formData.clubLogos && Array.isArray(formData.clubLogos)) {
      formData.clubLogos.forEach((logoItem, index) => {
        if (logoItem && logoItem.file instanceof File) {
          submitFormData.append('logo', logoItem.file);
          console.log(`Adding logo file ${index}:`, logoItem.file.name);
        }
      });
    }
    
    // Add waiver file if it exists
    if (formData.waiver && formData.waiver.file instanceof File) {
      submitFormData.append('waiver', formData.waiver.file);
      console.log(`Adding waiver file:`, formData.waiver.file.name);
    }
    
    // Check what's in the FormData (for debugging)
    console.log('FormData entries:');
    for (let [key, value] of submitFormData.entries()) {
      if (value instanceof File) {
        console.log(`- ${key}: ${value.name} (${value.type}, ${value.size} bytes)`);
      } else {
        console.log(`- ${key}: [data]`);
      }
    }
    
    // Send the request using the API functions
    if (isEditMode) {
      await updateClub(clubId, submitFormData);
      alert('Club updated successfully!');
    } else {
      await createClub(submitFormData);
      alert('Club created successfully!');
    }
    
    // Navigate back to clubs list
    navigate('/clubs');
  } catch (error) {
    console.error('Error saving club:', error);
    setGeneralError(`Failed to ${isEditMode ? 'update' : 'create'} club: ${error.message}`);
  } finally {
    setIsSubmitting(false);
  }
};

  // Update the getValuePreview function to always return a value
  const getValuePreview = (fieldName) => {
    const field = formConfig[fieldName];
    if (!field) return ""; // Add safety check for missing field config

    const value = formData[fieldName];

    if (!value || (Array.isArray(value) && value.length === 0)) {
      return "";
    }

    if (field.type === 'group') {
      // For address, show all filled fields
      if (fieldName === 'address') {
        // Safety check - ensure address object exists
        const address = formData.address || {};
        const addressParts = [];

        if (address.street) addressParts.push(address.street);
        if (address.street2) addressParts.push(address.street2);
        if (address.city) addressParts.push(address.city);
        if (address.state) addressParts.push(address.state);
        if (address.zipCode) addressParts.push(address.zipCode);
        if (address.country) addressParts.push(address.country);

        if (addressParts.length === 0) return "";

        // Return address as a JSX element with line breaks
        return (
          <div className="field-nav-multiline">
            {addressParts.map((part, index) => (
              <div key={index} className="field-nav-multiline-item">{part}</div>
            ))}
          </div>
        );
      }
      return ""; // Ensure we return empty string for other group types
    }

    // Rest of your existing logic...

    return String(value || ""); // Ensure we always return a string
  };

  const renderField = (fieldName) => {
    const field = formConfig[fieldName];

    if (!field) return null;

    // Keep this block which actually uses the addressValue
    if (field.type === 'group') {
      // Debug log to see values being rendered
      console.log('Rendering address with values:', formData[fieldName]);

      // Ensure address always exists, even if it's temporarily undefined
      const addressValue = formData[fieldName] || {
        street: "",
        street2: "",
        city: "",
        state: "",
        zipCode: "",
        country: ""
      };

      return (
        <div className="field-editor group-editor">
          <h3>{field.label}</h3>
          {Object.keys(field.fields).map(subFieldName => (
            <div key={subFieldName} className="form-group">
              <label>{field.fields[subFieldName].label}</label>
              <input
                type="text"
                className={`form-control ${errors[`${fieldName}.${subFieldName}`] ? 'error' : ''}`}
                value={addressValue[subFieldName] || ''}
                onChange={(e) => handleGroupInputChange(fieldName, subFieldName, e.target.value)}
                ref={el => {
                  if (subFieldName === 'street') {
                    inputRefs.current[fieldName] = el;
                  }
                }}
                onKeyDown={(e) => {
                  // Same key handling as before
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    // Find the next field in this group
                    const subFields = Object.keys(field.fields);
                    const currentSubIndex = subFields.indexOf(subFieldName);

                    if (currentSubIndex < subFields.length - 1) {
                      // Move to the next field in this group
                      const nextSubField = subFields[currentSubIndex + 1];
                      // Focus the next field
                      document.querySelector(`input[name="${fieldName}.${nextSubField}"]`)?.focus();
                    } else {
                      // Move to the next main field
                      const currentIndex = fieldNames.indexOf(currentField);
                      if (currentIndex < fieldNames.length - 1) {
                        setCurrentField(fieldNames[currentIndex + 1]);
                      }
                    }
                  }
                }}
                name={`${fieldName}.${subFieldName}`}
              />
              {errors[`${fieldName}.${subFieldName}`] && (
                <div className="error-message">{errors[`${fieldName}.${subFieldName}`]}</div>
              )}
            </div>
          ))}
        </div>
      );
    }

    switch (field.type) {
      case 'text':
        return (
          <div className="field-editor">
            <div className="form-group">
              <label htmlFor={fieldName}>{field.label}</label>
              <input
                type="text"
                id={fieldName}
                className={`form-control ${errors[fieldName] ? 'error' : ''}`}
                value={formData[fieldName] || ''}
                onChange={(e) => handleInputChange(fieldName, e.target.value)}
                ref={el => inputRefs.current[fieldName] = el}
                onKeyDown={handleKeyDown} // Add this line
              />
              {errors[fieldName] && (
                <div className="error-message">{errors[fieldName]}</div>
              )}
            </div>
          </div>
        );

      case 'textarea':
        return (
          <div className="field-editor">
            <div className="form-group">
              <label htmlFor={fieldName}>{field.label}</label>
              <textarea
                id={fieldName}
                className={`form-control ${errors[fieldName] ? 'error' : ''}`}
                value={formData[fieldName] || ''}
                onChange={(e) => handleInputChange(fieldName, e.target.value)}
                rows={8}
                ref={el => inputRefs.current[fieldName] = el}
                onKeyDown={handleKeyDown} // Add this line
              />
              {errors[fieldName] && (
                <div className="error-message">{errors[fieldName]}</div>
              )}
            </div>
          </div>
        );

      case 'select':
        return (
          <div className="field-editor">
            <div className="form-group">
              <label htmlFor={fieldName}>{field.label}</label>
              <select
                id={fieldName}
                className={`form-control ${errors[fieldName] ? 'error' : ''}`}
                value={formData[fieldName] || ''}
                onChange={(e) => handleInputChange(fieldName, e.target.value)}
                ref={el => inputRefs.current[fieldName] = el}
                onKeyDown={handleKeyDown} // Add this line
              >
                <option value="">Select {field.label}</option>
                {field.options.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              {errors[fieldName] && (
                <div className="error-message">{errors[fieldName]}</div>
              )}
            </div>
          </div>
        );

      case 'checkbox':
        return (
          <div className="field-editor">
            <div className="form-group checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  id={fieldName}
                  checked={Boolean(formData[fieldName])}
                  onChange={(e) => handleInputChange(fieldName, e.target.checked)}
                  ref={el => inputRefs.current[fieldName] = el}
                  onKeyDown={handleKeyDown} // Add this line
                />
                {field.label}
              </label>
              {errors[fieldName] && (
                <div className="error-message">{errors[fieldName]}</div>
              )}
            </div>
          </div>
        );

      case 'file':
        return (
          <div className="field-editor">
            <div className="form-group">
              <label htmlFor={fieldName}>{field.label}</label>
              <input
                type="file"
                id={fieldName}
                accept={field.accept || '*'}
                className={`form-control ${errors[fieldName] ? 'error' : ''}`}
                onChange={(e) => handleFileChange(fieldName, e.target.files)}
                multiple={fieldName === 'waiver' ? false : !!field.multiple} // Only clubLogos should be multiple
                ref={el => inputRefs.current[fieldName] = el}
                onKeyDown={handleKeyDown}
              />

              {/* For waiver, show single file differently */}
              {fieldName === 'waiver' && formData.waiver && (
                <div className="file-list-container">
                  <h4>New File</h4>
                  <div className="file-grid">
                    <div className="file-item">
                      <div className="file-name">{formData.waiver.name || 'Unnamed file'}</div>
                      <button
                        type="button"
                        className="remove-file"
                        onClick={() => {
                          setFormData(prev => ({
                            ...prev,
                            waiver: null
                          }));

                          // Clean up URL
                          if (filePreviewUrls.waiver && filePreviewUrls.waiver[0]) {
                            URL.revokeObjectURL(filePreviewUrls.waiver[0]);
                          }
                          setFilePreviewUrls(prev => ({
                            ...prev,
                            waiver: []
                          }));
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Continue with existing files display for both fields */}
              {existingFiles && existingFiles[fieldName] && (
                fieldName === 'waiver' ?
                  (existingFiles.waiver && (
                    <div className="file-list-container">
                      <h4>Existing File</h4>
                      <div className="file-grid">
                        <div className="file-item">
                          <div className="file-name">
                            {/* Add type checking before using split */}
                            {typeof existingFiles.waiver === 'string'
                              ? existingFiles.waiver.split('/').pop()
                              : (existingFiles.waiver.name || 'Waiver file')}
                          </div>
                          <button
                            type="button"
                            className="remove-file"
                            onClick={() => setExistingFiles(prev => ({
                              ...prev,
                              waiver: null
                            }))}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  )) :
                  (Array.isArray(existingFiles[fieldName]) && existingFiles[fieldName].length > 0 && (
                    <div className="file-list-container">
                      <h4>Existing Files</h4>
                      <div className="file-grid">
                        {existingFiles[fieldName].map((url, index) => (
                          url ? (
                            <div key={index} className="file-item">
                              {field.accept === "image/*" ? (
                                <div className="file-preview">
                                  <img
                                    src={url}
                                    alt={`${fieldName} preview ${index}`}
                                    className="file-image-preview"
                                  />
                                </div>
                              ) : (
                                <div className="file-name">{url.split('/').pop()}</div>
                              )}
                              <button
                                type="button"
                                className="remove-file"
                                onClick={() => handleRemoveExistingFile(fieldName, index)}
                              >
                                Remove
                              </button>
                            </div>
                          ) : null
                        ))}
                      </div>
                    </div>
                  ))
              )}

              {/* Display newly uploaded clubLogos with thumbnails */}
              {fieldName === 'clubLogos' && formData.clubLogos && Array.isArray(formData.clubLogos) &&
                formData.clubLogos.length > 0 && (
                  <div className="file-list-container">
                    <h4>New Images</h4>
                    <div className="file-grid">
                      {formData.clubLogos.map((file, index) => (
                        file ? (
                          <div key={index} className="file-item">
                            {filePreviewUrls.clubLogos && filePreviewUrls.clubLogos[index] && (
                              <div className="file-preview">
                                <img
                                  src={filePreviewUrls.clubLogos[index]}
                                  alt={`${fieldName} preview ${index}`}
                                  className="file-image-preview"
                                />
                              </div>
                            )}
                            <div className="file-name">{file.name || 'Unnamed file'}</div>
                            <button
                              type="button"
                              className="remove-file"
                              onClick={() => handleRemoveFile(fieldName, index)}
                            >
                              Remove
                            </button>
                          </div>
                        ) : null
                      ))}
                    </div>
                  </div>
                )}

              {errors[fieldName] && (
                <div className="error-message">{errors[fieldName]}</div>
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const isLastField = currentField === fieldNames[fieldNames.length - 1];

  // Add cleanup effect
  useEffect(() => {
    return () => {
      // Cleanup all created object URLs when component unmounts
      Object.values(filePreviewUrls).forEach(urlArray => {
        if (urlArray) {
          urlArray.forEach(url => {
            if (url) URL.revokeObjectURL(url);
          });
        }
      });
    };
  }, [filePreviewUrls]);

  const handlePublish = async () => {
    setIsPublishingAction(true);
    try {
      await publishClub(clubId);
      setIsPublished(true);
      // Update the status in the form data
      setFormData(prev => ({
        ...prev,
        status: "Active"
      }));
      alert('Club published successfully!');
    } catch (error) {
      setGeneralError(`Failed to publish club: ${error.message}`);
    } finally {
      setIsPublishingAction(false);
    }
  };

  const handleUnpublish = async () => {
    setIsPublishingAction(true);
    try {
      await unpublishClub(clubId);
      setIsPublished(false);
      // Update the status in the form data
      setFormData(prev => ({
        ...prev,
        status: "Inactive"
      }));
      alert('Club unpublished successfully!');
    } catch (error) {
      setGeneralError(`Failed to unpublish club: ${error.message}`);
    } finally {
      setIsPublishingAction(false);
    }
  };

  return (
    <div className="create-club-container">
      <h1 className="page-title">{isEditMode ? 'Edit Club' : 'Create New Club'}</h1>

      {generalError && (
        <div className="general-error">
          {generalError}
        </div>
      )}

      {isLoading ? (
        <div className="loading-indicator">
          <p>Loading club data...</p>
        </div>
      ) : (
        <div className="club-editor">
          <div className="field-navigation">
            <h3>Club Profile</h3>
            {fieldNames.map(fieldName => (
              <div
                key={fieldName}
                className={`field-nav-item ${currentField === fieldName ? 'active' : ''}`}
                onClick={() => setCurrentField(fieldName)}
              >
                <span className="field-nav-label">
                  {formConfig[fieldName]?.label || fieldName}
                </span>
                <span className="field-nav-value">
                  {getValuePreview(fieldName)}
                </span>
              </div>
            ))}
          </div>

          <div className="field-content">
            {/* Update this form to use handleSubmit */}
            <form onSubmit={handleSubmit}>
              {renderField(currentField)}

              <FormActions
                isLastField={isLastField}
                navigateToNextField={navigateToNextField}
                isSubmitting={isSubmitting}
                isEditMode={isEditMode}
                isPublished={isPublished}
                isPublishingAction={isPublishingAction}
                onPublish={handlePublish}
                onUnpublish={handleUnpublish}
                clubId={clubId}
              />
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateClub;