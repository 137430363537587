import React from 'react';

const TextField = ({
    fieldName,
    label,
    value,
    onChange,
    error,
    required,
    placeholder,
    inputRef,
    helpText,
    onKeyDown // Note: using onKeyDown here but handleKeyDown in calls
}) => {
    // Fix: Create a handler that correctly extracts the value before calling onChange
    const handleChange = (e) => {
        // The parent component expects just the value, not an event
        onChange(e.target.value);
    };

    return (
        <div className="form-group">
            <label htmlFor={fieldName}>
                {label}
                {required && <span className="required-mark">*</span>}
            </label>

            <div className="input-container">
                <input
                    type="text"
                    id={fieldName}
                    name={fieldName}
                    value={value || ''}
                    onChange={handleChange} // Use our new handler
                    onKeyDown={onKeyDown}
                    className={`form-control ${error ? 'error' : ''}`}
                    placeholder={placeholder}
                    ref={inputRef}
                />
                {helpText && <p className="help-text">{helpText}</p>}
                {error && <div className="error-message">{error}</div>}
            </div>
        </div>
    );
};

export default TextField;