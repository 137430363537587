import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const SignOut = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Clear all authentication related data from local and session storage
        const clearStorageData = () => {
            console.log('Clearing all authentication data during sign out');

            // Clear localStorage items
            localStorage.removeItem('jwt');
            localStorage.removeItem('failedSignInAttempts');
            localStorage.removeItem('fpFailedAttempts');

            // Clear sessionStorage items
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('userEmail');
            sessionStorage.removeItem('userFullName');
            sessionStorage.removeItem('firstName');
            sessionStorage.removeItem('lastName');
            sessionStorage.removeItem('token');

            // Call the auth context logout function
            logout();

            // Redirect to home page or login page
            console.log('Both request JWT and response jwtHeader are empty, refreshing page');
            // Wait a moment to ensure any state gets updated properly
            setTimeout(() => {
                window.location.reload();
            }, 500);
        };

        clearStorageData();
    }, [navigate, logout]);

    return (
        <div className="signout-container">
            <div className="signout-message">
                <h2>Signing out...</h2>
                <p>Please wait while we log you out securely.</p>
            </div>
        </div>
    );
};

export default SignOut;