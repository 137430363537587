import React, { useState, useEffect } from 'react';
import { validateName, validatePassword, validateEmail, validatePhoneNumber } from "../utils/validation";
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import './SignUp.css';

// First, import the authentication context
import { useAuth } from '../context/AuthContext';

// Add these imports
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const SignUp = () => {
    // Get the login function from auth context
    const { login } = useAuth();
    const navigate = useNavigate();

    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',  // Don't include +1 in the initial state
        password: '',
        birthDate: null,
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        birthDate: '',
    });

    // Add state for general/network errors
    const [generalError, setGeneralError] = useState('');

    useEffect(() => {
        // Flag to track if we've already opened to decades view
        let calendarOpened = false;
        
        // Create a MutationObserver to detect when the calendar appears
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.addedNodes.length) {
                    // Check if one of the added nodes is the calendar
                    const calendar = document.querySelector('.react-calendar');
                    if (calendar && !calendarOpened) {
                        calendarOpened = true;
                        
                        // Find the navigation label button and click it twice
                        const navButton = calendar.querySelector('.react-calendar__navigation__label');
                        if (navButton) {
                            // First click to year view
                            navButton.click();
                            // Second click to decade view
                            setTimeout(() => navButton.click(), 50);
                        }
                    }
                }
                
                // Reset the flag when calendar closes
                if (mutation.removedNodes.length) {
                    const wasCalendar = Array.from(mutation.removedNodes).some(
                        node => node.classList && node.classList.contains('react-calendar')
                    );
                    if (wasCalendar) {
                        calendarOpened = false;
                    }
                }
            });
        });
        
        // Start observing the document body for calendar appearance
        observer.observe(document.body, { childList: true, subtree: true });
        
        // Clean up
        return () => {
            observer.disconnect();
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let errorMessage = '';

        if (name === 'firstName' && !validateName(value)) {
            errorMessage = 'Invalid first name';
        } else if (name === 'lastName' && !validateName(value)) {
            errorMessage = 'Invalid last name';
        } else if (name === 'email' && value && !validateEmail(value)) {
            errorMessage = 'Invalid email address';
        } else if (name === 'password' && value && !validatePassword(value)) {
            errorMessage = 'Need minimum 8 characters, at least one small case, one upper case, one number and one special character';
        }

        setForm({ ...form, [name]: value });
        setErrors({ ...errors, [name]: errorMessage });

        // Clear general error when user starts typing
        if (generalError) {
            setGeneralError('');
        }
    };

    // Handle phone number change
    const handlePhoneChange = (e) => {
        const { value } = e.target;
        setForm({ ...form, phoneNumber: value });

        // Only show error if field isn't empty and doesn't match format
        const errorMessage = value && !validatePhoneNumber(value)
            ? 'Please enter a valid phone number in (###)-###-#### format'
            : '';

        setErrors({ ...errors, phoneNumber: errorMessage });

        // Clear general error when user modifies phone number
        if (generalError) {
            setGeneralError('');
        }
    };

    const handleDateChange = (date) => {
        // Ensure we have a full date object
        if (date) {
            // Create a new Date object to ensure all date components are populated
            const fullDate = new Date(date);
            
            // Log for debugging
            console.log('Selected date:', fullDate);
            
            setForm({ ...form, birthDate: fullDate });
        } else {
            setForm({ ...form, birthDate: null });
        }
        
        // Clear any existing error
        if (errors.birthDate) {
            setErrors({ ...errors, birthDate: '' });
        }
        
        // Clear general error when user changes date
        if (generalError) {
            setGeneralError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = {
            firstName: !validateName(form.firstName) ? 'Invalid first name' : '',
            lastName: !validateName(form.lastName) ? 'Invalid last name' : '',
            email: !validateEmail(form.email) ? 'Invalid email address' : '',
            phoneNumber: !validatePhoneNumber(form.phoneNumber) ? 'Please enter a valid phone number in (###)-###-#### format' : '',
            password: !validatePassword(form.password) ? 'Need minimum 8 characters, at least one small case, one upper case, one number and one special character' : '',
            birthDate: !form.birthDate ? 'Birth date is required' : '',
        };

        setErrors(newErrors);

        // Clear any previous general errors
        setGeneralError('');

        const hasErrors = Object.values(newErrors).some(error => error !== '');

        if (!hasErrors) {
            // Extract only the digits from the phone number and add the country code
            const digitsOnly = form.phoneNumber.replace(/\D/g, ''); // Remove all non-digits

            // Prepare the form data with formatted phone number (11 digits only)
            const formData = {
                ...form,
                phoneNumber: `+1${digitsOnly}` // Add 1 prefix and ensure only digits
            };

            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            try {
                // Set loading state
                setGeneralError('Creating your account...');

                // Create headers with Content-Type
                const headers = {
                    'Content-Type': 'application/json',
                };

                // Add X-JWT header if JWT is available in local storage
                const jwt = localStorage.getItem('jwt');
                console.log('LOCAL STORAGE JWT:', jwt ? 'Present' : 'Not present');
                if (jwt) {
                    headers['X-JWT'] = jwt;
                }

                // Create a copy of the form data to submit
                const submitData = { ...formData };

                // If fp wasn't generated earlier, generate it now
                if (!submitData.fp) {
                    try {
                        const visitorId = localStorage.getItem('fp');
                        if (visitorId) {
                            submitData.fp = visitorId;
                        }
                    } catch (fpError) {
                        console.error('Error getting fingerprint before submission:', fpError);
                    }
                }

                const response = await fetch(`${backendUrl}/api/auth/signup`, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(submitData), // Use submitData instead of formData
                    credentials: 'include', // Important for CORS
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Sign up successful:', data);

                    // Check for JWT in headers using multiple case variations
                    const jwtHeader =
                        response.headers.get('X-JWT') ||
                        response.headers.get('X-Jwt') ||
                        response.headers.get('x-jwt');

                    // Determine which JWT to use (header or body)
                    const userJwt = jwtHeader || (data.jwt ? data.jwt : jwt);

                    // Store user data in localStorage and sessionStorage
                    localStorage.setItem('firstName', form.firstName);
                    localStorage.setItem('lastName', form.lastName);

                    // Create userData object similar to what's created during signin
                    const userData = {
                        userProfile: {
                            ID: data.ID,
                            email: form.email,
                            firstName: form.firstName,
                            lastName: form.lastName,
                            fullName: `${form.firstName} ${form.lastName}`.trim()
                        },
                        jwt: userJwt
                    };

                    // Store the JWT in localStorage
                    if (userJwt) {
                        localStorage.setItem('jwt', userJwt);
                    }

                    // Use the auth context login function to set the user as logged in
                    login(userData, userJwt);

                    // Navigate to home or dashboard page
                    navigate('/');
                } else {
                    // Handle error response from server
                    const errorData = await response.json().catch(() => ({
                        message: 'An error occurred during sign up'
                    }));

                    // Display server error at the top of the form
                    setGeneralError(errorData.message || 'Account creation failed. Please try again.');

                    console.error('Sign up failed:', errorData.message || response.statusText);
                }
            } catch (error) {
                console.error('Error during sign up:', error);
                // Display network error at the top of the form
                setGeneralError('Network error. Please check your connection and try again.');
            }
        }
    };

    return (
        <div className="signup-form-container">
            <form onSubmit={handleSubmit}>
                {/* Display general error at the top of the form */}
                {generalError && (
                    <div className="general-error">
                        {generalError}
                    </div>
                )}

                <div className="form-group">
                    <input
                        name="firstName"
                        placeholder="First Name"
                        onChange={handleChange}
                        value={form.firstName}
                        className={`form-control ${errors.firstName ? 'error' : ''}`}
                    />
                    {errors.firstName && <div className="error-message">{errors.firstName}</div>}
                </div>

                <div className="form-group">
                    <input
                        name="lastName"
                        placeholder="Last Name"
                        onChange={handleChange}
                        value={form.lastName}
                        className={`form-control ${errors.lastName ? 'error' : ''}`}
                    />
                    {errors.lastName && <div className="error-message">{errors.lastName}</div>}
                </div>

                <div className="form-group">
                    <input
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        value={form.email}
                        className={`form-control ${errors.email ? 'error' : ''}`}
                    />
                    {errors.email && <div className="error-message">{errors.email}</div>}
                </div>

                <div className="form-group">
                    {/* Phone input with visible prefix */}
                    <div className="phone-input-wrapper">
                        <span className="phone-prefix">+1</span>
                        <InputMask
                            mask="(999)-999-9999"
                            name="phoneNumber"
                            placeholder="(123)-456-7890"
                            onChange={handlePhoneChange}
                            value={form.phoneNumber}
                            className={`form-control phone-input ${errors.phoneNumber ? 'error' : ''}`}
                        />
                    </div>
                    {errors.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
                </div>

                <div className="form-group">
                    <input
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        type="password"
                        autoComplete="new-password"
                        value={form.password}
                        className={`form-control ${errors.password ? 'error' : ''}`}
                    />
                    {errors.password && <div className="error-message">{errors.password}</div>}
                </div>

                <div className="form-group">
                    <label htmlFor="birthDate">Birth Date</label>
                    <div className="custom-date-input">
                        <DatePicker
                            id="birthDate"
                            value={form.birthDate}
                            onChange={handleDateChange} // Use the existing function
                            format="MM/dd/yyyy"
                            dayPlaceholder="DD"
                            monthPlaceholder="MM"
                            yearPlaceholder="YYYY"
                            className={errors.birthDate ? 'error' : ''}
                            calendarClassName="birth-date-calendar"
                            minDate={new Date(1900, 0, 1)}
                            maxDate={new Date()}
                            clearIcon={null}
                            calendarIcon={null}
                            showLeadingZeros={true}
                            onCalendarOpen={() => console.log('Calendar opened')}
                            onCalendarClose={() => console.log('Calendar closed')}
                        />
        
                    </div>
                    {errors.birthDate && <div className="error-message">{errors.birthDate}</div>}
                </div>

                <button type="submit" className="submit-button">Create Account</button>
            </form>
        </div>
    );
};

export default SignUp;