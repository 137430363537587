import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './ScheduleTournament.css';

import DivisionSearch from './components/DivisionSearch';
// Remove VenueSearch import since it's no longer used
import PlayerSearch from './components/PlayerSearch';
import TournamentPlanner from './components/TournamentPlanner';
import TournamentDraws from './components/TournamentDraws';
import { transformApiMatchesToInitialMatches } from './utils/matchUtils';
import { venues, timeSlots, initialDrawPlayers } from './data/initialData';

const ScheduleTournament = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { tournamentId } = useParams();
  const [activeView, setActiveView] = useState('planner');
  const [activeDivisions, setActiveDivisions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [activePlayers, setActivePlayers] = useState([]);
  const [tournamentData, setTournamentData] = useState(null);
  const [isLoadingTournament, setIsLoadingTournament] = useState(false);
  
  // New state for matches from API
  const [matches, setMatches] = useState([]);
  const [isLoadingMatches, setIsLoadingMatches] = useState(false);
  const [matchError, setMatchError] = useState(null);
  
  // Add simulation mode toggle state
  const [isSimulationMode, setIsSimulationMode] = useState(false);

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/signin?returnTo=/schedule-tournament');
    }
  }, [isLoggedIn, navigate]);

  // Determine which tournament ID to use based on simulation mode
  const effectiveTournamentId = isSimulationMode 
    ? `${tournamentId}-simulate`
    : tournamentId;

  // Fetch tournament data based on tournamentId
  useEffect(() => {
    if (effectiveTournamentId) {
      const fetchTournamentData = async () => {
        setIsLoadingTournament(true);
        try {
          const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
          const response = await fetch(`${backendUrl}/api/tournaments/${effectiveTournamentId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              ...(localStorage.getItem('jwt') && { 'X-JWT': localStorage.getItem('jwt') })
            },
            credentials: 'include'
          });

          if (!response.ok) {
            throw new Error('Failed to fetch tournament data');
          }

          const data = await response.json();
          console.log('Tournament data:', data);
          setTournamentData(data);
          
          // If simulation mode is turned on, automatically select the first division
          if (isSimulationMode && data?.divisions?.generatedDivisions?.length > 0) {
            console.log('Found divisions:', data.divisions.generatedDivisions);
            // Look specifically for a division with an 'id' property
            const divisionWithId = data.divisions.generatedDivisions.find(d => d.id);
            
            if (divisionWithId) {
              console.log('Auto-selecting division:', divisionWithId);
              setActiveDivisions([divisionWithId]);
            } else {
              console.warn('No division with ID found in data');
            }
          }
        } catch (err) {
          console.error('Error fetching tournament:', err);
        } finally {
          setIsLoadingTournament(false);
        }
      };

      fetchTournamentData();
    }
  }, [effectiveTournamentId, isSimulationMode]);

  // Fetch matches when a division is selected
  useEffect(() => {
    if (effectiveTournamentId && activeDivisions.length > 0) {
      const fetchMatches = async () => {
        setIsLoadingMatches(true);
        setMatchError(null);
        try {
          const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
          
          // Use did if available, otherwise fall back to id
          const divisionId = activeDivisions[0].did || activeDivisions[0].id;
          
          // Log what ID we're actually using
          console.log(`Fetching matches using division ID: ${divisionId} (did=${activeDivisions[0].did}, id=${activeDivisions[0].id})`);
          
          const response = await fetch(
            `${backendUrl}/api/tournaments/${effectiveTournamentId}/divisions/${divisionId}/matches`,
            {
              headers: {
                'Content-Type': 'application/json',
                'X-JWT': localStorage.getItem('jwt') || ''
              }
            }
          );
          
          if (!response.ok) {
            throw new Error(`Failed to fetch matches: ${response.status}`);
          }
          
          const data = await response.json();
          console.log('Raw match data from API:', data);
          
          // Explicitly transform API match data
          const formattedMatches = transformApiMatchesToInitialMatches(data);
          console.log('Transformed matches for calendar view:', formattedMatches);
          
          setMatches(formattedMatches);
        } catch (error) {
          console.error('Error fetching matches:', error);
          setMatchError(error.message);
        } finally {
          setIsLoadingMatches(false);
        }
      };
      
      fetchMatches();
    } else {
      // Clear matches when no division is selected
      setMatches([]);
    }
  }, [effectiveTournamentId, activeDivisions]);

  // Handle division selection
  const handleDivisionsChange = (divisions) => {
    console.log('Divisions changed:', divisions);
    
    // Debug the division structure thoroughly
    if (divisions.length > 0) {
      console.log('Selected division details:', {
        division: divisions[0],
        hasId: !!divisions[0].id,
        id: divisions[0].id,
        did: divisions[0].did,
        propertyNames: Object.keys(divisions[0])
      });
    }
    
    // Update active divisions
    setActiveDivisions(divisions);
  };

  const handlePlayersChange = (players) => {
    setActivePlayers(players);
  };
  
  // Handler for simulation toggle
  const handleSimulationToggle = () => {
    // Clear current data before changing mode
    setMatches([]);
    if (!isSimulationMode) {
      // If turning ON simulation, we'll let the useEffect handle selecting the first division
      setActiveDivisions([]);
    }
    setIsSimulationMode(!isSimulationMode);
  };

  return (
    <div className="schedule-tournament-container">
      {/* Page Header with Simulation Toggle */}
      <div className="tournament-header-with-toggle">
        <h2 className="tournament-header">
          {isLoadingTournament ? 'Loading Tournament...' : 
           tournamentData ? `Schedule: ${tournamentData.name}` : 'Schedule Tournament'}
        </h2>
        
        <div className="simulation-toggle">
          <label className="toggle-label">
            <span className="toggle-text">Simulation</span>
            <div className="toggle-switch">
              <input 
                type="checkbox" 
                checked={isSimulationMode} 
                onChange={handleSimulationToggle} 
              />
              <span className="slider"></span>
            </div>
          </label>
        </div>
      </div>
      
      {/* Top Filter Row */}
      <div className="top-filters-row">
        <div className="filter-container">
          <DivisionSearch 
            onDivisionsChange={handleDivisionsChange}
            initialDivisions={tournamentData?.divisions?.generatedDivisions || []}
            tournamentId={effectiveTournamentId}
            isLoading={isLoadingTournament}
            selectedDivisions={activeDivisions}
          />
        </div>
        
        {/* PlayerSearch is kept but VenueSearch is removed */}
        <div className="filter-container">
          <PlayerSearch
            onPlayersChange={handlePlayersChange}
            tournamentId={effectiveTournamentId}
          />
        </div>
      </div>
      
      {/* Main Content Area */}
      <div className="main-content-area">
        {/* View Selector Tabs */}
        <div className="view-tabs">
          <button 
            className={`tab ${activeView === 'planner' ? 'active' : ''}`} 
            onClick={() => setActiveView('planner')}
          >
            Calendar View
          </button>
          <button 
            className={`tab ${activeView === 'draws' ? 'active' : ''}`} 
            onClick={() => setActiveView('draws')}
          >
            Bracket/Division View
          </button>
        </div>
        
        {/* View Content */}
        <div className="view-content">
          {activeView === 'planner' && (
            <div className="planner-view">
              {activeDivisions.length === 0 ? (
                <div className="no-selection-message">
                  {isSimulationMode ? 'Loading simulation data...' : 'Please select a division to view matches'}
                </div>
              ) : (
                <>
                  {isLoadingMatches ? (
                    <div className="loading-overlay">Loading matches...</div>
                  ) : matches.length > 0 ? (
                    <TournamentPlanner 
                      matches={Array.isArray(matches) ? matches : []} 
                      venues={venues} // Always use default venues
                      timeSlots={timeSlots}
                      tournamentId={effectiveTournamentId}
                    />
                  ) : (
                    <div className="no-matches-message">
                      No matches found for the selected division. 
                      {matchError ? ' There was an error loading the data.' : ''}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          
          {activeView === 'draws' && (
            <div className="draws-view">
              {activeDivisions.length === 0 ? (
                <div className="no-selection-message">Please select a division to view draws</div>
              ) : (
                <TournamentDraws 
                  players={initialDrawPlayers}
                  tournamentId={effectiveTournamentId}
                  divisionId={activeDivisions[0]?.id}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleTournament;