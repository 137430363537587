import { getGenderText } from './formatUtils';
import Fuse from 'fuse.js';

/**
 * Utility functions for division processing and matching
 */

/**
 * Finds divisions that match the selected match formats
 * @param {Array} selectedFormats - Selected match formats
 * @param {Array} allDivisions - Available divisions
 * @returns {Array} - Array of division values that match the formats
 */
export const findMatchingDivisions = (selectedFormats, allDivisions) => {
  if (!selectedFormats || selectedFormats.length === 0 || !allDivisions || allDivisions.length === 0) {
    return [];
  }

  console.log('---MATCHING DIVISIONS FOR FORMATS---');
  console.log('Selected formats:', selectedFormats);
  console.log('Available divisions:', allDivisions.map(d => d.label || d.value));
  
  const matchingDivisions = allDivisions.filter(division => {
    const divText = (division.label || division.value || '').toLowerCase();
    
    // Check if this division matches ANY of the selected formats
    for (const format of selectedFormats) {
      const formatText = getGenderText(format);
      
      // Try generic matching first
      if (divText.includes(formatText)) {
        console.log(`✓ Match found: Generic match for "${divText}"`);
        return true;
      }
      
      // Singles matching
      if (formatText.includes('singles') || formatText.includes('single')) {
        const isMensFormat = formatText.includes('men') && !formatText.includes('women');
        const isWomensFormat = formatText.includes('women');
        
        // Check for men's singles
        if (isMensFormat && 
            (divText.includes('men') || divText.includes("men's")) && 
            (divText.includes('single') || divText.includes('sgl') || 
             (!divText.includes('double') && !divText.includes('dbl')))) {
          console.log(`✓ Match found: Men's singles for "${divText}"`);
          return true;
        }
        
        // Check for women's singles
        if (isWomensFormat && 
            (divText.includes('women') || divText.includes("women's")) && 
            (divText.includes('single') || divText.includes('sgl') || 
             (!divText.includes('double') && !divText.includes('dbl')))) {
          console.log(`✓ Match found: Women's singles for "${divText}"`);
          return true;
        }
      }
      
      // Doubles matching
      if (formatText.includes('doubles') || formatText.includes('double')) {
        const isMensFormat = formatText.includes('men') && !formatText.includes('women') && !formatText.includes('mixed');
        const isWomensFormat = formatText.includes('women') && !formatText.includes('mixed');
        const isMixedFormat = formatText.includes('mixed');
        
        // Check for men's doubles
        if (isMensFormat && 
            (divText.includes('men') || divText.includes("men's")) && 
            !divText.includes('women') && !divText.includes('mixed') &&
            (divText.includes('double') || divText.includes('dbl'))) {
          console.log(`✓ Match found: Men's doubles for "${divText}"`);
          return true;
        }
        
        // Check for women's doubles
        if (isWomensFormat && 
            (divText.includes('women') || divText.includes("women's")) && 
            !divText.includes('mixed') &&
            (divText.includes('double') || divText.includes('dbl'))) {
          console.log(`✓ Match found: Women's doubles for "${divText}"`);
          return true;
        }
        
        // Check for mixed doubles
        if (isMixedFormat && 
            divText.includes('mixed') && 
            (divText.includes('double') || divText.includes('dbl'))) {
          console.log(`✓ Match found: Mixed doubles for "${divText}"`);
          return true;
        }
      }
    }
    
    return false;
  }).map(div => div.value);
  
  console.log('Matching division values:', matchingDivisions);
  return matchingDivisions;
};

/**
 * Generates divisions based on selected criteria
 * @param {string} skill - Selected skill category
 * @param {Array} gender - Selected match formats
 * @param {string} age - Selected age group
 * @param {string} rating - Selected skill rating
 * @param {Object} tournamentDivisions - Tournament divisions data
 * @returns {Array} - Generated divisions
 */
export const generateDivisions = (skill, gender, age, rating, tournamentDivisions) => {
  if (!skill && !gender && !rating && !age) return [];
  
  console.log('Searching for divisions with:', { skill, gender, age, rating });
  
  // Create a source array from the generated divisions
  const divisionSources = (tournamentDivisions?.generatedDivisions || [])
    .filter(div => div && (typeof div === 'string' || div.name)) // Filter out invalid entries
    .map(div => {
      // Handle both string and object formats
      if (typeof div === 'string') {
        return { name: div, value: div, label: div };
      }
      return {
        name: div.name || '',
        value: div.value || div.name || '',
        label: div.label || div.name || ''
      };
    });
  
  if (divisionSources.length === 0) {
    return [];
  }

  // Pre-process the divisions to extract various metadata for smarter matching
  const enhancedSources = divisionSources.map(div => {
    const divText = div.name.toLowerCase();
    
    // Extract age ranges using regex
    const ageRanges = [];
    const ageRegex = /(\d+)[-+](\d+|\+)/g;
    let match;
    while ((match = ageRegex.exec(divText)) !== null) {
      const minAge = parseInt(match[1], 10);
      const maxAge = match[2] === '+' ? 999 : parseInt(match[2], 10);
      ageRanges.push({ min: minAge, max: maxAge, text: match[0] });
    }
    
    // Extract ratings using regex
    const ratingRegex = /(\d+\.\d+|\d+)[-+]?(\d+\.\d+|\d+|\+)?/g;
    const ratings = [];
    while ((match = ratingRegex.exec(divText)) !== null) {
      const minRating = parseFloat(match[1]);
      const maxRating = match[2] === '+' ? 999 : (match[2] ? parseFloat(match[2]) : minRating);
      ratings.push({ min: minRating, max: maxRating, text: match[0] });
    }
    
    return {
      ...div,
      ageRanges,
      ratings
    };
  });
  
  // Create specialized search strings
  const enhancedSearchQueries = [];
  
  // Basic search with all terms
  enhancedSearchQueries.push([skill, gender, age, rating]
    .filter(Boolean)
    .join(' '));
  
  // Add specialized queries
  if (rating) {
    const ratingValue = parseFloat(rating);
    if (!isNaN(ratingValue)) {
      enhancedSearchQueries.push(`rating:${rating}`);
    }
  }
  
  if (age) {
    const ageValue = parseInt(age, 10);
    if (!isNaN(ageValue)) {
      enhancedSearchQueries.push(`age:${age}`);
    }
  }
  
  // Configure Fuse with more detailed options for better matching
  const fuseOptions = {
    includeScore: true,
    threshold: 0.6,
    useExtendedSearch: true,
    keys: ['name']
  };
  
  // Create a new Fuse instance with our enhanced sources
  const fuse = new Fuse(divisionSources, fuseOptions);
  
  // Perform search with each query and combine results
  let results = [];
  
  enhancedSearchQueries.forEach(query => {
    if (query && query.trim()) {
      const queryResults = fuse.search(query);
      results = [...results, ...queryResults];
    }
  });
  
  // Deduplicate results by item.value
  const seen = new Set();
  results = results.filter(result => {
    const duplicate = seen.has(result.item.value);
    seen.add(result.item.value);
    return !duplicate;
  });
  
  // Manual post-processing for age and rating matching
  results = results.map(result => {
    let score = result.score;
    let matchDetails = [];
    
    // Get the matching div from our enhanced sources
    const enhancedDiv = enhancedSources.find(s => s.value === result.item.value);
    
    // Check age match more precisely
    if (age && enhancedDiv) {
      const ageValue = parseInt(age, 10);
      if (!isNaN(ageValue)) {
        // Check if age falls within any of the extracted ranges
        const ageMatch = enhancedDiv.ageRanges.some(range => 
          ageValue >= range.min && ageValue <= range.max
        );
        
        if (ageMatch) {
          score *= 0.5; // Improve score for age match (lower is better for Fuse)
          matchDetails.push(`age:${age}`);
        }
      }
    }
    
    // Check rating match more precisely
    if (rating && enhancedDiv) {
      const ratingValue = parseFloat(rating);
      if (!isNaN(ratingValue)) {
        // Check if rating falls within any of the extracted ranges
        const ratingMatch = enhancedDiv.ratings.some(range => 
          ratingValue >= range.min && ratingValue <= range.max
        );
        
        if (ratingMatch) {
          score *= 0.5; // Improve score for rating match
          matchDetails.push(`rating:${rating}`);
        }
      }
    }
    
    return {
      ...result,
      score,
      matchDetails
    };
  });
  
  // Sort by adjusted score
  results.sort((a, b) => a.score - b.score);
  
  // Return the results in the expected format
  return results.map(result => ({
    id: result.item.value || `div-${Math.random()}`,
    name: result.item.name || result.item.label || '',
    value: result.item.value || '',
    label: result.item.label || result.item.name || '',
    score: 1 - result.score, // Convert to a score where higher is better
    matchString: result.matchDetails.join(',') || `fuzzy-match:${result.score}`
  }));
};