const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

/**
 * Helper to get authentication headers, with option for multipart requests
 * @param {boolean} isMultipart - Whether this is a multipart/form-data request
 * @returns {Object} Headers object with JWT if available
 */
const getHeaders = (isMultipart = false) => {
  const jwt = localStorage.getItem('jwt');
  const headers = jwt ? { 'X-JWT': jwt } : {};
  
  // Only add Content-Type for non-multipart requests
  // For multipart/form-data, the browser must set the Content-Type with boundary
  if (!isMultipart) {
    headers['Content-Type'] = 'application/json';
  }
  
  return headers;
};


/**
 * Create a new club
 * @param {Object} formData - FormData object containing form data and existing files
 * @returns {Promise<Object>} Created club data
 * @throws {Error} If club creation fails
 */
export const createClub = async (formData) => {
  try {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
    
    // Check if formData is already a FormData object
    const isMultipart = formData instanceof FormData;
    
    // Use the improved getHeaders function
    const headers = getHeaders(isMultipart);
    
    console.log('Sending request to create club with headers:', headers);
    
    const response = await fetch(`${backendUrl}/api/clubs`, {
      method: 'POST',
      body: formData,
      headers: headers, // Now includes X-JWT even for multipart requests
      credentials: 'include'
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => {
        return { message: `Server returned status: ${response.status}` };
      });
      throw new Error(errorData.message || 'Failed to create club');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating club:', error);
    throw error;
  }
};

/**
 * Update an existing club
 * @param {string} clubId - ID of the club to update
 * @param {Object} formData - FormData object containing form data and existing files
 * @returns {Promise<Object>} Updated club data
 * @throws {Error} If club update fails
 */
export const updateClub = async (clubId, formData) => {
  try {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
    
    // Check if formData is already a FormData object
    const isMultipart = formData instanceof FormData;
    
    // Use the improved getHeaders function
    const headers = getHeaders(isMultipart);
    
    console.log('Sending request to update club with headers:', headers);
    
    const response = await fetch(`${backendUrl}/api/clubs/${clubId}`, {
      method: 'PUT',
      body: formData, // Send FormData directly
      headers: headers, // Now includes X-JWT even for multipart requests
      credentials: 'include'
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => {
        return { message: `Server returned status: ${response.status}` };
      });
      throw new Error(errorData.message || 'Failed to update club');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error updating club:', error);
    throw error;
  }
};

/**
 * Fetch a club by ID
 * @param {string} clubId - ID of the club to fetch
 * @returns {Promise<Object>} Club data
 * @throws {Error} If fetching fails
 */
export const fetchClub = async (clubId) => {
  const response = await fetch(`${BACKEND_URL}/api/clubs/${clubId}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders()
  });

  if (!response.ok) {
    throw new Error('Failed to fetch club data');
  }

  return await response.json();
};

/**
 * Delete a club by ID
 * @param {string} clubId - ID of the club to delete
 * @returns {Promise<void>}
 * @throws {Error} If deletion fails
 */
export const deleteClub = async (clubId) => {
  const response = await fetch(`${BACKEND_URL}/api/clubs/${clubId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: getHeaders()
  });

  if (!response.ok) {
    throw new Error('Failed to delete club');
  }
};

/**
 * Fetch a list of all clubs
 * @param {Object} filters - Optional query parameters
 * @returns {Promise<Array>} List of clubs
 * @throws {Error} If fetching fails
 */
export const fetchClubs = async (filters = {}) => {
  // Convert filters to query string
  const queryParams = new URLSearchParams();
  Object.entries(filters).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      queryParams.append(key, value);
    }
  });

  const queryString = queryParams.toString() ? `?${queryParams.toString()}` : '';

  const response = await fetch(`${BACKEND_URL}/api/clubs${queryString}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders()
  });

  if (!response.ok) {
    throw new Error('Failed to fetch clubs');
  }

  return await response.json();
};

/**
 * Transform API club data to form data structure
 * @param {Object} apiClub - Club data from API
 * @returns {Object} Formatted form data
 */
export const transformClubDataToFormData = (apiClub) => {
  console.log('API club data received:', apiClub); // Debug log

  return {
    CID: apiClub.CID || "",
    clubName: apiClub.clubName || "",
    clubTitle: apiClub.clubTitle || "",
    clubDescription: apiClub.clubDescription || "",
    clubType: apiClub.clubType || "",
    status: apiClub.status || "Inactive",
    clubLogos: [], // Files handled separately 
    address: {
      street: apiClub.address?.street || "",
      street2: apiClub.address?.street2 || "",
      city: apiClub.address?.city || "",
      state: apiClub.address?.state || "",
      zipCode: apiClub.address?.zipCode || "",
      country: apiClub.address?.country || ""
    },
    clubWebsiteUrl: apiClub.clubWebsiteUrl || "",
    waiver: null // Changed from [] to null
  };
};

/**
 * Parse file URLs from API response
 * @param {Object} clubData - Club data from API
 * @returns {Object} Object containing parsed logo and waiver URLs
 */
export const parseFileUrls = (clubData) => {
  return {
    clubLogos: Array.isArray(clubData.clubLogos) ? clubData.clubLogos : [],
    waiver: clubData.waiver || null // Changed from Array.isArray check to direct null fallback
  };
};

/**
 * Creates an empty form data object with all required fields initialized
 * @returns {Object} Empty form data structure with default values
 */
export const createEmptyFormData = () => ({
  CID: "",
  clubName: "",
  clubTitle: "",
  clubDescription: "",
  clubType: "",
  status: "",
  clubLogos: [],
  address: {
    street: "",
    street2: "",
    city: "",
    state: "",
    zipCode: "",
    country: ""
  },
  clubWebsiteUrl: "",
  waiver: null  // null, not an array
});

// Publish a club
export const publishClub = async (clubId) => {
  try {
    const response = await fetch(`${BACKEND_URL}/api/clubs/publish/${clubId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...getHeaders()
      }
    });

    if (!response.ok) {
      // Check if the response is JSON
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to publish club (${response.status})`);
      } else {
        // Handle non-JSON error response
        const errorText = await response.text();
        console.error('Server returned non-JSON response:', errorText);
        throw new Error(`Server error (${response.status}): Please check the API endpoint`);
      }
    }
    
    // Check if response has content before parsing
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return await response.json();
    } else {
      // For empty or non-JSON successful responses
      return { success: true };
    }
  } catch (error) {
    console.error('Error publishing club:', error);
    throw error;
  }
};

// Unpublish a club
export const unpublishClub = async (clubId) => {
  try {
    const response = await fetch(`${BACKEND_URL}/api/clubs/unpublish/${clubId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...getHeaders()
      }
    });

    if (!response.ok) {
      // Check if the response is JSON
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to unpublish club (${response.status})`);
      } else {
        // Handle non-JSON error response
        const errorText = await response.text();
        console.error('Server returned non-JSON response:', errorText);
        throw new Error(`Server error (${response.status}): Please check the API endpoint`);
      }
    }
    
    // Check if response has content before parsing
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return await response.json();
    } else {
      // For empty or non-JSON successful responses
      return { success: true };
    }
  } catch (error) {
    console.error('Error unpublishing club:', error);
    throw error;
  }
};

