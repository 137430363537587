import React from 'react';
import { getFieldNames } from '../config/formConfig';

const FieldNavigation = ({ formConfig, currentField, setCurrentField, errors }) => {
    const fieldNames = getFieldNames();

    return (
        <div className="field-navigation">
            <h3>Form Sections</h3>
            <ul className="field-nav-list">
                {fieldNames.map(fieldName => {
                    const field = formConfig[fieldName];
                    const hasError = Object.keys(errors).some(errorKey =>
                        errorKey === fieldName || errorKey.startsWith(`${fieldName}.`) || errorKey.startsWith(`${fieldName}[`)
                    );

                    return (
                        <li
                            key={fieldName}
                            className={`field-nav-item ${currentField === fieldName ? 'active' : ''} ${hasError ? 'has-error' : ''}`}
                            onClick={() => setCurrentField(fieldName)}
                        >
                            <span className="field-nav-label">{field.label}</span>
                            {hasError && <span className="field-nav-error-indicator">!</span>}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default FieldNavigation;