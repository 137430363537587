import React, { useState, useEffect, useRef } from 'react';
import './FileField.css';

const FileField = ({
    fieldName,
    label,
    onChange,
    error,
    inputRef,
    helpText,
    accept,
    value
}) => {
    const [preview, setPreview] = useState(null);
    const [fileName, setFileName] = useState('');
    const fileInputRef = useRef(null);
    
    // Use inputRef if provided, otherwise use local ref
    const actualInputRef = inputRef || fileInputRef;
    
    useEffect(() => {
        // If we already have a value (e.g., loading existing data)
        if (value && typeof value === 'string') {
            setPreview(value);
            // Extract filename from path
            const parts = value.split('/');
            setFileName(parts[parts.length - 1]);
        } else if (value && value.dataUrl) {
            setPreview(value.dataUrl);
            setFileName(value.name || '');
        } else {
            setPreview(null);
            setFileName('');
        }
    }, [value]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        // Set filename immediately for better UX
        setFileName(file.name);

        // Create preview
        const reader = new FileReader();
        reader.onload = (event) => {
            const dataUrl = event.target.result;
            setPreview(dataUrl);
            
            // Pass complete file data to parent
            onChange({
                file: file, // This is the actual File object for upload
                dataUrl: dataUrl,
                name: file.name,
                type: file.type,
                size: file.size
            });
            
            console.log("File selected:", file.name, file.type, file.size);
        };
        reader.readAsDataURL(file);
    };

    const clearFile = (e) => {
        e.preventDefault();
        setPreview(null);
        setFileName('');
        onChange(null);
        
        if (actualInputRef.current) {
            actualInputRef.current.value = '';
        }
    };

    return (
        <div className="form-group file-field inline-field">
            <label htmlFor={fieldName} className="file-field-label">{label}</label>

            <div className="file-field-content">
                <div className="file-upload-container">
                    <input
                        type="file"
                        id={fieldName}
                        name={fieldName}
                        onChange={handleFileChange}
                        className={`form-control ${error ? 'error' : ''}`}
                        ref={actualInputRef}
                        accept={accept}
                        key={preview ? 'has-value' : 'no-value'}
                    />
                    
                    {/* Display selected filename */}
                    {fileName && (
                        <div className="selected-filename">
                            Selected: <strong>{fileName}</strong>
                            <button 
                                type="button" 
                                className="clear-button"
                                onClick={clearFile}
                            >
                                ✕
                            </button>
                        </div>
                    )}

                    {preview && (
                        <div className="file-preview">
                            <img
                                src={preview}
                                alt="File preview"
                                className="file-preview-image"
                            />
                        </div>
                    )}
                </div>
                {helpText && <p className="help-text">{helpText}</p>}
                {error && <div className="error-message">{error}</div>}
            </div>
        </div>
    );
};

export default FileField;