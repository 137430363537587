/**
 * Configuration for the club creation/editing form
 * Defines field types, labels, validation rules, and options
 */

export const formConfig = {
    clubName: {
        type: "text",
        label: "Club Name",
        required: true,
        helpText: "Official name of the club"
    },

    clubTitle: {
        type: "text",
        label: "Club Title",
        helpText: "Display title for the club"
    },

    clubDescription: {
        type: "textarea",
        label: "Club Description",
        helpText: "Describe your club's purpose and activities"
    },

    clubType: {
        type: "select",
        label: "Club Type",
        required: true,
        options: [
            "Company",
            "NonprofitOrganization",
            "ForProfitAssociation",
            "Club",
            "DigitalClub",
            "Facility",
            "Federation",
            "Parks",
            "Recreation",
            "Tour",
            "Other"
        ],
        helpText: "Select the type that best describes your club"
    },

    status: {
        type: "select",
        label: "Status",
        options: [
            "Active",
            "Inactive"
        ],
        helpText: "Set whether this club is currently active"
    },

    clubLogos: {
        type: "file",
        label: "Club Logos",
        accept: "image/*",
        multiple: true,
        required: true,
        helpText: "Upload club logos (PNG or JPG format)"
    },

    address: {
        type: "group",
        label: "Address",
        fields: {
            street: { type: "text", label: "Street", required: false },
            street2: { type: "text", label: "Street 2", required: false },
            city: { type: "text", label: "City", required: true },
            state: { type: "text", label: "State", required: true },
            zipCode: { type: "text", label: "Zip Code", required: true },
            country: { type: "text", label: "Country", required: false }
        },
        helpText: "Enter the club's physical address"
    },

    clubWebsiteUrl: {
        type: "text",
        label: "Club Website URL",
        helpText: "Enter the full URL including http:// or https://"
    },

    waiver: {
        type: "file",
        label: "Upload Waiver (PDF)",
        accept: ".pdf",
        multiple: true,
        helpText: "Upload waiver documents in PDF format"
    }
};

/**
 * Gets the validation rules from the form config
 * @returns {Object} An object with field names and their validation requirements
 */
export const getValidationRules = () => {
    const rules = {};

    // Extract validation rules from form config
    Object.entries(formConfig).forEach(([fieldName, config]) => {
        if (config.required) {
            rules[fieldName] = { required: true };
        }

        // Handle group fields (like address)
        if (config.type === 'group' && config.fields) {
            Object.entries(config.fields).forEach(([subFieldName, subConfig]) => {
                if (subConfig.required) {
                    rules[`${fieldName}.${subFieldName}`] = { required: true };
                }
            });
        }
    });

    return rules;
};

/**
 * Get the field ordering for the form
 * @returns {Array} An array of field names in the desired order
 */
export const getFieldOrder = () => {
    return Object.keys(formConfig);
};