import React from 'react';

const TextareaField = ({
    fieldName,
    label,
    value,
    onChange,
    error,
    inputRef,
    onKeyDown,
    helpText
}) => {
    return (
        <div className="form-group">
            <label htmlFor={fieldName}>{label}</label>
            <textarea
                id={fieldName}
                name={fieldName}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className={`form-control ${error ? 'error' : ''}`}
                ref={inputRef}
                onKeyDown={onKeyDown}
                rows={5}
            />
            {helpText && <p className="help-text">{helpText}</p>}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default TextareaField;