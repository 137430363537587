import React, { useState, useEffect, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import MatchDetailsPopover from './MatchDetailsPopover';
import { formatMatchForBackend } from '../utils/matchFormatters';
import './TournamentPlanner.css';

const TournamentPlanner = ({ matches = [], venues = [], timeSlots = [], tournamentId }) => {
  // State for matches and UI
  const [displayedMatches, setDisplayedMatches] = useState([]);
  const [courts] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [changedMatches, setChangedMatches] = useState(new Set());
  
  // State for interactive features
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [copiedMatch, setCopiedMatch] = useState(null);
  const [draggedMatch, setDraggedMatch] = useState(null);
  const [isSwapping, setIsSwapping] = useState(false);
  
  // State for match editing popover
  const [editingMatch, setEditingMatch] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  
  // Debug useEffect to monitor state changes - MOVED INSIDE COMPONENT
  useEffect(() => {
    console.log("editingMatch changed:", editingMatch);
  }, [editingMatch]);

  useEffect(() => {
    console.log("popoverPosition changed:", popoverPosition);
  }, [popoverPosition]);
  
  // Refs for DOM elements
  const plannerRef = useRef(null);
  const gridContainerRef = useRef(null);
  const unassignedColumnRef = useRef(null);
  
  // Initialize displayed matches from props
  useEffect(() => {
    setDisplayedMatches(matches.map(match => ({
      ...match,
      // Ensure court and timeSlot are parsed as integers
      court: match.court !== undefined ? parseInt(match.court) : undefined,
      timeSlot: match.timeSlot !== undefined ? parseInt(match.timeSlot) : undefined
    })));
  }, [matches]);
  
  // Get match for a specific slot and court
  const getMatchForSlotAndCourt = (timeSlotIndex, court) => {
    return displayedMatches.find(m => 
      m.court === court && 
      m.timeSlot === timeSlotIndex
    );
  };
  
  // Track changes to be saved to backend
  const trackChange = (matchId) => {
    setChangedMatches(prev => {
      const newSet = new Set(prev);
      newSet.add(matchId);
      return newSet;
    });
  };
  
  // Move match to a new slot and court
  const moveMatch = (match, newTimeSlot, newCourt) => {
    // Track this match as changed
    trackChange(match.id);
    
    const updatedMatches = displayedMatches.map(m => {
      if (m.id === match.id) {
        return { 
          ...m, 
          court: parseInt(newCourt), 
          timeSlot: parseInt(newTimeSlot) 
        };
      }
      return m;
    });
    
    setDisplayedMatches(updatedMatches);
  };
  
  // Swap two matches
  const swapMatches = (match1, match2) => {
    // Don't swap if they're the same match
    if (match1.id === match2.id) return;
    
    // Track both matches as changed
    trackChange(match1.id);
    trackChange(match2.id);
    
    const updatedMatches = displayedMatches.map(m => {
      if (m.id === match1.id) {
        return { 
          ...m, 
          court: match2.court, 
          timeSlot: match2.timeSlot 
        };
      } else if (m.id === match2.id) {
        return { 
          ...m, 
          court: match1.court, 
          timeSlot: match1.timeSlot 
        };
      }
      return m;
    });
    
    setDisplayedMatches(updatedMatches);
  };
  
  // Position unassigned column
  const positionUnassignedColumn = useCallback(() => {
    if (gridContainerRef.current && unassignedColumnRef.current) {
      const containerRect = gridContainerRef.current.getBoundingClientRect();
      const unassignedColumn = unassignedColumnRef.current;
      
      // Set the top position to match the grid container
      unassignedColumn.style.top = `${containerRect.top}px`;
      
      // Set the height to match the grid container
      unassignedColumn.style.height = `${containerRect.height}px`;
    }
  }, []);

  // Set up listeners for scroll and resize events
  useEffect(() => {
    // Position the unassigned column initially
    positionUnassignedColumn();
    
    // Update position on scroll or resize
    const handlePositioning = () => {
      positionUnassignedColumn();
    };
    
    window.addEventListener('scroll', handlePositioning);
    window.addEventListener('resize', handlePositioning);
    
    return () => {
      window.removeEventListener('scroll', handlePositioning);
      window.removeEventListener('resize', handlePositioning);
    };
  }, [positionUnassignedColumn]);
  
  // Filter for unassigned matches
  const getUnassignedMatches = () => {
    return displayedMatches.filter(match => 
      match.court === undefined || 
      match.court === null || 
      match.timeSlot === undefined || 
      match.timeSlot === null || 
      isNaN(parseInt(match.court)) || 
      isNaN(parseInt(match.timeSlot))
    );
  };
  
  // Assign a match to a specific slot and court
  const assignMatch = (match, timeSlot, court) => {
    // Track this match as changed
    trackChange(match.id);
    
    const updatedMatches = displayedMatches.map(m => {
      if (m.id === match.id) {
        return { 
          ...m, 
          court: parseInt(court), 
          timeSlot: parseInt(timeSlot),
          status: 'assigned'
        };
      }
      return m;
    });
    
    setDisplayedMatches(updatedMatches);
  };
  
  // Move a match to the unassigned area
  const moveToUnassigned = (match) => {
    // Track this match as changed
    trackChange(match.id);
    
    const updatedMatches = displayedMatches.map(m => {
      if (m.id === match.id) {
        // Remove court and timeSlot to mark as unassigned
        const updatedMatch = { ...m };
        delete updatedMatch.court;
        delete updatedMatch.timeSlot;
        updatedMatch.status = 'unassigned';
        return updatedMatch;
      }
      return m;
    });
    
    setDisplayedMatches(updatedMatches);
  };
  
  // Handle match click
  const handleMatchClick = (match, e) => {
    e.stopPropagation();
    console.log("Match clicked:", match);
    
    if (e.ctrlKey || e.metaKey) {
      // Copy match on Ctrl/Command click
      setCopiedMatch({ ...match });
      setSelectedMatch(null);
      alert('Match copied! Click on an empty slot to place it.');
    } else if (isSwapping && selectedMatch) {
      // Swap with previously selected match
      swapMatches(selectedMatch, match);
      setSelectedMatch(null);
      setIsSwapping(false);
    } else {
      // Show match details popover
      setSelectedMatch(match);
      
      // Clone the match to avoid direct state mutation
      const matchCopy = JSON.parse(JSON.stringify(match)); 
      setEditingMatch(matchCopy);
      
      // Position the popover near the click
      const rect = e.currentTarget.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
      
      // Position below the clicked match and considering scroll
      // Adding 20px offsets to make sure the popover is clearly visible
      const popoverPos = {
        top: rect.bottom + scrollTop + 20,
        left: rect.left + scrollLeft + 20
      };
      
      console.log('Setting popover position:', popoverPos);
      setPopoverPosition(popoverPos);
      
      console.log('Opening popover for match:', matchCopy);
    }
  };
  
  // Handle empty cell click
  const handleEmptyCellClick = (timeSlotIndex, court) => {
    if (copiedMatch) {
      // Paste copied match
      const newMatch = {
        ...copiedMatch,
        id: `${copiedMatch.id}-copy-${Date.now()}`, // New ID for the copied match
        court: court,
        timeSlot: timeSlotIndex
      };
      
      setDisplayedMatches([...displayedMatches, newMatch]);
      setCopiedMatch(null);
      trackChange(newMatch.id);
    } else if (selectedMatch && isSwapping) {
      // Move the selected match here
      moveMatch(selectedMatch, timeSlotIndex, court);
      setSelectedMatch(null);
      setIsSwapping(false);
    }
  };
  
  // Handle match update from popover
  const handleMatchUpdate = (updatedMatch) => {
    // Track this match as changed
    trackChange(updatedMatch.id);
    
    console.log('Match updated from popover:', updatedMatch);
    
    // Update the match in the displayed matches
    const updatedMatches = displayedMatches.map(m => {
      if (m.id === updatedMatch.id) {
        // Make sure to merge with existing match to preserve any fields
        // that might not be in the updated match
        return {
          ...m,
          ...updatedMatch,
          // Ensure player information is preserved correctly
          player1: {
            ...m.player1,
            ...updatedMatch.player1
          },
          player2: {
            ...m.player2,
            ...updatedMatch.player2
          }
        };
      }
      return m;
    });
    
    setDisplayedMatches(updatedMatches);
    setEditingMatch(null); // Close popover
    setSelectedMatch(null); // Deselect match
  };
  
  // Handle match drag start
  const handleDragStart = (e, match) => {
    setDraggedMatch(match);
    e.dataTransfer.effectAllowed = 'move';
    
    // Add a dragging class for visual feedback
    e.target.classList.add('dragging');
    
    // Set a ghost image
    const ghostElement = e.target.cloneNode(true);
    ghostElement.style.width = `${e.target.offsetWidth}px`;
    ghostElement.style.opacity = '0.5';
    
    document.body.appendChild(ghostElement);
    e.dataTransfer.setDragImage(ghostElement, 0, 0);
    
    // Clean up ghost element after drag
    setTimeout(() => {
      document.body.removeChild(ghostElement);
    }, 0);
  };
  
  // Handle match drag end
  const handleDragEnd = (e) => {
    e.target.classList.remove('dragging');
    setDraggedMatch(null);
  };
  
  // Handle drag over a cell
  const handleDragOver = (e, timeSlotIndex, court) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    e.currentTarget.classList.add('drag-over');
  };
  
  // Handle drag leave
  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove('drag-over');
  };
  
  // Handle drop on a cell
  const handleDrop = (e, timeSlotIndex, court) => {
    e.preventDefault();
    e.currentTarget.classList.remove('drag-over');
    
    if (draggedMatch) {
      // Check if match is from unassigned column (it won't have court or timeSlot)
      const isUnassignedMatch = 
        draggedMatch.court === undefined || 
        draggedMatch.court === null || 
        draggedMatch.timeSlot === undefined || 
        draggedMatch.timeSlot === null ||
        isNaN(parseInt(draggedMatch.court)) ||
        isNaN(parseInt(draggedMatch.timeSlot));
        
      // Check if there's already a match at this location
      const existingMatch = getMatchForSlotAndCourt(timeSlotIndex, court);
      
      if (existingMatch) {
        // Show alert about occupied cell
        if (isSwapping) {
          // If in swap mode, perform the swap
          swapMatches(draggedMatch, existingMatch);
          setIsSwapping(false);
        } else {
          // Ask if they want to swap
          if (window.confirm('This slot already has a match. Would you like to swap these matches?')) {
            swapMatches(draggedMatch, existingMatch);
          } else {
            console.log('Drop canceled - slot is already occupied');
          }
        }
      } else {
        // Slot is empty, proceed with the move
        if (isUnassignedMatch) {
          // This is an unassigned match being placed
          assignMatch(draggedMatch, timeSlotIndex, court);
        } else {
          // This is a normal move
          moveMatch(draggedMatch, timeSlotIndex, court);
        }
      }
      
      setDraggedMatch(null);
    }
  };
  
  // Handle drag over unassigned area
  const handleUnassignedDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    e.currentTarget.classList.add('unassigned-drag-over');
  };
  
  // Handle drag leave unassigned area
  const handleUnassignedDragLeave = (e) => {
    e.currentTarget.classList.remove('unassigned-drag-over');
  };
  
  // Handle drop on unassigned area
  const handleUnassignedDrop = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('unassigned-drag-over');
    
    if (draggedMatch) {
      // Check if match is already unassigned
      const isAlreadyUnassigned = 
        draggedMatch.court === undefined || 
        draggedMatch.court === null || 
        draggedMatch.timeSlot === undefined || 
        draggedMatch.timeSlot === null;
      
      if (!isAlreadyUnassigned) {
        moveToUnassigned(draggedMatch);
      }
      
      setDraggedMatch(null);
    }
  };
  
  // Debounced save function
  const saveChangesToBackend = useRef(
    debounce(async (matchesToUpdate) => {
      if (!matchesToUpdate.length) return;
      
      setIsSaving(true);
      setSaveError(null);
      
      try {
        console.log(`Preparing ${matchesToUpdate.length} matches for backend`);
        
        // Format matches for backend structure
        const formattedMatches = matchesToUpdate.map(match => formatMatchForBackend(match, tournamentId));
        console.log("Sample formatted match:", formattedMatches[0]);
        
        // Group matches by division ID for proper API routing
        const matchesByDivision = {};
        formattedMatches.forEach(match => {
          const divisionId = match.DID || 'unknown';
          if (!matchesByDivision[divisionId]) {
            matchesByDivision[divisionId] = [];
          }
          matchesByDivision[divisionId].push(match);
        });
        
        // Get API URL base from environment or use default
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
        const jwt = localStorage.getItem('jwt');
        
        // Make API calls for each division group
        const updatePromises = Object.entries(matchesByDivision).map(async ([divisionId, divMatches]) => {
          if (divisionId === 'unknown') {
            console.warn('Some matches have unknown division ID:', divMatches);
            // You might want to handle these differently or skip them
            return;
          }
          
          const endpoint = `${backendUrl}/api/tournaments/${tournamentId}/divisions/${divisionId}/matches`;
          console.log(`Sending ${divMatches.length} matches to endpoint: ${endpoint}`);
          
          const response = await fetch(endpoint, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': jwt ? `Bearer ${jwt}` : '',
              'X-JWT': jwt || ''
            },
            body: JSON.stringify({ matches: divMatches })
          });
          
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to update matches for division ${divisionId}: ${response.status} ${errorText}`);
          }
          
          const responseData = await response.json().catch(() => ({}));
          console.log(`Backend update successful for division ${divisionId}:`, responseData);
          
          return divisionId;
        });
        
        // Wait for all division updates to complete
        await Promise.all(updatePromises);
        
        // Clear the changed matches set after all successful saves
        setChangedMatches(new Set());
      } catch (error) {
        console.error('Error saving matches to backend:', error);
        setSaveError(`Failed to save changes: ${error.message}`);
      } finally {
        setIsSaving(false);
      }
    }, 1000)
  ).current;
  
  // Trigger save whenever changedMatches updates
  useEffect(() => {
    if (changedMatches.size > 0) {
      const matchesToUpdate = Array.from(changedMatches).map(id => {
        const match = displayedMatches.find(m => m.id === id);
        if (!match) return null;
        
        return match; // Return the raw match - we'll format it inside saveChangesToBackend
      }).filter(Boolean); // Remove any null entries
      
      saveChangesToBackend(matchesToUpdate);
    }
  }, [changedMatches, displayedMatches, saveChangesToBackend]);
  
  return (
    <div className="tournament-planner" ref={plannerRef}>
      <div className="planner-tips">
        <span className="tip">Tips: </span>
        <span>• Click to select a match</span>
        <span>• Ctrl+C to copy, click empty slot to paste</span>
        <span>• Drag and drop to move matches</span>
        <span>• Double-click to edit players</span>
        <span>• Drag to unassigned to remove from schedule</span>
      </div>
      
      {/* Status indicators */}
      {isSaving && (
        <div className="operation-status saving">
          Saving changes to server...
        </div>
      )}
      
      {saveError && (
        <div className="operation-status error">
          {saveError}
          <button onClick={() => {
            setSaveError(null);
            if (changedMatches.size > 0) {
              const matchesToUpdate = Array.from(changedMatches).map(id => {
                return displayedMatches.find(m => m.id === id);
              }).filter(Boolean);
              saveChangesToBackend(matchesToUpdate);
            }
          }}>
            Retry
          </button>
        </div>
      )}
      
      <div className="tournament-grid-container" ref={gridContainerRef}>
        <div className="tournament-grid horizontal-layout">
          {/* Court labels column */}
          <div className="court-labels-column">
            <div className="header-cell corner-cell">Court / Time</div>
            {courts.map(court => (
              <div key={`court-label-${court}`} className="court-label-cell">
                Court {court}
              </div>
            ))}
          </div>
          
          {/* Time slot columns */}
          {timeSlots.map((timeSlot, timeSlotIndex) => (
            <div key={`time-column-${timeSlotIndex}`} className="time-column">
              <div className="header-cell time-header-cell">
                {timeSlot}
              </div>
              
              {courts.map(court => {
                const match = getMatchForSlotAndCourt(timeSlotIndex, court);
                const isOccupied = !!match;
                
                return (
                  <div 
                    key={`cell-${timeSlotIndex}-${court}`} 
                    className={`match-cell ${!isOccupied ? 'empty-cell' : 'occupied-cell'} ${isSwapping ? 'swapping-mode' : ''}`}
                    onClick={() => !isOccupied && handleEmptyCellClick(timeSlotIndex, court)}
                    onDragOver={(e) => handleDragOver(e, timeSlotIndex, court)}
                    onDragLeave={handleDragLeave}
                    onDrop={(e) => handleDrop(e, timeSlotIndex, court)}
                  >
                    {match ? (
                      <div 
                        className={`match-item ${selectedMatch && selectedMatch.id === match.id ? 'selected' : ''} ${changedMatches.has(match.id) ? 'changed' : ''}`}
                        onClick={(e) => handleMatchClick(match, e)}
                        draggable
                        onDragStart={(e) => handleDragStart(e, match)}
                        onDragEnd={handleDragEnd}
                      >
                        <div className="match-players">
                          <div className="player player1">
                            {match.player1?.name || 'Player 1'}
                          </div>
                          <div className="vs-separator">vs</div>
                          <div className="player player2">
                            {match.player2?.name || 'Player 2'}
                          </div>
                        </div>
                        <div className="match-info">
                          {match.stage && `${match.stage} | `}
                          {match.round && `Round ${match.round}`}
                        </div>
                      </div>
                    ) : (
                      <div className="empty-slot">Available</div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        
        {/* Sticky unassigned column */}
        <div 
          className="unassigned-column-container"
          ref={unassignedColumnRef}
          onDragOver={handleUnassignedDragOver}
          onDragLeave={handleUnassignedDragLeave}
          onDrop={handleUnassignedDrop}
        >
          <div className="unassigned-column">
            <div className="header-cell unassigned-header-cell">
              Unassigned Matches ({getUnassignedMatches().length})
            </div>
            
            <div className="unassigned-matches-container">
              {getUnassignedMatches().length === 0 ? (
                <div className="empty-unassigned-message">
                  All matches have been scheduled!
                </div>
              ) : (
                getUnassignedMatches().map(match => (
                  <div 
                    key={`unassigned-${match.id}`}
                    className={`match-item unassigned-match ${selectedMatch && selectedMatch.id === match.id ? 'selected' : ''} ${changedMatches.has(match.id) ? 'changed' : ''}`}
                    onClick={(e) => handleMatchClick(match, e)}
                    draggable
                    onDragStart={(e) => handleDragStart(e, match)}
                    onDragEnd={handleDragEnd}
                  >
                    <div className="match-players">
                      <div class="player player1">
                        {match.player1?.name || 'Player 1'}
                      </div>
                      <div className="vs-separator">vs</div>
                      <div className="player player2">
                        {match.player2?.name || 'Player 2'}
                      </div>
                    </div>
                    <div className="match-info">
                      {match.stage && `${match.stage} | `}
                      {match.round && `Round ${match.round}`}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* Operation status indicators */}
      {isSwapping && (
        <div className="operation-status swapping">
          Swapping mode: Select a match to swap with the highlighted match
        </div>
      )}
      {copiedMatch && (
        <div className="operation-status copied">
          Match copied: Click an empty slot to place the match
        </div>
      )}
      
      {/* Render the match details popover */}
      {editingMatch && (
        <div 
          className="popover-container"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000,
            pointerEvents: 'none', // The container doesn't capture clicks
            backgroundColor: 'rgba(0, 0, 0, 0.1)' // Slight dimming of the background
          }}
        >
          <MatchDetailsPopover
            match={editingMatch}
            position={popoverPosition}
            onClose={() => setEditingMatch(null)}
            onUpdate={handleMatchUpdate}
            courts={courts}
            timeSlots={timeSlots}
          />
        </div>
      )}
    </div>
  );
};

export default TournamentPlanner;