export const validateName = (value) => {
  // Allow only alphabetic characters and spaces, and limit to 20 characters
  const regex = /^[A-Za-z\s]{0,20}$/;
  return regex.test(value) && value.length <= 40;
};

export const validateEmail = (value) => {
  // Simplified email validation regex with a maximum length of 40 characters
  // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,40}$/;
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  return regex.test(value) && value.length <= 40;
};

// Custom validation for phone number
export const validatePhoneNumber = (phoneNumber) => {
  // For (123)-456-7890 format (without +1)
  return /^\(\d{3}\)-\d{3}-\d{4}$/.test(phoneNumber);
};

export const validatePassword = (value) => {
  // Password validation regex: minimum 8 characters, at least one lowercase letter, at least one uppercase letter, one number, and one special character
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(value) && value.length <= 40;
};