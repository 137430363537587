import React from 'react';
import './PublicToggle.css';

const PublicToggle = ({ isPublic, onChange, disabled }) => {
  return (
    <div className="public-toggle-container">
      <label className="toggle-switch">
        <input
          type="checkbox"
          checked={isPublic}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="toggle-slider"></span>
      </label>
      <span className="toggle-label">
        {isPublic ? 'Published' : 'Not yet published'}
      </span>
    </div>
  );
};

export default PublicToggle;